import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';

import './index.css';
import App from './App';
import 'jquery/src/jquery'; //for bootstrap.min.js
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import {Router, Route, Switch} from 'react-router-dom';
import history from "./history";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Auth0ProviderWithHistory from "./auth/Auth0Provider.js";

import * as serviceWorker from './serviceWorker';

import BaseLayout from './BaseLayout';
import Home from './components/Home';
import Promo from './components/Promo';
import Properties from './components/Properties';
import Category from './components/Category';
// import Thanks from './components/Thanks';
import Contractors from './components/Contractors';
import Register from './components/Register';
import Terms from './components/Terms';
import PrivacyPolicy from './components/PrivacyPolicy';

import 'pace-js';
import 'pace-js/themes/orange/pace-theme-minimal.css';
// import './pace.css';
import './Interceptor';

const ProtectedRoute = ({ component, ...args }) => (
  	<Route component={withAuthenticationRequired(component)} {...args} />
);

// const onRedirectCallback = (appState) => {
// 	// Use the router's history module to replace the url
// 	history.replace(appState?.returnTo || window.location.pathname);
// };

// const onRedirectCallback = (AppState) => {
// 	// If using a Hash Router, you need to use window.history.replaceState to
// 	// remove the `code` and `state` query parameters from the callback url.
// 	// window.history.replaceState({}, document.title, window.location.pathname);
// 	history.replace((AppState && AppState.returnTo) || window.location.pathname);
// };

ReactDOM.render(
  	// access to history for redirects
  	<Auth0ProviderWithHistory>
		<Provider store={configureStore()}>
			<Router history={history}>
				<Switch>
					<Route exact path='/promo' component={Promo}/>
					{/* <Route path='/thanks' component={Thanks}/> */}
					<Route exact path='/register/sub_type=:subId' component={Register}/>
					<Route exact path='/register' component={Register}/>
					<Route exact path='/' component={Promo}/>
					<Route path='/terms' component={Terms}/>
					<Route path='/privacy_policy' component={PrivacyPolicy}/>
					<BaseLayout history={history}>
						<Switch>
							<ProtectedRoute path='/home/user=:userId' component={Home}/>
							<ProtectedRoute path='/contractors/user=:userId' component={Contractors}/>
							<ProtectedRoute path='/properties/user=:userId' component={Properties}/>
							<ProtectedRoute path='/records/user=:userId/category=:catId' component={Category}/>
						</Switch>
					</BaseLayout>
				</Switch>
			</Router>
		</Provider>
	</Auth0ProviderWithHistory>, document.getElementById('root'));
serviceWorker.unregister();
