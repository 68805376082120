import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "react-bootstrap";

const LogoutStartover = () => {
	const { logout } = useAuth0();

	const logoutTasks = () => {
		localStorage.clear();
		sessionStorage.clear();
		sessionStorage.setItem("logged_in", 'false');
		localStorage.setItem("redirect_url", '/');
	}

	return (
		<Button
			variant="primary"
			className="logout-btn "
			onClick={() => {logout({ returnTo: window.location.origin }); logoutTasks();}}
			>
				Start Over
		</Button>
	);
};

export default LogoutStartover;