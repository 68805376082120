import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import SideNavigation from './components/SideNavigation';
import { withRouter } from 'react-router-dom';

function BaseLayout(props){

  return(
		<div>
			<Header history={props.history} />
			<SideNavigation />
			{props.children}
			<Footer/>
		</div>
	);
}

export default withRouter(BaseLayout);