import React, { Component } from 'react';
import './css/Contractors.css';
import {Container, Col, Row, Button, Form, ListGroup, Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import $ from 'jquery';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
// import dateFormat from 'dateformat';
/* global google */

class Contractors extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error                  : null,
			isLoaded               : false,
			categories             : [],
			properties             : [],
			contractors            : [],
			contractor_html        : '',
			categoryId             : '',
			contractorId           : '',
			userId                 : '',
			detail                 : '',
			title                  : '',
			propertyId             : null,
			contractorsLoaded      : false, 
            showContractorAdd      : false,
			contractorDetailLoaded : false,
			startDate              : '',
			endDate                : '',
			showContractorEdit     : false,
			display_date           : '',
			contractorName         : '',
		    contractorPhone        : '',
		    contractorAddress1     : '',
	        contractorAddress2     : '',
		    contractorCity         : '',
		    contractorState        : '',
			contractorZipCode      : '',
			contractorCategoryId : '',
			contractorEmail      : '',
			contractorWebsite    : '',
			contractorsCount     : 0,
			contractor_detail    : [],
			showLoader           : false

		};

		this.autocompleteInput     = React.createRef();
		this.autocompleteInputEdit = React.createRef();
		this.handlePlaceSelect     = this.handlePlaceSelect.bind(this);
		this.autocomplete          = null;
		this.handlePlaceSelectEdit = this.handlePlaceSelectEdit.bind(this);
		this.autocompleteEdit      = null;
		this._handleInput          = this._handleInput.bind(this);
		this.addContractor         = this.addContractor.bind(this);
		this.updateContractor      = this.updateContractor.bind(this);
		this.deleteContractor      = this.deleteContractor.bind(this);
		this.showDetail            = this.showDetail.bind(this);
		this.handleAddShow         = this.handleAddShow.bind(this);
		this.handleAddClose        = this.handleAddClose.bind(this);
		this.handleEditShow        = this.handleEditShow.bind(this);
		this.handleEditClose       = this.handleEditClose.bind(this);
		this.getContractorsByUser  = this.getContractorsByUser.bind(this);
		this.getCategories         = this.getCategories.bind(this);
		this.initGooglePlaces      = this.initGooglePlaces.bind(this);
		this.initGooglePlacesEdit  = this.initGooglePlacesEdit.bind(this);
		this.loadInitial           = this.loadInitial.bind(this);
	}

	componentDidMount() {
		setTimeout(
			function() {
				this.loadInitial();
			}
			.bind(this),
			500
		);
	}

	loadInitial() {
		this.setState({ 
			showLoader : true,
		});
		axios.defaults.headers.common = {
			'Authorization': 'Bearer ' + sessionStorage.getItem('token')
		};

		let smallScreen = (window.innerWidth <= 850);
		// console.log('the width: ', window.innerWidth);
		// console.log('small screen: ', smallScreen);
		if (smallScreen){
			$('.parent-container').css( { "padding-left" : "4.7em"} );
		} else {
			$('.parent-container').css( { "padding-left" : "2em"} );
		}
		
		$('.addContractorForm').hide();
		const categoryId = parseInt(this.props.match.params.catId);
		// console.log('category ', categoryId);
	
		const userId = sessionStorage.getItem('userID');
		this.setState({ categoryId, userId });
		this.getContractorsByUser(userId);
		this.getCategories();
		
	};

	initGooglePlaces(){
		// console.log('this is happening initGooglePlaces');

		this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
			{"types": ["geocode"]});
	
		this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
	}

	getContractorsByUser(userId){
		
		try{
			axios({
				method: "get",
				url: process.env.REACT_APP_BASE_URL+"Contractors/user/"+userId,
			})
			.then(res => {
				
				const contractors = res.data.result;
				// console.log('contractors result ', contractors);
				if (contractors.length > 0) {
					this.setState({ 
						contractors,
						contractorsCount  : contractors.length,
						contractorsLoaded : true,
						showLoader        : false
					});
				}	
			});
		} catch(ex){
			console.log('getContractorsByUser exception : ', ex);
		}
	}

	getCategories(){

		try{
			axios.get(process.env.REACT_APP_BASE_URL+"categories")
			.then(res => {
				const categories = res.data.result;
		
				this.setState({ 
					categories,
					categoriesLoaded : true 
				});
				// console.log('categories : ', categories)
			});
		} catch(ex){
			console.log('getCategories exception : ', ex);
		}
	}

	handleAddShow() {
		this.setState({ 
						showContractorAdd  : true,
						contractorName     : '', 
						contractorPhone    : '',
						contractorAddress1 : '',
						contractorAddress2 : '',  
						contractorCity     : '',  
						contractorState    : '', 
						contractorZipCode  : '',
						contractorEmail    : '',
						contractorWebsite  : '' 
					});
	}
	
    handleAddClose() {
		this.setState({ showContractorAdd  : false });
	}

	handleEditShow() {
		this.setState({ showContractorEdit : true });
	}
	
    handleEditClose() {
		this.setState({ showContractorEdit : false });
	}

    showDetail(contractorId){
        this.setState({ 
			showLoader : true,
		});
        this.setState({ showContractorAdd : false });
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Contractors/"+contractorId)
			.then(res => {
				const contractor_detail = res.data.result;
				
				this.setState({ 
					contractor_detail,
					contractorDetailLoaded : true,
					contractorId           : contractor_detail.id, 
					contractorName         : contractor_detail.name, 
					contractorPhone        : contractor_detail.phone,
					contractorAddress1     : contractor_detail.address1,
					contractorAddress2     : contractor_detail.address2,  
					contractorCity         : contractor_detail.city,  
					contractorState        : contractor_detail.state, 
					contractorZipCode      : contractor_detail.zipCode,
					contractorEmail        : contractor_detail.email,
					contractorWebsite      : contractor_detail.website,
					contractorCategoryId   : contractor_detail.categoryId,
					contractorCategory     : contractor_detail.contractorCategory,

				});
				this.setState({ 
					showLoader : false,
				});
				// console.log('contractor detail state: ', this.state.contractorState);
			});
		} catch(ex){
			console.log('showDetail exception : ', ex);
		}
	}
	
	addContractor(e){
		e.preventDefault();

		this.setState({ 
			showLoader : true,
		});

		var contractorCategoryId = this.state.contractorCategoryId;
		var contractorName       = this.state.contractorName;
		var contractorPhone      = this.state.contractorPhone;
		var contractorAddress1   = this.state.contractorAddress1;
		var contractorAddress2   = this.state.contractorAddress2;
		var contractorCity       = this.state.contractorCity;
		var contractorState      = this.state.contractorState;
		var contractorZipCode    = this.state.contractorZipCode;
		var contractorEmail      = this.state.contractorEmail;
		var contractorWebsite    = this.state.contractorWebsite;
		try{
			axios.post(process.env.REACT_APP_BASE_URL+'Contractors?userId='+this.state.userId, { 
				contractorCategoryId : contractorCategoryId,
				categoryId           : contractorCategoryId,
				name                 : contractorName,
				phone                : contractorPhone,
				address1             : contractorAddress1, 
				address2             : contractorAddress2,
				city                 : contractorCity,
				state                : contractorState,
				zipCode              : contractorZipCode,
				email                : contractorEmail,
				website              : contractorWebsite
			})
			.then((result) => {
				// console.log('we created a contractor', result);
				this.setState({ showContractorAdd : false });
				$('.addContractorForm').hide();
				
				this.getContractorsByUser(this.state.userId);
			});
		} catch(ex){
			console.log('addContractor exception : ', ex);
		}
	}

	deleteContractor(){
		this.setState({ 
			showLoader : true,
		});
		var confirm = window.confirm('Are you sure you want to delete this contractor?')
		if (confirm){
			
			try{
				var contractorId = this.state.contractorId;

				this.setState({ contractorDetailLoaded : false });
				axios.delete(process.env.REACT_APP_BASE_URL+"Contractors/"+contractorId)
				.then((result) => {
					// console.log('we deteled a contractor', result);
					this.getContractorsByUser(this.state.userId);
				});
			} catch(ex){
				console.log('deleteContractor exception : ', ex);
			}
		}
	}

	updateContractor(e){
		e.preventDefault();
		this.setState({ 
			showLoader : true,
		});

		var contractorId         = this.state.contractorId;
		var contractorCategoryId = this.state.contractorCategoryId;
		var contractorName       = this.state.contractorName;
		var contractorPhone      = this.state.contractorPhone;
		var contractorAddress1   = this.state.contractorAddress1;
		var contractorAddress2   = this.state.contractorAddress2;
		var contractorCity       = this.state.contractorCity;
		var contractorState      = this.state.contractorState;
		var contractorZipCode    = this.state.contractorZipCode;
		var contractorEmail      = this.state.contractorEmail;
		var contractorWebsite    = this.state.contractorWebsite;

		try{
			axios.put(process.env.REACT_APP_BASE_URL+'Contractors/'+contractorId, { 
				contractorCategoryId : contractorCategoryId,
				name                 : contractorName,
				phone                : contractorPhone,
				address1             : contractorAddress1, 
				address2             : contractorAddress2,
				city                 : contractorCity,
				state                : contractorState,
				zipCode              : contractorZipCode,
				email                : contractorEmail,
				website              : contractorWebsite
			})
			.then((result) => {
				// console.log('we updated a contractor', result);
				$('.contractorEditForm').hide();
				this.setState({ showContractorEdit : false });
				this.showDetail(contractorId)

				this.getContractorsByUser(this.state.userId);
			});
		} catch(ex){
			console.log('updateContractor exception : ', ex);
		}
	}

	_handleInput(event) {
		let obj  =  {}
		let key  = event.target.name;
		obj[key] = event.target.value;
		this.setState(obj);
	}

	initGooglePlacesEdit(){
		// console.log('this is happening initGooglePlaces');

		this.autocompleteEdit = new google.maps.places.Autocomplete(this.autocompleteInputEdit.current,
			{"types": ["geocode"]});
	
		this.autocompleteEdit.addListener('place_changed', this.handlePlaceSelectEdit);
	}

	handlePlaceSelect() {
		let addressObject = this.autocomplete.getPlace()
		// console.log('the address: ', addressObject)
		let address = addressObject.address_components;

		var addressDisplay = `${address[0].long_name} ${address[1].long_name}`;
		var city    = address[2].long_name;
		var state   = address[4].short_name;
		var zipCode = address[6].short_name;
		var name    = addressObject.name;
		var url     = addressObject.url;

		if (name.length === 0){
			name = '';
		}
		if (url.length === 0){
			url = '';
		}
		if (addressDisplay.length === 0){
			addressDisplay = '';
		}
		if (city.length === 0){
			city = '';
		}
		if (state.length === 0){
			state = '';
		}
		if (zipCode.length === 0){
			zipCode = '';
		}

		this.setState({
			contractorName     : name,
			contractorAddress1 : addressDisplay,
			contractorCity     : city,
			contractorState    : state,
			contractorZipCode  : zipCode,
			contractorWebsite  : url
		});
	}

	handlePlaceSelectEdit() {
		let addressObject = this.autocompleteEdit.getPlace()
		// console.log('the address: ', addressObject)
		let address = addressObject.address_components;

		var addressDisplay = `${address[0].long_name} ${address[1].long_name}`;
		var city    = address[2].long_name;
		var state   = address[4].short_name;
		var zipCode = address[6].short_name;
		var name    = addressObject.name;
		var url     = addressObject.url;

		if (name.length === 0){
			name = '';
		}
		if (url.length === 0){
			url = '';
		}
		if (addressDisplay.length === 0){
			addressDisplay = '';
		}
		if (city.length === 0){
			city = '';
		}
		if (state.length === 0){
			state = '';
		}
		if (zipCode.length === 0){
			zipCode = '';
		}

		this.setState({
			contractorName     : name,
			contractorAddress1 : addressDisplay,
			contractorCity     : city,
			contractorState    : state,
			contractorZipCode  : zipCode,
			contractorWebsite  : url
		});
	}

	
	render() {

		const showLoader = this.state.showLoader;
		
		let loaderDisplay;

		if (showLoader) {
			loaderDisplay = 
						<div className="loader_container">
							<Loader
								type="Oval"
								color="#5FCE7F"
								height={100}
								width={100}
								timeout={10000} //10 secs
								visible={this.state.showLoader}
							/>
						</div>
		} else {
			loaderDisplay = "";
		}

        let contractorsDisplay;

		contractorsDisplay = <div className="contractors_container"> 
								<div>
									<div className="contractors_container_header">
										<div className="contractor_category_name">{this.state.contractors.name}</div>
                                        <div className="contractors_header_line_2">
                                            <div className="contractors_count">{this.state.contractorsCount} Contractors</div>
                                            <div className="contractor_add_button">
												<FontAwesomeIcon onClick={this.handleAddShow} className="add_contractor_plus icon" icon={ faPlusCircle }/>
                                            </div>
                                        </div>
									</div>
                                    <Modal show={this.state.showContractorAdd} size='lg' onHide={this.handleAddClose}>
										<Modal.Header closeButton>
											<Modal.Title>Add a Contractor</Modal.Title>
										</Modal.Header>
										<Modal.Body>	
											<Form 
												className="formCard addContractorForm" 
												onSubmit={this.addContractor}>
												<div className="new_contractor_title">Add Contractor</div>
													<Form.Group as={Row} >
													<Col md={4}>
														<Form.Label className="formCardTitle">Search: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															id="autocomplete"
															ref={this.autocompleteInput}
															// placeholder="Name" 
															onFocus={this.initGooglePlaces}
															name='contractorSearch' 
															// onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalContractorCategoryId">
													<Col md={4}>
														<Form.Label className="formCardTitle">Category: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="select" 
															name='contractorCategoryId' 
															onChange={this._handleInput} 
															defaultValue="Choose...">
															<option value="">Choose...</option>
															{this.state.categories.map(category => 
																<option key={category.id} value={category.id}>{category.name}</option>
															)};
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalContractorName">
													<Col md={4}>
														<Form.Label className="formCardTitle">Name: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															// placeholder="Name" 
															value={this.state.contractorName}
															name='contractorName' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalPhone">
													<Col md={4}>
														<Form.Label className="formCardTitle">Phone: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="tel" 
															name='contractorPhone' 
															value={this.state.contractorPhone}
															onChange={this._handleInput} 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalContractor">
													<Col md={4}>
														<Form.Label className="formCardTitle">Address1: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='contractorAddress1' 
															value={this.state.contractorAddress1}
															onChange={this._handleInput} 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Address2: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='contractorAddress2' 
															value={this.state.contractorAddress2}
															onChange={this._handleInput} 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">City: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='contractorCity' 
															value={this.state.contractorCity}
															onChange={this._handleInput} 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalState">
													<Col md={4}>
														<Form.Label className="formCardTitle">State: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="select" 
															name='contractorState' 
															onChange={this._handleInput} 
															value={this.state.contractorState}
															defaultValue="Choose..."
															>
															<option value="">Choose...</option>
															<option value="AL">Alabama</option>
															<option value="AK">Alaska</option>
															<option value="AZ">Arizona</option>
															<option value="AR">Arkansas</option>
															<option value="CA">California</option>
															<option value="CO">Colorado</option>
															<option value="CT">Connecticut</option>
															<option value="DE">Delaware</option>
															<option value="DC">District Of Columbia</option>
															<option value="FL">Florida</option>
															<option value="GA">Georgia</option>
															<option value="HI">Hawaii</option>
															<option value="ID">Idaho</option>
															<option value="IL">Illinois</option>
															<option value="IN">Indiana</option>
															<option value="IA">Iowa</option>
															<option value="KS">Kansas</option>
															<option value="KY">Kentucky</option>
															<option value="LA">Louisiana</option>
															<option value="ME">Maine</option>
															<option value="MD">Maryland</option>
															<option value="MA">Massachusetts</option>
															<option value="MI">Michigan</option>
															<option value="MN">Minnesota</option>
															<option value="MS">Mississippi</option>
															<option value="MO">Missouri</option>
															<option value="MT">Montana</option>
															<option value="NE">Nebraska</option>
															<option value="NV">Nevada</option>
															<option value="NH">New Hampshire</option>
															<option value="NJ">New Jersey</option>
															<option value="NM">New Mexico</option>
															<option value="NY">New York</option>
															<option value="NC">North Carolina</option>
															<option value="ND">North Dakota</option>
															<option value="OH">Ohio</option>
															<option value="OK">Oklahoma</option>
															<option value="OR">Oregon</option>
															<option value="PA">Pennsylvania</option>
															<option value="RI">Rhode Island</option>
															<option value="SC">South Carolina</option>
															<option value="SD">South Dakota</option>
															<option value="TN">Tennessee</option>
															<option value="TX">Texas</option>
															<option value="UT">Utah</option>
															<option value="VT">Vermont</option>
															<option value="VA">Virginia</option>
															<option value="WA">Washington</option>
															<option value="WV">West Virginia</option>
															<option value="WI">Wisconsin</option>
															<option value="WY">Wyoming</option>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Zip Code: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="number" 
															name='contractorZipCode' 
															value={this.state.contractorZipCode}
															onChange={this._handleInput} 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Email: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="email" 
															name='contractorEmail' 
															onChange={this._handleInput} 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Website: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="url" 
															name='contractorWebsite' 
															value={this.state.contractorWebsite}
															onChange={this._handleInput} 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row}>
													<Col sm={{ span: 10 }}>
														<Button type="submit">Submit</Button>
													</Col>
												</Form.Group>
											</Form>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" className="white-btn"  onClick={this.handleAddClose}>
												Close
											</Button>
										</Modal.Footer>
									</Modal>
									<ListGroup defaultActiveKey="#link1">
										{this.state.contractors.map(contractor => 
											<div className="contractor_container" key={contractor.id} >
												<ListGroup.Item action onClick={() => this.showDetail(contractor.id)}>
                                                    <div className="contractor_list_top_line">
                                                        <div className="contractor_list_contractor">{contractor.name}</div>
                                                        <div className="contractor_list_category">{contractor.contractorCateorgy}</div>
                                                    </div>
													<div className="contractor_list_location">{contractor.city}, {contractor.state}</div>
												</ListGroup.Item>
											</div>
										)}
									</ListGroup>
								</div>
							</div>
        
        const loadContractorDetail = this.state.contractorDetailLoaded;
        let contractorDetailDisplay;
        
    	if (loadContractorDetail) {
			contractorDetailDisplay = <div className="contractor_detail_container">
									<Modal show={this.state.showContractorEdit} size='lg' onHide={this.handleEditClose}>
										<Modal.Header closeButton>
											<Modal.Title>Edit Contractor</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<Form 
												className="formCard addContractorForm" 
												onSubmit={this.updateContractor}>
												<div className="new_contractor_title">Edit Contractor</div>
												<Form.Group as={Row} controlId="formHorizontalContractorCategory">
													<Col md={4}>
														<Form.Label className="formCardTitle">Category: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="select" 
															name='contractorCategoryId' 
															onChange={this._handleInput} 
															defaultValue={this.state.contractorCategoryId}>
															{/* <option value="">Choose...</option> */}
															{this.state.categories.map(category => 
																<option key={category.id} value={category.id}>{category.name}</option>
															)};
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalName">
													<Col md={4}>
														<Form.Label className="formCardTitle">Name: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															// placeholder="Name" 
															value={this.state.contractorName}
															name='contractorName' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalCategoryPhone">
													<Col md={4}>
														<Form.Label className="formCardTitle">Phone: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="tel" 
															value={this.state.contractorPhone}
															name='contractorPhone' 
															onChange={this._handleInput} 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalContractor">
													<Col md={4}>
														<Form.Label className="formCardTitle">Address1: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															value={this.state.contractorAddress1}
															name='contractorAddress1' 
															onChange={this._handleInput} 
															id="autocompleteEdit"
															ref={this.autocompleteInputEdit}
															onFocus={this.initGooglePlacesEdit}
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Address2: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															value={this.state.contractorAddress2}
															name='contractorAddress2' 
															onChange={this._handleInput} 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">City: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='contractorCity' 
															value={this.state.contractorCity}
															onChange={this._handleInput} 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalState">
													<Col md={4}>
														<Form.Label className="formCardTitle">State: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="select" 
															name='contractorState' 
															onChange={this._handleInput} 
															value={this.state.contractorState}
															// defaultValue={this.state.contractorState}
															>
															<option value="">Choose...</option>
															<option value="AL">Alabama</option>
															<option value="AK">Alaska</option>
															<option value="AZ">Arizona</option>
															<option value="AR">Arkansas</option>
															<option value="CA">California</option>
															<option value="CO">Colorado</option>
															<option value="CT">Connecticut</option>
															<option value="DE">Delaware</option>
															<option value="DC">District Of Columbia</option>
															<option value="FL">Florida</option>
															<option value="GA">Georgia</option>
															<option value="HI">Hawaii</option>
															<option value="ID">Idaho</option>
															<option value="IL">Illinois</option>
															<option value="IN">Indiana</option>
															<option value="IA">Iowa</option>
															<option value="KS">Kansas</option>
															<option value="KY">Kentucky</option>
															<option value="LA">Louisiana</option>
															<option value="ME">Maine</option>
															<option value="MD">Maryland</option>
															<option value="MA">Massachusetts</option>
															<option value="MI">Michigan</option>
															<option value="MN">Minnesota</option>
															<option value="MS">Mississippi</option>
															<option value="MO">Missouri</option>
															<option value="MT">Montana</option>
															<option value="NE">Nebraska</option>
															<option value="NV">Nevada</option>
															<option value="NH">New Hampshire</option>
															<option value="NJ">New Jersey</option>
															<option value="NM">New Mexico</option>
															<option value="NY">New York</option>
															<option value="NC">North Carolina</option>
															<option value="ND">North Dakota</option>
															<option value="OH">Ohio</option>
															<option value="OK">Oklahoma</option>
															<option value="OR">Oregon</option>
															<option value="PA">Pennsylvania</option>
															<option value="RI">Rhode Island</option>
															<option value="SC">South Carolina</option>
															<option value="SD">South Dakota</option>
															<option value="TN">Tennessee</option>
															<option value="TX">Texas</option>
															<option value="UT">Utah</option>
															<option value="VT">Vermont</option>
															<option value="VA">Virginia</option>
															<option value="WA">Washington</option>
															<option value="WV">West Virginia</option>
															<option value="WI">Wisconsin</option>
															<option value="WY">Wyoming</option>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Zip Code: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="number" 
															value={this.state.contractorZipCode}
															name='contractorZipCode' 
															onChange={this._handleInput} 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalEmail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Email: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="email" 
															value={this.state.contractorEmail}
															name='contractorEmail' 
															onChange={this._handleInput} 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalWebsite">
													<Col md={4}>
														<Form.Label className="formCardTitle">Website: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="url" 
															value={this.state.contractorWebsite}
															name='contractorWebsite' 
															onChange={this._handleInput} 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row}>
													<Col sm={{ span: 10 }}>
														<Button type="submit">Submit</Button>
													</Col>
												</Form.Group>
											</Form>
										</Modal.Body>
											<Modal.Footer>
												<Button variant="secondary" className="white-btn"  onClick={this.handleEditClose}>
													Close
												</Button>
											</Modal.Footer>
										</Modal>
                                    <div className="contractor_detail_header">
										<div className="contractor_detail_header_category">{this.state.contractor_detail.contractorCategory}
											<Button className="contractor_edit_btn" onClick={this.handleEditShow}>Edit</Button>
										</div>
									</div> 
                                    <div className="contractor_detail_body">
                                        <div className="contractor_detail_contractor">{this.state.contractor_detail.name}</div>
										<div className="contractor_detail_location">{this.state.contractor_detail.city}, {this.state.contractor_detail.state}</div>
                                        {/* <div className="contractor_detail_category">{}</div> */}
                                        <Form 
                                            className="contractorDetailForm form_no_modal" 
											>
                                            <Form.Group as={Row} controlId="formHorizontalUser">
                                            <Form.Control 
                                                        type="hidden" 
                                                        name='userId' 
                                                        value={this.state.userId} 
                                                        // onChange={this._handleInput} 
                                                        // required
														/>
                                                <Form.Control 
                                                        type="hidden" 
                                                        name='categoryId' 
                                                        value={this.state.categoryId} 
                                                        // onChange={this._handleInput} 
                                                        // required
														/>
                                                    <Form.Control 
                                                        type="hidden" 
                                                        name='propertyId' 
                                                        value={this.state.propertyId} 
                                                        // onChange={this._handleInput} 
                                                        // required
														/>
                                            </Form.Group>
											<div className="contractor_detail_container">
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={1}>
														<Form.Label className="formCardTitle">Detail: </Form.Label>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalCategoryDetailPhone">
													<Col md={2}>
														<Form.Label className="formCardTitle">Phone: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="tel" 
															value={this.state.contractorPhone}
															name='contractorPhone' 
															readOnly
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalContractor">
													<Col md={2}>
														<Form.Label className="formCardTitle">Address1: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="text" 
															value={this.state.contractorAddress1}
															name='contractorAddress1' 
															readOnly
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={2}>
														<Form.Label className="formCardTitle">Address2: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="text" 
															value={this.state.contractorAddress2}
															name='contractorAddress2' 
															readOnly
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={2}>
														<Form.Label className="formCardTitle">City: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="text" 
															name='contractorCity' 
															value={this.state.contractorCity}
															readOnly
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalCategoryState">
													<Col md={2}>
														<Form.Label className="formCardTitle">State: </Form.Label>
													</Col>
													<Col md={6}>
													<Form.Control 
															as="select" 
															name='contractorState' 
															readOnly
															onChange={this._handleInput} 
															value={this.state.contractorState}
															// defaultValue={this.state.contractorState}
															>
															<option value="">Choose...</option>
															<option value="AL">Alabama</option>
															<option value="AK">Alaska</option>
															<option value="AZ">Arizona</option>
															<option value="AR">Arkansas</option>
															<option value="CA">California</option>
															<option value="CO">Colorado</option>
															<option value="CT">Connecticut</option>
															<option value="DE">Delaware</option>
															<option value="DC">District Of Columbia</option>
															<option value="FL">Florida</option>
															<option value="GA">Georgia</option>
															<option value="HI">Hawaii</option>
															<option value="ID">Idaho</option>
															<option value="IL">Illinois</option>
															<option value="IN">Indiana</option>
															<option value="IA">Iowa</option>
															<option value="KS">Kansas</option>
															<option value="KY">Kentucky</option>
															<option value="LA">Louisiana</option>
															<option value="ME">Maine</option>
															<option value="MD">Maryland</option>
															<option value="MA">Massachusetts</option>
															<option value="MI">Michigan</option>
															<option value="MN">Minnesota</option>
															<option value="MS">Mississippi</option>
															<option value="MO">Missouri</option>
															<option value="MT">Montana</option>
															<option value="NE">Nebraska</option>
															<option value="NV">Nevada</option>
															<option value="NH">New Hampshire</option>
															<option value="NJ">New Jersey</option>
															<option value="NM">New Mexico</option>
															<option value="NY">New York</option>
															<option value="NC">North Carolina</option>
															<option value="ND">North Dakota</option>
															<option value="OH">Ohio</option>
															<option value="OK">Oklahoma</option>
															<option value="OR">Oregon</option>
															<option value="PA">Pennsylvania</option>
															<option value="RI">Rhode Island</option>
															<option value="SC">South Carolina</option>
															<option value="SD">South Dakota</option>
															<option value="TN">Tennessee</option>
															<option value="TX">Texas</option>
															<option value="UT">Utah</option>
															<option value="VT">Vermont</option>
															<option value="VA">Virginia</option>
															<option value="WA">Washington</option>
															<option value="WV">West Virginia</option>
															<option value="WI">Wisconsin</option>
															<option value="WY">Wyoming</option>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={2}>
														<Form.Label className="formCardTitle">Zip Code: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="number" 
															value={this.state.contractorZipCode}
															name='contractorZipCode' 
															readOnly 
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalEmail">
													<Col md={2}>
														<Form.Label className="formCardTitle">Email: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="email" 
															value={this.state.contractorEmail}
															name='contractorEmail' 
															readOnly
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalWebsite">
													<Col md={2}>
														<Form.Label className="formCardTitle">Website: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="url" 
															value={this.state.contractorWebsite}
															name='contractorWebsite' 
															readOnly
															>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row}>
													<Col sm={{ span: 10 }}>
														<Button type="button" onClick={this.deleteContractor}>Delete</Button>
													</Col>
												</Form.Group>
											</div>
                                    	</Form>
                                    </div>
                                </div>
		} else {
			contractorDetailDisplay = <div></div>
		}
      
		return (
			<div className="parent-container parent_container_contractor Fade">
                <Container>
                    <Row>
                        <Col sm={4}>
                           {contractorsDisplay}
                        </Col>
                        <Col sm={8}>
							{loaderDisplay}
                            {contractorDetailDisplay}
                        </Col>
                    </Row>
                </Container>
			</div>
		);
  	}
}

export default Contractors;