import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "react-bootstrap";

const LoginButton = () => {
	const { loginWithRedirect } = useAuth0();
	// const { loginWithPopup } = useAuth0();
	// const { getAccessTokenSilently } = useAuth0();
	// return <button onClick={() => loginWithRedirect()}>Log In</button>;

	// const loginTasks = () => {
	// 	localStorage.clear();
	// 	sessionStorage.clear();
	// 	sessionStorage.setItem("logged_in", 'false');
	// 	localStorage.setItem("redirect_url", '/');
	// }

	return (
		<Button
			variant="primary"
			className=""
			// onClick={this.handleShow}
			onClick={() => loginWithRedirect({
				appState: { 
					
					returnTo : '/register' 
				},
				screen_hint : "login",
				redirect_uri : '/register',
				returnTo : '/register' 
			})}
		
			>
				Log&nbsp;In
		</Button>
	)
};

export default LoginButton;