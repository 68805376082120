import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
// import { useAuth0 } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
	const domain   = process.env.REACT_APP_AUTH0_DOMAIN;
	const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

	const history  = useHistory();

	// const { getTokenSilently } = useAuth0();

	const onRedirectCallback = (appState) => {
		// console.log('redirecting', appState)
		history.push(appState?.returnTo || window.location.pathname);
	};

	if (localStorage.getItem("redirect_url")){
		var url = localStorage.getItem("redirect_url");
	} else {
		var url = '/';
	}

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			redirectUri={window.location.origin + url}
			onRedirectCallback={onRedirectCallback}
			audience='homehistory.io/api'
		>
			{children}
		</Auth0Provider>
	);
};

export default Auth0ProviderWithHistory;