import React, { Component } from 'react';
import './css/Category.css';
import {Container, Col, Row, Button, Form, ListGroup, Modal, Table, Alert} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faFileDownload, faTimesCircle, faFileWord, faFile, faFileImage, faFileExcel, faFilePdf, faCalendar,faSort } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import $ from 'jquery';
import history from "../history";
import dateFormat from 'dateformat';
import moment from 'moment';
import ImageGallery from 'react-image-gallery';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
moment.locale("en-US");

class Category extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error                  : null,
			isLoaded               : false,
			categories             : [],
			properties             : [],
			records                : [],
			record_html            : '',
			categoryId             : '',
			userId                 : '',
			detail                 : '',
			title                  : '',
			propertyId             : null,
			recordsLoadedCat       : false, 
            showRecordAdd          : false,
			recordDetailLoaded     : false,
			startDate              : '',
			endDate                : '',
			showRecordEdit         : false,
			display_date           : '',
			totalCost              : '',
			contractor_name_hidden : true,
			contractorName         : '',
			contractorCategoryId   : '',
			recordCostsLoaded      : false,
			recordCosts            : [],
			showAddCost            : false,
			showAddImage           : false,
			loadImage              : false,
			showAddDocument        : false,
			loadDocument           : false,
			recordDocuments        : [],
			recordImagePath        : '',
			recordsCount           : 0,
			showDocAlert           : false,
			showImageAlert         : false,
			message        : "",
			imageGallery   : [],
			recordId       : '',
			amount         : '',
			paymentDate    : '',
			contractorId   : '',
			formFile       : '',
			fileName       : '',
			docsDisplay    : [],
			recordDetail   : [],
			contractorsLoaded : false,
			category      : [],
			contractors   : [],
			record_detail : [],
			record_costs  : [],
			showLoader    : false,
			imageId       : '',
			allImages     : [],
			cal_events    : [],
			loadCalendar  : false,
			serviceTechName    : '',
			serviceTechPhone   : '',
			serviceTechEmail   : '',
			displayServiceTech : false,
			recordLinks       : [],
			displayRecordLinks : false,
			recordLink         : '',
			nextSort           : 'DESC',
			all_records        : []
		};

		this._handleInput                 = this._handleInput.bind(this);
		this.addRecord                    = this.addRecord.bind(this);
		this.updateRecord                 = this.updateRecord.bind(this);
		this.deleteRecord                 = this.deleteRecord.bind(this);
		this.showDetail                   = this.showDetail.bind(this);
		this.handleAddShow                = this.handleAddShow.bind(this);
		this.handleAddClose               = this.handleAddClose.bind(this);
		this.handleEditShow               = this.handleEditShow.bind(this);
		this.handleEditClose              = this.handleEditClose.bind(this);
		this.recordPost                   = this.recordPost.bind(this);
		this.getRecordCosts               = this.getRecordCosts.bind(this);
		this.handleAddCostShow            = this.handleAddCostShow.bind(this);
		this.handleAddCostClose           = this.handleAddCostClose.bind(this);
		this.handleCalendarClose          = this.handleCalendarClose.bind(this);
		this.handleCalendarShow           = this.handleCalendarShow.bind(this);
		this.addRecordCost                = this.addRecordCost.bind(this);
		this.getRecordsByPropertyCategory = this.getRecordsByPropertyCategory.bind(this);
		this.getCategory                  = this.getCategory.bind(this);
		this.getCategories                = this.getCategories.bind(this);
		this.getCurrentProperty           = this.getCurrentProperty.bind(this);
		this.loadInitial                  = this.loadInitial.bind(this);
		this.handleAddImageShow           = this.handleAddImageShow.bind(this);
		this.handleAddImageClose          = this.handleAddImageClose.bind(this);
		this.addRecordImage               = this.addRecordImage.bind(this);
		this.setSelectedImage             = this.setSelectedImage.bind(this);
		this.handleAddDocumentShow        = this.handleAddDocumentShow.bind(this);
		this.handleAddDocumentClose       = this.handleAddDocumentClose.bind(this);
		this.addRecordDocument            = this.addRecordDocument.bind(this);
		this.setSelectedDocument          = this.setSelectedDocument.bind(this);
		this.deleteCost                   = this.deleteCost.bind(this);
		this.deleteDocument               = this.deleteDocument.bind(this);
		this.deleteImage                  = this.deleteImage.bind(this);
		this.renderImageDelete            = this.renderImageDelete.bind(this);
		this.refImg                       = React.createRef();
		this.calRecordSelected            = this.calRecordSelected.bind(this);
		this.deleteLink                   = this.deleteLink.bind(this);
		this.sortRecords                  = this.sortRecords.bind(this);
		this.searchRecords                = this.searchRecords.bind(this);
		this.getRecordsByProperty         = this.getRecordsByProperty.bind(this);
	} 

	componentDidMount(){
		// set Interval
		setTimeout(
			function() {
				this.loadInitial();
			}
			.bind(this),
			500
		);
	}

	loadInitial() {

		axios.defaults.headers.common = {
			'Authorization': 'Bearer ' + sessionStorage.getItem('token')
		};

		$('.addRecordForm').hide();
	
		const userId = sessionStorage.getItem('userID');
		this.setState({ userId });

		// this.getPropertiesByUser(userId);
		this.getCurrentProperty();
	};

	handleAddShow() {
		
		this.setState({ 
			showRecordAdd          : true,
			contractor_name_hidden : true,
			title                  : '',
			detail                 : '',
			startDate              : '',
			endDate                : '',
			amount                 : '',
			paymentDate            : '',
			serviceTechName        : '',
			serviceTechPhone       : '',
			serviceTechEmail       : '',
			recordLink             : ''          
		 });
	}
	
  handleAddClose() {
		this.setState({ showRecordAdd  : false });
	}

	handleEditShow() {
		this.setState({ showRecordEdit : true });
	}
	
  handleEditClose() {
		this.setState({ showRecordEdit : false });
	}

	handleAddCostShow() {
		this.setState({ showAddCost : true });
	}

	handleCalendarClose() {
		this.setState({ loadCalendar : false });
	}

	handleCalendarShow() {
		this.setState({ loadCalendar : true });
	}
	
  	handleAddCostClose() {
		this.setState({ showAddCost : false });
	}

	handleAddImageShow() {
		this.setState({ showAddImage : true });
	}
	
  	handleAddImageClose() {
		this.setState({ showAddImage : false });
	}

	handleAddDocumentShow() {
		this.setState({ showAddDocument : true });
	}
	
  	handleAddDocumentClose() {
		this.setState({ showAddDocument : false });
	}

	getRecordsByProperty(propertyId){

		try{
			axios({
				method: "get",
				url: process.env.REACT_APP_BASE_URL+"Records/prop/"+propertyId,
			})
			.then(res => {
				
				const records = res.data.result;
				// console.log('this records  :', records)

				var cal_events = records.map(record => ({'start': new Date(moment(record.startDate).format('YYYY-MM-DD')), 'end': new Date(moment(record.endDate).format('YYYY-MM-DD')) , 'title': record.title, allDay: true, recordId: record.recordId, recordCat: record.recordCategory['id']}));
				
				if (records.length > 0) {
					this.setState({ 
						cal_events   : cal_events,
					})
				}

				// if (records.length > 0) {
				// 	this.setState({ 
				// 		records,
				// 		all_records : records
				// 	}, () => {
				// 		this.sortRecords(null, records, 'DESC', this.state.categoryId, 'no');
				// 	})
				// }
			});
		} catch(ex){
			console.log('getRecordsByProperty exception : ', ex);
		}
	}

	// getPropertiesByUser(userId){

	// 	const categoryId = parseInt(this.props.match.params.catId);
	// 	// console.log('category ', categoryId);
	// 	this.setState({ categoryId });

	// 	try {
	// 		axios.get(process.env.REACT_APP_BASE_URL+"properties/user/" + userId)
	// 		.then(res => {
	// 			const properties = res.data.result;
	// 			// console.log('properties : ', properties);
	// 			if (properties.length > 0) {
	// 				var propertyId = properties[0].id;
	// 				this.setState({ properties, propertyId });
	// 			}

	// 			this.getRecordsByPropertyCategory(propertyId,categoryId);
	// 			this.getCategory(categoryId);
	// 			this.getCategories();
	// 		});
	// 	} catch(ex){
	// 		console.log('getPropertiesByUser exception : ', ex);
	// 	}
	// }

	getCurrentProperty(){
		this.setState({ 
			showLoader : true,
		});
		const categoryId = parseInt(this.props.match.params.catId);
		// console.log('category ', categoryId);
		this.setState({ categoryId });

		try{
			if (sessionStorage.getItem('currentActiveProperty') === null){
				axios.get(process.env.REACT_APP_BASE_URL+"properties/user/" + this.state.userId)
				.then(res => {
					const property = res.data.result[0];
					// console.log('property1 : ', property);
		
					this.setState({ 
						property,
						propertyId           : property.id, 
						propertyDescription  : property.name, 
						propertyPhone        : property.phoneNumber,  
						propertyAddress1     : property.address1,  
						propertyAddress2     : property.address2, 
						propertyCity         : property.city, 
						propertyZipCode      : property.zipCode, 
						propertyState        : property.state, 
						propertyPurchaseDate : property.purchaseDate,  
						propertyYearBuilt    : property.yearBuilt, 
						propertyRoomCt       : property.roomCt, 
						propertyBathCt       : property.bathCt,
						propertyImages       : property.propertyImages
					});
					this.getRecordsByProperty(property.id);
					this.getRecordsByPropertyCategory(property.id ,categoryId);
					this.getCategory(categoryId);
					this.getCategories();
					
				});
			} else {
				let currentProperty = sessionStorage.getItem('currentActiveProperty');

				axios.get(process.env.REACT_APP_BASE_URL+"Properties/" + currentProperty)
				.then(res => {
					const property = res.data.result;
					// console.log('property2 : ', property);
					
					this.setState({ 
						property,
						propertyId           : property.id, 
						propertyDescription  : property.name, 
						propertyPhone        : property.phoneNumber,  
						propertyAddress1     : property.address1,  
						propertyAddress2     : property.address2, 
						propertyCity         : property.city, 
						propertyZipCode      : property.zipCode, 
						propertyState        : property.state, 
						propertyPurchaseDate : property.purchaseDate,  
						propertyYearBuilt    : property.yearBuilt, 
						propertyRoomCt       : property.roomCt, 
						propertyBathCt       : property.bathCt,
						propertyImages       : property.propertyImages,
						showPropertyEdit     : false 
					});	
					this.getRecordsByProperty(property.id);
					this.getRecordsByPropertyCategory(property.id ,categoryId);
					this.getCategory(categoryId);
					this.getCategories();
					
				});
			}
			// console.log("state :: ", this.state)
		} catch(ex){
			console.log('getCurrentProperty exception : ', ex);
		}
	}

	getCategories(){

		try{
			axios.get(process.env.REACT_APP_BASE_URL+"categories")
			.then(res => {
				const categories = res.data.result;
				this.setState({ 
					categories,
					categoriesLoaded : true 
				});
				// console.log('categories : ', categories)
				
				axios({
					method: "get",
					url: process.env.REACT_APP_BASE_URL+"Contractors/user/"+this.state.userId,
				})
					.then(res => {
						const contractors = res.data.result;
						this.setState({ 
							contractors,
							contractorsLoaded : true,
						});
						if (sessionStorage.getItem('record_detail')){
							// console.log('show detail from session')
							this.setState({ recordId :  sessionStorage.getItem('record_detail')});
							this.showDetail(sessionStorage.getItem('record_detail'));
						}
						// console.log('contractors : ', contractors)
				});
			});
		} catch(ex){
			console.log('getCategories exception : ', ex);
		}
	}

	async getCategory(categoryId){
		// var category = '';
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"categories/"+categoryId)
			.then(res => {
			
				const category = res.data.result;
				// console.log('category : ', category);
				this.setState({ 
					category, 
					recordsLoadedCat : true
				});
			});
		} catch(ex){
			console.log('getCategory exception : ', ex);
		}
	}

	sortRecords (e, unsorted_records, dir){

		if (e){
			e.preventDefault();
		}
		// console.log('sorting');
		try{
			if (dir === 'ASC'){
				var records = unsorted_records.sort(function(a, b){
					var keyA = new Date(a.startDate),
						keyB = new Date(b.startDate);
					// Compare the 2 dates
					if(keyA < keyB) return -1;
					if(keyA > keyB) return 1;
					return 0;
				});
	
				this.setState({ 
					records,
					recordsCount : records.length,
					showLoader   : false,
					nextSort     : 'DESC'
				});	
	
			} else {
				var records = unsorted_records.sort(function(a, b){
					var keyA = new Date(a.startDate),
						keyB = new Date(b.startDate);
					// Compare the 2 dates
					if(keyA > keyB) return -1;
					if(keyA < keyB) return 1;
					return 0;
				});

				this.setState({ 
					records,
					recordsCount : records.length,
					showLoader   : false,
					nextSort     : 'ASC'
				});	
			}
		} catch(ex){
			console.log('sortRecords exception : ', ex);
		}
	}

	getRecordsByPropertyCategory(propertyId, categoryId){

		try{
			axios({
				method: "get",
				url: process.env.REACT_APP_BASE_URL+"Records/prop/"+propertyId+"/category/"+categoryId,
			})
			.then(res => {
				
				const records = res.data.result;

				// console.log('records result ', records);

				// var cal_events = records.map(record => ({'start': new Date(moment(record.startDate).format('YYYY-MM-DD')), 'end': new Date(moment(record.endDate).format('YYYY-MM-DD')) , 'title': record.title, allDay: true, recordId: record.recordId, recordCat: record.recordCategory['id']}));

				if (records.length > 0) {
					this.setState({ 
						records,
						recordsCount : records.length,
						// cal_events   : cal_events,
						all_records : records
					}, () => {
						this.sortRecords(null, records, 'DESC');
					})
				}
			});
		} catch(ex){
			console.log('getRecordsByPropertyCategory exception : ', ex);
		}
	}

    showDetail(recordId){
		this.setState({ 
			showLoader : true,
		});
        // console.log('showing detail', recordId);
        this.setState({ showRecordAdd : false });
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"records/"+recordId)
			.then(res => {
				const record_detail = res.data.result;
				// console.log("record_detail : ", record_detail);

				this.setState({ 
					record_detail,
					recordDetailLoaded : true,
					recordId           : record_detail.recordId, 
					contractorId       : record_detail.recordContractor.id,
					categoryId         : record_detail.recordCategory.id,
					title              : record_detail.title,  
					detail             : record_detail.detail,  
					startDate          : record_detail.startDate, 
					endDate            : record_detail.endDate,
					totalCost          : record_detail.totalCostFormatted,
				});

				if (record_detail.recordDocuments.length > 0){
					var docs = record_detail.recordDocuments;
					
					var docs_array = [];
					var icon = '';
					for(var i=0; i < docs.length; i++){
						if (docs[i].urL_Full.match(/.(jpg|jpeg|png|gif)$/i)){
							icon = 'image';
						} else if (docs[i].urL_Full.match(/.(xls|xlsx|xlsm)$/i)){
							icon = 'excel';
					 	} else if (docs[i].urL_Full.match(/.(doc|docx)$/i)){
							icon = 'word';
						} else if (docs[i].urL_Full.match(/.(pdf)$/i)){
							icon = 'pdf';
						} else {
							icon = 'file';
						}
						docs_array.push({'path': docs[i].urL_Full, 'icon': icon, 'documentId' : docs[i].documentId, description: docs[i].description});
					}
				
					// console.log('the docs: ', docs_array);
					this.setState({ 
						docsDisplay : docs_array,
						loadDocument   : true
					})
				} else {
					this.setState({ 
						docsDisplay     : '',
						loadDocument    : false
					})
				}

				if (record_detail.recordImages.length > 0){
					var images     = record_detail.recordImages;
					// , 'renderItem': this.myRenderItem(image.imageId)
					var the_images = images.map(image => ({'original': image.urL_Full, 'thumbnail': image.urL_Thumb ? image.urL_Thumb  : '', originalAlt: 'image_'+image.imageId, thumbnailAlt: 'image_'+image.imageId }));
					this.setState({ 
						imageGallery : the_images,
						loadImage    : true,
						allImages    : images
					})
				} else {
					this.setState({ 
						imageGallery : '',
						loadImage    : false
					})
				}
				if (record_detail.serviceTechName){
					this.setState({ 
						serviceTechName    : record_detail.serviceTechName,
						serviceTechPhone   : record_detail.serviceTechPhone,
						serviceTechEmail   : record_detail.serviceTechEmail,
						displayServiceTech : true
					})
				} else {
					this.setState({ 
						serviceTechName    : '',
						serviceTechPhone   : '',
						serviceTechEmail   : '',
						displayServiceTech : false
					})
				}

				if (record_detail.recordQuickLinks.length > 0){
					var links = record_detail.recordQuickLinks;
					// , 'renderItem': this.myRenderItem(image.imageId)
					// var the_images = images.map(image => ({'original': image.urL_Full, 'thumbnail': image.urL_Thumb ? image.urL_Thumb  : '', originalAlt: 'image_'+image.imageId, thumbnailAlt: 'image_'+image.imageId }));
					this.setState({ 
						recordLinks        : links,
						displayRecordLinks : true,
					})
				}
				
				if (dateFormat(record_detail.updated, 'mmmm d, yyyy') === 'January 1, 0001'){
					this.setState({ display_date : moment(record_detail.created,).format('LL') });

				} else {
					this.setState({ display_date : moment(record_detail.updated,).format('LL') });
				}
				this.getRecordCosts(record_detail.recordId);
                // console.log('record detail : ', record_detail);
				sessionStorage.removeItem("record_detail");
			});
		} catch(ex){
			console.log('showDetail exception : ', ex);
		}
	}
	
	addRecord(e){
		this.setState({ 
			showLoader : true,
		});
		e.preventDefault();

		if (this.state.contractor_name_hidden === false){

			var contractorCategoryId = this.state.categoryId;
			var contractorName       = this.state.contractorName;
			try{
				axios.post(process.env.REACT_APP_BASE_URL+'Contractors?userId='+this.state.userId, { 
					contractorCategoryId : contractorCategoryId,
					name                 : contractorName,	
				})
				.then((res) => {
					// console.log('we created a contractor', res);
					var contractorId = res.data.result.id;

					this.setState({
						contractorId : contractorId
					}, () => {
						// console.log("show list: ", this.state.listData);
						this.recordPost();
					});
				});
			} catch(ex){
				console.log('addRecord exception : ', ex);
			}
		} else {
			this.recordPost();
		}
	}

	getRecordCosts(recordId){

		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Records/costs/"+recordId)
			.then(res => {
				const record_costs = res.data.result;
			
				this.setState({ 
					record_costs,
					recordCostsLoaded : true,
					recordCostId      : record_costs.recordCostId, 
					cost              : record_costs.cost,
					costFormatted     : record_costs.costFormatted,
					paymentDate       : record_costs.paymentDate,  
					created           : record_costs.created,  
					updated           : record_costs.updated,
					showLoader        : false
				});
				// console.log('record_costs : ', record_costs);
			});
		} catch(ex){
			console.log('getRecordCosts exception : ', ex);
		}
	};

	deleteCost(e, costId){
		
		if (e){
			e.preventDefault();
		}
		var confirm = window.confirm('Are you sure you want to delete this cost?')
		if (confirm){
			this.setState({ 
				showLoader : true,
			});
			try{
				axios.delete(process.env.REACT_APP_BASE_URL+'Records/cost/' + costId, { 
					recordCostId : costId
				})
				.then((result) => {
					// this.getRecordCosts(this.state.recordId);
					this.getRecordsByPropertyCategory(this.state.propertyId,this.state.categoryId);
					this.showDetail(this.state.recordId);
				});
			} catch(ex){
				console.log('deleteRecordCost exception : ', ex);
			}
		}
	}

	addRecordCost(e){
		// console.log('top of record cost add');
		this.setState({ 
			showLoader : true,
		});
		if (e){
			e.preventDefault();
		}
	
		var recordId = this.state.recordId;
		var amount   = this.state.amount;
		if (this.state.paymentDate === undefined || this.state.paymentDate === ''){
			this.setState({ paymentDate : dateFormat('yyyy-mm-dd') });
		} 

		var paymentDate = this.state.paymentDate;
	
		try{
			axios.post(process.env.REACT_APP_BASE_URL+'Records/cost', { 
				recordId    : recordId,
				amount      : amount,
				paymentDate : paymentDate
			})
			.then((result) => {

				$('.addRecordCostForm').hide();
				this.getRecordsByPropertyCategory(this.state.propertyId,this.state.categoryId);
				this.setState({ showAddCost : false });
				// console.log('we added a cost', result);
				this.showDetail(recordId)
			});
		} catch(ex){
			console.log('addRecordCost exception : ', ex);
		}
	}

	setSelectedImage(e){
		// console.log('file', file);
		// console.log('file', file.name);
		// let name  = e.target.name;
		let value = e.target.value;

		this.setState({ 
			propertyImage  : value,
			formFile       : e.target.files[0],
			fileName       : e.target.files[0].name,
			showImageAlert : false,
		});
	}

	setSelectedDocument(e){
		// console.log('file', file);
		// console.log('file', file.name);
		// let name  = e.target.name;
		let value = e.target.value;

		this.setState({ 
			propertyDocument : value,
			formFile         : e.target.files[0],
			fileName         : e.target.files[0].name,
			showDocAlert     : false,
		});
	}

	addRecordImage(e){
		this.setState({ 
			showLoader : true,
		});
		e.preventDefault();

		var recordId = this.state.recordId;
		// console.log(this.state.formFile);
		// console.log(this.state.fileName);

		const formData = new FormData();

		var file = this.state.formFile;
		// console.log('file size: ', file);

		var upload = true;

		if (file.size > 15000000){
			
			upload = false;
			this.setState({ 
				showImageAlert : true,
				message        : 'This file is too large. Please choose a smaller file.'
			});
		}

		if (!file.name.match(/.(jpg|jpeg|png|gif)$/i)){
			upload = false;
			this.setState({ 
				showImageAlert : true,
				message        : 'Please choose an image file.'
			});
		}

		if (upload){
			formData.append('formFile', this.state.formFile);
			formData.append('fileName', this.state.fileName);
		
			try{
				axios.post(process.env.REACT_APP_BASE_URL+'Records/Image/'+recordId, formData, {
					headers: {
					  'Content-Type': 'multipart/form-data'
					}
				})
				.then((result) => {
	
					// console.log('image result ', result);
					const image_response = result.data.result;
					// console.log("image response : ", image_response)
					if (image_response) {
						this.setState({ 
							recordImagePath : image_response.path 
						});
						// console.log('record image path: ', this.state.recordImagePath)
					}
	
					$('.addRecordImageForm').hide();
					this.setState({ showAddImage : false });
					this.showDetail(recordId)
				});
			} catch(ex){
				console.log('addRecordImage exception : ', ex);
			}
		}
	}

	addRecordDocument(e){
		this.setState({ 
			showLoader : true,
		});
		e.preventDefault();

		var recordId = this.state.recordId;
		// console.log(this.state.formFile);
		// console.log(this.state.fileName);

		var file = this.state.formFile;
		// console.log('file size: ', file);

		var upload = true;

		if (file.size > 15000000){			
			upload = false;
			this.setState({ 
				showDocAlert : true,
				message      : 'This file is too large. Please choose a smaller file.'
			});
		}

		if (!file.name.match(/.(odt|xls|xlsx|ods|ppt|pptx|xlsm|rtf|tex|wpd|txt|pdf|jpg|jpeg|png|gif|doc|docx)$/i)){
			upload = false;
			
			// console.log('not file type');
			this.setState({ 
				showDocAlert : true,
				message      : 'Not an accepted file type. Please choose a different file.'
			});
		}

		if (upload){

			const formData = new FormData();

			formData.append('formFile', this.state.formFile);
			formData.append('fileName', this.state.fileName);
		
			try{
				axios.post(process.env.REACT_APP_BASE_URL+'Records/Document/'+recordId, formData, {
					headers: {
					'Content-Type': 'multipart/form-data'
					}
				})
				.then((result) => {

					// console.log('document result ', result);
					const document_response = result.data.result;
					// console.log("document_response : ", document_response)
					if (document_response) {
						this.setState({ 
							recordDocumentPath : document_response.path 
						});
						// console.log('record document path: ', this.state.recordDocumentPath)
					}

					$('.addRecordDocumentForm').hide();
					this.setState({ showAddDocument : false });
					this.showDetail(recordId)
				});
			} catch(ex){
				console.log('addRecordDocument exception : ', ex);
			}
		}
	}

	deleteDocument(e, docId){
		
		if (e){
			e.preventDefault();
		}
		var confirm = window.confirm('Are you sure you want to delete this document?')
		if (confirm){
			this.setState({ 
				showLoader : true,
			});
			try{
				// console.log('document delete ');
				axios.delete(process.env.REACT_APP_BASE_URL+"Documents/"+docId)
				.then((result) => {

					// console.log('document result ', result);
					const document_response = result.data.result;
					// console.log("document_response delete : ", document_response)
			
					this.showDetail(this.state.recordId)
				});
			} catch(ex){
				console.log('deleteDocument exception : ', ex);
			}
		}
	}

	deleteImage(e, index){
		if (e){
			e.preventDefault();
		}
		
		var imgId = this.state.allImages[index].imageId;
		// console.log('this image : ', imgId);
		// console.log('the index : ', index);
		var confirm = window.confirm('Are you sure you want to delete this image?')
		if (confirm){
			this.setState({ 
				showLoader : true,
			});
			try{
				// console.log('delete image ');
				axios.delete(process.env.REACT_APP_BASE_URL+"Images/"+imgId)
				.then((result) => {
	
					// console.log('document result ', result);
					const image_response = result.data.result;
					// console.log("image_response delete : ", image_response)
			
					this.showDetail(this.state.recordId)
				});
			} catch(ex){
				console.log('deleteImage exception : ', ex);
			}
		}
	}

	deleteLink(e, linkId){
		if (e){
			e.preventDefault();
		}
		
		var confirm = window.confirm('Are you sure you want to delete this link?')
		if (confirm){
			this.setState({ 
				showLoader : true,
			});
			try{
				// console.log('delete link ');
				axios.delete(process.env.REACT_APP_BASE_URL+"Records/Link/"+linkId)
				.then((result) => {
	
					// console.log('document result ', result);
					const link_response = result.data.result;
					// console.log("link_response delete : ", link_response)
			
					this.showDetail(this.state.recordId)
				});
			} catch(ex){
				console.log('deleteLink exception : ', ex);
			}
		}
	}

	recordPost(e){
		if (e){
			e.preventDefault();
		}
		var contractorId = this.state.contractorId;
		var propertyId   = this.state.propertyId;
		var categoryId   = this.state.categoryId;
		var title        = this.state.title;
		var detail       = this.state.detail;
		var startDate    = this.state.startDate;
		var endDate      = this.state.endDate;
		var amount       = this.state.amount;
		var serviceTechName  = this.state.serviceTechName;
		var serviceTechPhone = this.state.serviceTechPhone;
		var serviceTechEmail = this.state.serviceTechEmail;
		var recordLink       = this.state.recordLink;
		// var paymentDate  = this.state.paymentDate;
		try{
			axios.post(process.env.REACT_APP_BASE_URL+'records', { 
			
				contractorId : contractorId,
				propertyId   : propertyId,
				categoryId   : categoryId,
				title        : title, 
				detail       : detail,
				startDate    : startDate,
				endDate      : endDate,
				serviceTechName  : serviceTechName,
				serviceTechPhone : serviceTechPhone,
				serviceTechEmail : serviceTechEmail,
				
			})
			.then((res) => {
				// console.log('the record post result : ', res);
				const record_response = res.data.result;
				this.setState({ 
					recordId      : record_response.recordId,
					showRecordAdd : false
				});
				if (recordLink.length > 0){
					axios.post(process.env.REACT_APP_BASE_URL+'records/link', { 
						recordId : record_response.recordId,
						uri      : recordLink
					})
					.then((res) => {
						const record_link_response = res.data.result;
						// console.log('the record link post result : ', record_link_response);
					})
				}
				
				// console.log('amount : ', amount)
				if (amount){
					// console.log('record post record cost add');
					this.addRecordCost();
				}
				this.getCategories();
				$('.addRecordForm').hide();

				this.getRecordsByPropertyCategory(propertyId,categoryId);
				this.getRecordsByProperty(propertyId);
				this.showDetail(record_response.recordId);
				// console.log('we created a record', result);
			});
		} catch(ex){
			console.log('recordPost exception : ', ex);
		}
	}

	deleteRecord(){
	
		var recordId   = this.state.recordId;
		var propertyId = this.state.propertyId;
		var categoryId = this.state.categoryId;

		this.setState({ recordDetailLoaded : false });
		var confirm = window.confirm('Are you sure you want to delete this record?')
		if (confirm){
			this.setState({ 
				showLoader : true,
			});
			try{
				axios.delete(process.env.REACT_APP_BASE_URL+"Records/"+recordId)
					.then((result) => {
					// console.log('we deteled a contractor', result);
					this.getRecordsByPropertyCategory(propertyId,categoryId);
				});
			} catch(ex){
				console.log('deleteRecord exception : ', ex);
			}
		}
	}

	updateRecord(e){
		this.setState({ 
			showLoader : true,
		});
		e.preventDefault();
		var recordId     = this.state.recordId;
		var contractorId = this.state.contractorId;
		var propertyId   = this.state.propertyId;
		var categoryId   = this.state.categoryId;
		var title        = this.state.title;
		var detail       = this.state.detail;
		var startDate    = this.state.startDate;
		var endDate      = this.state.endDate;
		var serviceTechName  = this.state.serviceTechName;
		var serviceTechPhone = this.state.serviceTechPhone;
		var serviceTechEmail = this.state.serviceTechEmail;
		var recordLink       = this.state.recordLink;
		// var amount       = parseFloat(this.state.totalCost.replace(/\$|,/g, ''));
		// var paymentDate  = this.state.paymentDate;
	
		try{
			axios.put(process.env.REACT_APP_BASE_URL+'records/'+recordId, { 
				recordId     : recordId,
				contractorId : contractorId,
				propertyId   : propertyId,
				categoryId   : categoryId,
				title        : title, 
				detail       : detail,
				startDate    : startDate,
				endDate      : endDate,
				serviceTechName  : serviceTechName,
				serviceTechPhone : serviceTechPhone,
				serviceTechEmail : serviceTechEmail,
				// recordLink       : recordLink
			})
			.then((result) => {
				if (recordLink.length > 0){
					axios.post(process.env.REACT_APP_BASE_URL+'records/link', { 
						recordId : recordId,
						uri : recordLink
					})
					.then((res) => {
						const record_link_response = res.data.result;
						// console.log('the record link post result : ', record_link_response);
						$('.recordEditForm').hide();
						this.setState({ showRecordEdit : false });
						this.showDetail(recordId);
						this.getRecordsByPropertyCategory(propertyId,categoryId);
					})
				} else {
					$('.recordEditForm').hide();
					this.setState({ showRecordEdit : false });
					this.showDetail(recordId)
					this.getRecordsByPropertyCategory(propertyId,categoryId);
				}
				// this.updateRecordCost(recordId, amount, paymentDate);
				// console.log('we updated a record', result);
				
			});
		} catch(ex){
			console.log('updateRecord exception : ', ex);
		}
	}

	// updateRecordCost(recordId, amount, paymentDate){

	// 	try{
	// 		axios.post(process.env.REACT_APP_BASE_URL+'records/cost', { 
	// 			recordId    : recordId,
	// 			amount      : amount,
	// 			paymentDate : paymentDate
	// 		})
	// 		.then(res => {
	// 			// console.log('records result ', res);
	// 			const recordCost = res.data.result;
	// 			if (recordCost.length > 0) {
	// 				// this.setState({ records });
	// 				// this.setState({ recordsCount : records.length });
	// 			}
	// 		});
	// 	} catch(ex){
	// 		console.log('updateRecordCost exception : ', ex);
	// 	}
	// }

	_handleInput(event) {
		let obj  =  {}
		let key  = event.target.name;
		obj[key] = event.target.value;
		this.setState(obj);
		
		if (key === 'contractorId' && obj[key] === 'add_new'){
			this.setState({ contractor_name_hidden : false });
		} else if (key === 'contractorId' && obj[key] !== 'add_new'){
			this.setState({ contractor_name_hidden : true });
		}
	};

	searchRecords(e){
		let value = e.target.value.toLowerCase();
		let all_records = this.state.all_records;
		// console.log('all records : ', all_records);

		if (value.length > 0){
			var records = all_records.filter((record) => {
				return record.title.toLowerCase().search(value) !== -1;
			});
	
			// console.log('after search : ', records)
			this.setState({
				records,
				recordsCount : records.length
			});
		} else {
			// console.log('in the else: ', all_records);
			var records = this.state.all_records;
			this.setState({
				records,
				recordsCount : records.length
			});
		}
	};

	renderImageDelete = props => {
		return	<FontAwesomeIcon className="red-btn icon img_delete" onClick={(e) => { this.deleteImage(e, this.refImg.getCurrentIndex()) }} icon={ faTimesCircle }/>;
	};

	calRecordSelected(event){
		// history.push("/records/user=" + this.state.userId) + "/category=";
		this.setState({ loadCalendar : false });
		if (event.recordCat === this.state.categoryId){
			this.showDetail(event.recordId);
		} else {
			sessionStorage.setItem("record_detail", event.recordId);
			// window.location = '/records/user='+this.state.userId+'/category='+categoryId;
			history.push('/records/user='+this.state.userId+'/category='+event.recordCat);
		}
	}
	
	render() {

		const showLoader = this.state.showLoader;
		
		let loaderDisplay;

		if (showLoader) {
			loaderDisplay = 
						<div className="loader_container">
							<Loader
								type="Oval"
								color="#5FCE7F"
								height={100}
								width={100}
								timeout={10000} //10 secs
								visible={this.state.showLoader}
							/>
						</div>
		} else {
			loaderDisplay = "";
		}

		const showImageAlert = this.state.showImageAlert;
		const showDocAlert   = this.state.showDocAlert;
		
		let alertDisplay;

		if (showImageAlert || showDocAlert) {
			alertDisplay = 	
							<Row>
								<Col md={1}></Col>
								<Col md={5}>
									<Alert variant="danger" style={{ width: "26rem" }}>
										{/* <Alert.Heading> */}
											{this.state.message}
										{/* </Alert.Heading> */}
									</Alert>
								</Col>
								<Col md={6}></Col>
							</Row>;
							
		} else {
			alertDisplay = "";
		}

		const loadImage = this.state.loadImage;
		let imageDisplay;

		if (loadImage) {
			imageDisplay = 	<div className="img_container">
								<ImageGallery 
									ref={i => this.refImg = i}
									items={this.state.imageGallery} 
									showBullets={true} 
									renderCustomControls={this.renderImageDelete}
								/>
							</div>
		} else {
			imageDisplay = <div className="img_container"></div>
		}

		const loadServiceTech = this.state.displayServiceTech;
		let serviceTechDisplay;

		if (loadServiceTech) {
			serviceTechDisplay = <div className="service_tech_container">
									<Row>
										<Col md={3}>
											<b>Service Technician:</b>
										</Col>
									</Row>
									<Row>
										<Col md={1}>
											Name:
										</Col>
										<Col md={5}>
											{this.state.serviceTechName}
										</Col>
									</Row>
									<Row>
										<Col md={1}>
											Phone:
										</Col>
										<Col md={5}>
											{this.state.serviceTechPhone}
										</Col>
									</Row>
									<Row>
										<Col md={1}>
											Email:
										</Col>
										<Col md={5}>
											{this.state.serviceTechEmail}
										</Col>
									</Row>
								</div>
		} else {
			serviceTechDisplay = <div className="service_tech_container"></div>
		}

		const loadRecordLinks = this.state.displayRecordLinks;
		let recordLinksDisplay;

		if (loadRecordLinks) {
			recordLinksDisplay = <div className="record_links_container">
									
										{this.state.recordLinks.map((link, index) => 
											<Row>
												<Col md={6}>
													<a href={link.uri}  className="record_link" key={link.recordQuickLinkId} target="_blank" rel="noopener noreferrer">
														{link.uri}
													</a>
													<FontAwesomeIcon className="red-btn icon link_delete" onClick={(e) => { this.deleteLink(e, link.recordQuickLinkId) }} key={index} icon={ faTimesCircle }/>
												</Col>
											</Row>
										)}
									
									
								</div>
		} else {
			recordLinksDisplay = <div className="record_links_container"></div>
		}

		const loadDocument = this.state.loadDocument;
		let documentDisplay;
    	if (loadDocument) {
			documentDisplay = <div className="document_container">
								<Row>
									{this.state.docsDisplay.map((doc, index) => 
										<Col md={1}>
											<a href={doc.path}  className="doc_link" download={doc.description} key={doc.decscription} target="_blank" rel="noopener noreferrer">
												{doc.icon === 'word'  ? <FontAwesomeIcon className="icon doc_icon" icon={faFileWord}     key={index}/> : null }
												{doc.icon === 'excel' ? <FontAwesomeIcon className="icon doc_icon" icon={faFileExcel}    key={index}/> : null }
												{doc.icon === 'pdf'   ? <FontAwesomeIcon className="icon doc_icon" icon={faFilePdf}      key={index}/> : null }
												{doc.icon === 'file'  ? <FontAwesomeIcon className="icon doc_icon" icon={faFileDownload} key={index}/> : null }
												{doc.icon === 'image' ? <FontAwesomeIcon className="icon doc_icon" icon={faFileImage}    key={index}/> : null }
											</a>
											{/* <Button variant="secondary" className="doc_delete" onClick={(e) => { this.deleteDocument(e, doc.documentId) }} key={doc.documentId}>
												X
											</Button> */}
											<FontAwesomeIcon className="red-btn icon doc_delete" onClick={(e) => { this.deleteDocument(e, doc.documentId) }} key={doc.documentId} icon={ faTimesCircle }/>
										</Col>
										
									)}
				 				</Row>
							</div>
		} else {
			documentDisplay = <div className="document_container"></div>
		}

		const loadRecordCosts = this.state.recordCostsLoaded;
		let recordCostsDisplay;

		if (loadRecordCosts) {
			recordCostsDisplay = <div className="records_costs_container">
									<Table striped bordered >
										<thead>
											<tr>
												<th>Payment Date</th>
												<th>Cost</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{this.state.record_costs.map(recordCost => 
												<tr key={recordCost.recordCostId}>
													<td>{dateFormat(recordCost.paymentDate, 'm/dd/yyyy')}</td>
													<td>{recordCost.costFormatted}</td>													
													<td><FontAwesomeIcon className="red-btn icon" onClick={(e) => { this.deleteCost(e, recordCost.recordCostId) }} icon={ faTimesCircle }/></td>
												</tr>
											)}
											<tr className="top_border">
												<td>Total:</td>
												<td >{this.state.record_detail.totalCostFormatted}</td>
												<td></td>
											</tr>
										</tbody>
									</Table>
								</div>;
		} else {
			recordCostsDisplay = <div></div>;
		}
		
		const loadRecords = (this.state.recordsLoadedCat && this.state.contractorsLoaded);
        let recordsDisplay;
       
    	if (loadRecords) {
			recordsDisplay = <div className="records_container"> 
								<div>
									<div className="records_container_header">
										<Row className="top_line_records">
											<Col md={10} className="record_category_name">{this.state.category.name}</Col>
											<Col md={2} className="record_calendar_button">
												<FontAwesomeIcon className="calendar_button icon" onClick={this.handleCalendarShow} icon={ faCalendar }/>
											</Col>
										</Row>
                                        <Row className="records_header_line_2">
                                            <Col md={3} className="records_count">{this.state.recordsCount} Projects</Col>
											<Col md={4} className="records_search">
												<input type="text" className="search_input" placeholder="Search" onChange={this.searchRecords}/>
											</Col>
											<Col md={2} className="record_sort_button">
												<FontAwesomeIcon className="sort_records icon" onClick={(e) => { this.sortRecords(e, this.state.records, this.state.nextSort) }} icon={ faSort }/>
                                            </Col>
                                            <Col md={2} className="record_add_button">
												<FontAwesomeIcon className="add_record_plus icon" onClick={this.handleAddShow} icon={ faPlusCircle }/>
                                            </Col>
                                        </Row>
									</div>
                                    <Modal show={this.state.showRecordAdd} size='lg' onHide={this.handleAddClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Add a Record</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
											<Form 
												className="formCard addRecordForm" 
												onSubmit={this.addRecord}>
												<div className="new_record_title">New Record</div>
												<Form.Group as={Row} controlId="formHorizontalRecordTitle">
													<Col md={4}>
														<Form.Label className="formCardTitle">Title: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															placeholder="title" 
															name='title' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalPropertyId">
													<Form.Control 
														type="hidden" 
														name='propertyId' 
														value={this.state.propertyId} 
														onChange={this._handleInput} 
														required/>
													<Col md={4}>
														<Form.Label className="formCardTitle">Category: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="select" 
															name='categoryId' 
															onChange={this._handleInput} 
															defaultValue={this.state.categoryId}>
															<option value="">Choose...</option>
															{this.state.categories.map(category => 
																<option key={category.id} value={category.id}>{category.name}</option>
															)};
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalContractor">
													<Col md={4}>
														<Form.Label className="formCardTitle">Contractor: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="select" 
															name='contractorId' 
															onChange={this._handleInput} 
															defaultValue="Choose...">
															<option value="">Choose...</option>
															<option value="add_new">Add New</option>
															{this.state.contractors.map(contractor => 
																<option key={contractor.id} value={contractor.id}>{contractor.name}</option>
															)};
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalContractorName" style={{display: this.state.contractor_name_hidden ? 'none' : 'flex' }}>
													<Col md={4}>
														<Form.Label className="formCardTitle">Contractor Name: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															// hidden = { this.state.contractor_name_hidden ? true : false } 
															type="text" 
															placeholder="Name" 
															name='contractorName' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Detail: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="textarea" 
															rows="3"
															placeholder="detail" 
															name='detail' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Start Date: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="date" 
															// value={dateFormat(this.state.startDate, 'yyyy-mm-dd')} 
															name='startDate' 
															required
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">End Date: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="date" 
															name='endDate' 
															required
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalCost">
													<Col md={4}>
														<Form.Label className="formCardTitle">Cost: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="number" 
															name='amount' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Payment Date: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="date" 
															name='paymentDate' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalLink">
													<Col md={4}>
														<Form.Label className="formCardTitle">Link: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="url" 
															name='recordLink' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalTechName">
													<Col md={4}>
														<Form.Label className="formCardTitle">Service Tech Name: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='serviceTechName' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalTechPhone">
													<Col md={4}>
														<Form.Label className="formCardTitle">Service Tech Phone: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="tel" 
															name='serviceTechPhone' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalTechEmail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Service Tech Email: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="email" 
															name='serviceTechEmail' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row}>
													<Col sm={{ span: 10 }}>
														<Button type="submit">Submit</Button>
													</Col>
												</Form.Group>
											</Form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" className="white-btn"  onClick={this.handleAddClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
									</Modal>
									<ListGroup defaultActiveKey="#link1">
										{this.state.records.map(record => 
										
											<div className="record_container" key={record.recordId} >
												<ListGroup.Item action onClick={() => this.showDetail(record.recordId)}>
                                                    <div className="record_list_top_line">
                                                        <div className="record_list_contractor">{record.recordContractor.name}</div>
                                                        <div className="record_list_date">{dateFormat(record.startDate, 'm/dd/yyyy')}</div>
                                                    </div>
													<div className="record_list_title" >{record.title}</div>
													{/* {record.recordNotes.map(note => 
														<div className="record_list_title" key={note.noteId}>{note.note}</div>
													)} */}
													
													<div className="record_list_price">{record.totalCostFormatted}</div>
												</ListGroup.Item>
											</div>
										)}
									</ListGroup>
								</div>
							</div>;
		} else {
			recordsDisplay = <div></div>;
        }
        
        const loadRecordDetail = this.state.recordDetailLoaded;
        let recordDetailDisplay;
        
    	if (loadRecordDetail) {
			recordDetailDisplay = <div className="record_detail_container">
									
									<Modal show={this.state.showRecordEdit} size='lg' onHide={this.handleEditClose}>
										<Modal.Header closeButton>
											<Modal.Title>Edit Record</Modal.Title>
										</Modal.Header>
											<Modal.Body>
												<Form 
													className="formCard RecordEditForm" 
													onSubmit={this.updateRecord}
													>
													<div className="cardTitle">Record Information</div>
													<Form.Group as={Row} controlId="formHorizontalRecordTitle">
														<Col md={4}>
															<Form.Label className="formCardTitle">Title: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																// placeholder="title" 
																name='title' 
																onChange={this._handleInput} 
																value={this.state.title}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalRecordId">
														<Form.Control 
															type="hidden" 
															name='recordId' 
															value={this.state.recordId} 
															onChange={this._handleInput} 
															required/>
														<Form.Control 
															type="hidden" 
															name='propertyId' 
															value={this.state.propertyId} 
															onChange={this._handleInput} 
															// required
															/>
														<Col md={4}>
															<Form.Label className="formCardTitle">Category: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																as="select" 
																name='categoryId' 
																onChange={this._handleInput} 
																defaultValue={this.state.categoryId}>
																<option value="">Choose...</option>
																{this.state.categories.map(category => 
																	<option key={category.id} value={category.id}>{category.name}</option>
																)};
															</Form.Control>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalContractor">
														<Col md={4}>
															<Form.Label className="formCardTitle">Contractor: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																as="select" 
																name='contractorId' 
																onChange={this._handleInput} 
																defaultValue={this.state.contractorId}>
																<option value="">Choose...</option>
																{this.state.contractors.map(contractor => 
																	<option key={contractor.id} value={contractor.id}>{contractor.name}</option>
																)};
															</Form.Control>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalDetail">
														<Col md={4}>
															<Form.Label className="formCardTitle">Detail: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																as="textarea" 
																rows="3"
																// placeholder="detail" 
																name='detail' 
																value={this.state.detail} 
																onChange={this._handleInput} 
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalDetail">
														<Col md={4}>
															<Form.Label className="formCardTitle">Start Date: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="date"
																// defaultValue={dateFormat(this.state.startDate, 'yyyy-mm-dd')} 
																value={ this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : null } 
																// value={this.state.startDate} 
																name='startDate'
																required 
																onChange={this._handleInput} 
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalDetail">
														<Col md={4}>
															<Form.Label className="formCardTitle">End Date: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="date" 
																// defaultValue={dateFormat(this.state.endDate, 'yyyy-mm-dd')} 
																value={ this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : null } 
																name='endDate' 
																required
																onChange={this._handleInput} 
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalLink">
														<Col md={4}>
															<Form.Label className="formCardTitle">Link: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="url" 
																name='recordLink' 
																onChange={this._handleInput} 
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalTechName">
														<Col md={4}>
															<Form.Label className="formCardTitle">Service Tech Name: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='serviceTechName' 
																value={this.state.serviceTechName} 
																onChange={this._handleInput} 
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalTechPhone">
														<Col md={4}>
															<Form.Label className="formCardTitle">Service Tech Phone: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="tel" 
																name='serviceTechPhone' 
																value={this.state.serviceTechPhone}
																onChange={this._handleInput} 
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalTechEmail">
														<Col md={4}>
															<Form.Label className="formCardTitle">Service Tech Email: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="email" 
																name='serviceTechEmail' 
																value={this.state.serviceTechEmail} 
																onChange={this._handleInput} 
																/>
														</Col>
													</Form.Group>
													{/* <Form.Group as={Row} controlId="formHorizontalDetail">
														<Col md={4}>
															<Form.Label className="formCardTitle">Payment Date: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="date"
																// value={dateFormat(this.state.startDate, 'yyyy-mm-dd')} 
																// value={this.state.startDate} 
																name='paymentDate' 
																onChange={this._handleInput} 
																/>
														</Col>
													</Form.Group> */}
													<Form.Group as={Row}>
														<Col sm={{ span: 10 }}>
															<Button type="submit">Submit</Button>
														</Col>
													</Form.Group>
												</Form>
											</Modal.Body>
											<Modal.Footer>
												<Button variant="secondary" className="white-btn"  onClick={this.handleEditClose}>
													Close
												</Button>
											</Modal.Footer>
										</Modal>
                                    <div className="records_detail_header">
										<div className="record_detail_header_last_edit">Last Edit: {this.state.display_date}
											<Button className="record_edit_btn" onClick={this.handleEditShow}>Edit</Button>
										</div>
                                        {/* <div className="record_detail_header_line_2">
                                            line2
                                        </div> */}
									</div> 
                                    <div className="record_detail_body">
										<Row>
											<Col sm={{ span: 10 }}>
												<div className="record_detail_contractor">{this.state.record_detail.recordContractor.name}</div>
											</Col>
											<Col sm={{ span: 2 }} className="text-right delete_record_btn">
												<Button type="button" onClick={this.deleteRecord}>Delete&nbsp;Project</Button>
											</Col>
										</Row>
                                        
										<div className="record_detail_title">{this.state.record_detail.title}</div>
                                        <div className="record_detail_date">{ moment(this.state.record_detail.startDate,).format('LL') } </div>
										{/* value={ this.state.record_detail.created, ? moment(this.state.record_detail.created,).format('LL') : null }  */}

										
                                        <Form 
                                            className="recordDetailForm" 
                                            // onSubmit={this.updateRecord}
											>
                                            <Form.Group as={Row} controlId="formHorizontalUser">
                                            <Form.Control 
                                                        type="hidden" 
                                                        name='userId' 
                                                        value={this.state.userId} 
                                                        // onChange={this._handleInput} 
                                                        // required
														/>
                                                <Form.Control 
                                                        type="hidden" 
                                                        name='categoryId' 
                                                        value={this.state.categoryId} 
                                                        // onChange={this._handleInput} 
                                                        // required
														/>
                                                    <Form.Control 
                                                        type="hidden" 
                                                        name='propertyId' 
                                                        value={this.state.propertyId} 
                                                        // onChange={this._handleInput} 
                                                        // required
														/>
                                            </Form.Group>
											<div className="record_detail_container">
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={1}>
														<Form.Label className="formCardTitle">Detail: </Form.Label>
													</Col>
	
													<Col md={6}>
														<Form.Control 
															className="record_detail_description"
															as="textarea" 
															rows="5"
															placeholder="detail" 
															name='detail' 
															value={this.state.record_detail.detail}
															// onChange={this._handleInput} 
															readOnly
															/>
														</Col>
													</Form.Group>
													{serviceTechDisplay}
													<Row className="add_toolbar">
														<Col md={2}>
															<div className="record_detail_add_cost">
																<Button className="record_addCost_btn" onClick={this.handleAddCostShow}>Add Cost</Button>
															</div>
														</Col>
														<Col md={2}>
															<div className="record_detail_add_image">
																<Button className="record_addImage_btn" onClick={this.handleAddImageShow}>Add&nbsp;Image</Button>
															</div>
														</Col>
														<Col md={2}>
															<div className="record_detail_add_document">
																<Button className="record_addDocument_btn" onClick={this.handleAddDocumentShow}>Add&nbsp;Document</Button>
															</div>
														</Col>
													</Row>
												
													<Modal show={this.state.showAddCost} size='lg' onHide={this.handleAddCostClose}>
														<Modal.Header closeButton>
															<Modal.Title>Add a Record Cost</Modal.Title>
														</Modal.Header>
														<Modal.Body>
															<Form 
																className="formCard addRecordCostForm" 
																onSubmit={e => this.addRecordCost(e)}>
																
																<div className="new_record_title">New Record Cost</div>
																<Form.Control 
																	type="hidden" 
																	name='recordId' 
																	value={this.state.recordId} 
																	required/>
																<Form.Group as={Row} controlId="formHorizontalCost">
																	<Col md={4}>
																		<Form.Label className="formCardTitle">Cost: </Form.Label>
																	</Col>
																	<Col md={6}>
																		<Form.Control 
																			type="number" 
																			name='amount' 
																			onChange={this._handleInput} 
																			/>
																	</Col>
																</Form.Group>
																<Form.Group as={Row} controlId="formHorizontalPaymentDate">
																	<Col md={4}>
																		<Form.Label className="formCardTitle">Payment Date: </Form.Label>
																	</Col>
																	<Col md={6}>
																		<Form.Control 
																			type="date" 
																			name='paymentDate' 
																			onChange={this._handleInput} 
																			/>
																	</Col>
																</Form.Group>
																<Form.Group as={Row}>
																	<Col sm={{ span: 10 }}>
																		<Button type="submit">Submit</Button>
																	</Col>
																</Form.Group>
															</Form>
														</Modal.Body>
														<Modal.Footer>
															<Button variant="secondary" className="white-btn"  onClick={this.handleAddCostClose}>
																Close
															</Button>
														</Modal.Footer>
													</Modal>
													
													<Modal show={this.state.showAddImage} size='lg' onHide={this.handleAddImageClose}>
														<Modal.Header closeButton>
															<Modal.Title>Add a Record Image</Modal.Title>
														</Modal.Header>
														<Modal.Body>
															<Form 
																className="formCard addRecordImageForm" 
																onSubmit={this.addRecordImage}>
																<div className="new_record_title">New Record Image</div>
																<Form.Control 
																	type="hidden" 
																	name='recordId' 
																	value={this.state.recordId} 
																	required/>
																<Form.Group as={Row} controlId="formHorizontalRecordImage">
																	<Col md={4}>
																		<Form.Label className="formCardTitle">Image: </Form.Label>
																	</Col>
																	<Col md={6}>
																		<Form.Control 
																			type="file" 
																			// placeholder="name" 
																			className='recordImage'
																			name='recordImage' 
																			// onChange={this._handleInput} 
																			onChange={(e) => this.setSelectedImage(e)}
																			/>
																	</Col>
																</Form.Group>
																{alertDisplay}
																<Form.Group as={Row}>
																	<Col sm={{ span: 10 }}>
																		<Button type="submit">Submit</Button>
																	</Col>
																</Form.Group>
															</Form>
														</Modal.Body>
														<Modal.Footer>
															<Button variant="secondary" className="white-btn"  onClick={this.handleAddImageClose}>
																Close
															</Button>
														</Modal.Footer>
													</Modal>
													<Modal show={this.state.showAddDocument} size='lg' onHide={this.handleAddDocumentClose}>
														<Modal.Header closeButton>
															<Modal.Title>Add a Record Document</Modal.Title>
														</Modal.Header>
														<Modal.Body>
															<Form 
																className="formCard addRecordDocumentForm" 
																onSubmit={this.addRecordDocument}>
																<div className="new_record_title">New Record Document</div>
																<Form.Control 
																	type="hidden" 
																	name='recordId' 
																	value={this.state.recordId} 
																	required/>
																<Form.Group as={Row} controlId="formHorizontalRecordDocument">
																	<Col md={4}>
																		<Form.Label className="formCardTitle">Document: </Form.Label>
																	</Col>
																	<Col md={6}>
																		<Form.Control 
																			type="file" 
																			// placeholder="name" 
																			className='recordDocument'
																			name='recordDocument' 
																			// onChange={this._handleInput} 
																			onChange={(e) => this.setSelectedDocument(e)}
																			/>
																	</Col>
																</Form.Group>
																{alertDisplay}
																<Form.Group as={Row}>
																	<Col sm={{ span: 10 }}>
																		<Button type="submit">Submit</Button>
																	</Col>
																</Form.Group>
															</Form>
														</Modal.Body>
														<Modal.Footer>
															<Button variant="secondary" className="white-btn"  onClick={this.handleAddDocumentClose}>
																Close
															</Button>
														</Modal.Footer>
													</Modal>
													<Row>
														<Col md={6}>
															{recordCostsDisplay}
														</Col>
													</Row>
													
											</div>
											{/* {this.state.record_detail.recordNotes.map(note => 
												<div className="record_notes_container" key={note.noteId}>
													<Form.Group as={Row} controlId="formHorizontalDetail">
														<Col md={1}>
															<Form.Label className="formCardTitle">Note: </Form.Label>
														</Col>
														<Col md={6}>
															<Form.Control 
																className="record_detail_description"
																as="textarea" 
																rows="5"
																placeholder="detail" 
																name='detail' 
																value={note.note}
																onChange={this._handleInput} 
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row}>
													<Col sm={{ span: 10 }}>
														<Button type="submit">Update</Button>
													</Col>
												</Form.Group>
										 	</div>
										)} */}
									
                                    	</Form>
										
										<Row>
											<Col md={8}>
												<div>
													{imageDisplay}
												</div>
											</Col>
										</Row>
										
										<Row>
											<Col md={8}>
												<div>
													{documentDisplay}
												</div>
											</Col>
										</Row>
										{recordLinksDisplay}
                                    </div>
                                </div>;
		} else {
			recordDetailDisplay = <div></div>;
		}

		const localizer = momentLocalizer(moment);

		const loadCalendar = this.state.loadCalendar;
        let calendarDisplay;
        
    	if (loadCalendar) {
			calendarDisplay = <div className="record_detail_container">
									
									<Modal show={this.state.loadCalendar} size='lg' dialogClassName="calendar_modal" onHide={this.handleCalendarClose}>
										<Modal.Header closeButton>
											<Modal.Title>Records Calendar</Modal.Title>
										</Modal.Header>
											<Modal.Body>
												<Calendar
													selectable={true}
													defaultDate={moment().toDate()}
													style={{ height: 600,width: '95%' }}
													views={['month', 'day']}
													defaultView='month'
													localizer={localizer}
													events={this.state.cal_events}
													// onSelectEvent={event => this.calRecordSelected(event)}
													onSelectEvent={(e) => this.calRecordSelected(e)}
													// onSelectSlot={(this.calRecordSelected)}
													// onSelectEvent={event => alert(`${event.recordId} ${event.title}`)}

													startAccessor="start"
													endAccessor="end"
												/>
											</Modal.Body>
											<Modal.Footer>
												<Button variant="secondary" className="white-btn"  onClick={this.handleCalendarClose}>
													Close
												</Button>
											</Modal.Footer>
										</Modal>
									</div>;
		} else {
			calendarDisplay = <div></div>
		}			
        
		return (
			<div className="parent-container parent_container_category Fade">
                <Container className="records_top">
                    <Row>
                        <Col md={4}>
                           	{recordsDisplay}
                        </Col>
                        <Col md={8}>
							{calendarDisplay}
							{loaderDisplay}
                            {recordDetailDisplay}
                        </Col>
                    </Row>
					{/* <Row>
						<Col>
							<Calendar
								defaultDate={moment().toDate()}
								style={{ height: 500,width: '95%' }}
								views={['month', 'week', 'day']}
								defaultView='month'
								localizer={localizer}
								events={this.state.cal_events}
								startAccessor="start"
								endAccessor="end"
							/>
						</Col>
					</Row> */}
                </Container>
			 </div>
		);
  	}
}

export default Category;