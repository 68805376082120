import axios from "axios";

axios.interceptors.request.use(function (config) {

	// spinning start to show
	// UPDATE: Add this code to show global loading indicator
	// document.body.classList.add('loading-indicator');
	// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
	// const token = window.localStorage.token;
	const token = sessionStorage.getItem('token')
	if (token) {
	   config.headers.Authorization = `Bearer ${token}`
	}
	return config
}, function (error) {
	return Promise.reject(error);
});
  
axios.interceptors.response.use(function (response) {
	// console.log('we got a response');
	// spinning hide
	// UPDATE: Add this code to hide global loading indicator
	// document.body.classList.remove('loading-indicator');
  
	return response;
}, function (error) {
	// document.body.classList.remove('loading-indicator');
	return Promise.reject(error);
});