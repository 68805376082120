import React, { Component } from 'react';
import './css/Home.css';
import {Container, Col, Row, Form, Button, Modal, Alert } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';
import dateFormat from 'dateformat';
import ImageGallery from 'react-image-gallery';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faFileDownload, faTimesCircle, faFileWord, faFile, faFileImage, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';


import moment from 'moment'

// import { withRouter } from 'react-router-dom';
/* global google */

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error                : null,
			isLoaded             : false,
			categories           : [],
			loadProperty         : false,
			loadUtilities        : false,
			loadAppliances       : false,
			loadContacts         : false,
			propertyId           : '', 
			propertyDescription  : '', 
			propertyPhone        : '', 
			propertyAddress1     : '', 
			propertyAddress2     : '', 
			propertyCity         : '', 
			propertyZipCode      : '', 
			propertyState        : '', 
			propertyPurchaseDate : '', 
			propertyYearBuilt    : '', 
			propertyRoomCt       : '', 
			propertyBathCt       : '',
			showPropertyEdit     : false,
			showApplianceEdit    : false,
			showUtilityEdit      : false,
			showContactEdit      : false,
			propertyImage        : '',
			propertyImages       : [], 
			userId               : '',
			propertyImagePath    : '',
			loadImage            : false,
			imgFormFile             : '',
			imgFileName             : '',
			docFormFile             : '',
			docFileName             : '',
			propertyDefault      : false,
			propertyHOA          : '',
			propertyRealEstateAgent : '',
			propertyHomeInsurance : '',
			propertyArchitect     : '',
			propertyContractor    : '',
			propertyFootage       : '',
			propertyRoof          : '',
			propertyACUnit        : '',
			propertyWaterHeater   : '',
			propertyAppliances      : [],
			propertyContacts        : [],
			propertyDocuments       : [],
			propertyUtilities       : [],
			utilityNames            : [],
			utilities               : [],
			appliances              : [],
			showApplianceAdd        : false,
			showContactAdd          : false,
			showUtilityAdd          : false,
			propertyContactTypes    : [],
			propertyUtilityTypes    : [],
			applianceId             : '',
			appliancePurchaseDate   : '',
			applianceDescription    : '',
			applianceNotes          : '',
			applianceReceiptURL     : '',
			applianceWarrantyURL    : '', 
			utilityTypeDescription  : 'Cable/Internet',
			contactTypeDescription  : 'Financing Agent',
			contactShowHighlight    : true,
			showImageAlert          : false,
			message         : "",
			imageGallery    : [],
			contactName     : '',
			contactCompany  : '',
			contactTypeId   : '',
			contactPhone    : '',
			contactAddress1 : '',
			contactAddress2 : '',			
			contactCity     : '',
			contactState    : '',
			contactZipCode  : '',
			contactEmail    : '',
			contactWebsite  : '',
			contactId       : '',
			utilityTypeId   : '',
			utilityProviderId : '',
			utilityName       : '',
			utilityPhone      : '',
			utilityAddress1   : '',
			utilityAddress2   : '',
			utilityCity       : '',
			utilityState      : '',
			utilityZipCode    : '',
			utilityEmail      : '',
			utilityWebAddress : '',
			showLoader        : false,
			allImages         : [],
			allApplianceImages : [],
			loadApplianceImage : false,
			applianceImageGallery : [],
			showApplianceImageAlert : false,
			applianceImagePath : '',
			applianceImage     : '',
			loadDocument       : false,
			docsDisplay        : [],
			applianceDocument  : '' ,
			showDocAlert       : false
		};

		this._handleInput         = this._handleInput.bind(this);
		this._handleInputUtility  = this._handleInputUtility.bind(this);
		this._handleInputContact  = this._handleInputContact.bind(this);
		// this._handleInputAppliance         = this._handleInputAppliance.bind(this);
		this.updateProperty       = this.updateProperty.bind(this);
		this.updateApplianceData  = this.updateApplianceData.bind(this);
		this.updateUtilityData    = this.updateUtilityData.bind(this);
		this.handleShow           = this.handleShow.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.loadInitial          = this.loadInitial.bind(this);
		this.postPropertyImage    = this.postPropertyImage.bind(this);
		this.postApplianceImage   = this.postApplianceImage.bind(this);
		this.setSelectedImage     = this.setSelectedImage.bind(this);
		this.setSelectedApplianceImage     = this.setSelectedApplianceImage.bind(this);
		// this.getPropertiesByUser  = this.getPropertiesByUser.bind(this);
		this.getPropertiesDisplay    = this.getPropertiesDisplay.bind(this);
		this.getUserData             = this.getUserData.bind(this);
		// this.getContractorsData   = this.getContractorsData.bind(this);
		this.getCurrentProperty      = this.getCurrentProperty.bind(this);
		this.handleShowUtility       = this.handleShowUtility.bind(this);
		this.handleCloseUtility      = this.handleCloseUtility.bind(this);
		this.handleShowAppliance     = this.handleShowAppliance.bind(this);
		this.handleShowAddAppliance  = this.handleShowAddAppliance.bind(this);
		this.handleCloseAppliance    = this.handleCloseAppliance.bind(this);
		this.handleCloseAddAppliance = this.handleCloseAddAppliance.bind(this);
		this.handleShowContact     = this.handleShowContact.bind(this);
		this.handleCloseContact    = this.handleCloseContact.bind(this);
		this.postApplianceData     = this.postApplianceData.bind(this);
		this.handleShowAddContact  = this.handleShowAddContact.bind(this);
		this.handleCloseAddContact = this.handleCloseAddContact.bind(this);
		this.handleShowAddUtility  = this.handleShowAddUtility.bind(this);
		this.handleCloseAddUtility = this.handleCloseAddUtility.bind(this);
		this.getFirstProperty      = this.getFirstProperty.bind(this);
		this.getContactData        = this.getContactData.bind(this);
		this.showContactDetail     = this.showContactDetail.bind(this);
		this.getContactTypes       = this.getContactTypes.bind(this);
		this.postContactData       = this.postContactData.bind(this);
		this.updateContactData     = this.updateContactData.bind(this);
		this.getUtilityTypes       = this.getUtilityTypes.bind(this);
		this.showUtilityDetail     = this.showUtilityDetail.bind(this);
		this.postUtilityData       = this.postUtilityData.bind(this);
		this.showApplianceDetail   = this.showApplianceDetail.bind(this);
		this.deleteContact         = this.deleteContact.bind(this);
		this.deleteAppliance       = this.deleteAppliance.bind(this);
		this.deleteUtility         = this.deleteUtility.bind(this);
		this.initGooglePlaces      = this.initGooglePlaces.bind(this);
		this.initGooglePlacesContact = this.initGooglePlacesContact.bind(this);
		this.initGooglePlacesUtility = this.initGooglePlacesUtility.bind(this);
		this.handlePlaceSelect     = this.handlePlaceSelect.bind(this);
		this.autocomplete          = null;
		this.autocompleteContact   = null;
		this.autocompleteUtility   = null;
		this.autocompleteInput     = React.createRef();
		this.autocompleteInputUtility = React.createRef();
		this.autocompleteInputContact = React.createRef();
		this._handleCheckbox          = this._handleCheckbox.bind(this);
		this.updateContactHighlight   = this.updateContactHighlight.bind(this);
		this.setBottomRowHeights      = this.setBottomRowHeights.bind(this);
		this.getBottomRowHeights      = this.getBottomRowHeights.bind(this);
		this.handlePlaceSelectUtility = this.handlePlaceSelectUtility.bind(this);
		this.handlePlaceSelectContact = this.handlePlaceSelectContact.bind(this);
		this.deleteImage              = this.deleteImage.bind(this);
		this.renderImageDelete        = this.renderImageDelete.bind(this);
		this.deleteApplianceImage     = this.deleteApplianceImage.bind(this);
		this.renderApplianceImageDelete = this.renderApplianceImageDelete.bind(this);
		this.deleteDocument             = this.deleteDocument.bind(this);
		this.setSelectedDocument        = this.setSelectedDocument.bind(this);
		this.refImgHome                 = React.createRef();
		this.refImgApplianceHome        = React.createRef();
		this.addApplianceDocument       = this.addApplianceDocument.bind(this);
	}

	handleShow() {
		// console.log('handleshow')
		this.setState({ 
			showPropertyEdit : true, 
			// showLoader       : true
		});
	}
	
    handleClose() {
		this.setState({ 
			showPropertyEdit : false ,
			// showLoader       : false
		});
	} 

	handleShowUtility() {
		this.setState({ showUtilityEdit : true });
	}
	
    handleCloseUtility() {
		this.setState({ 
			showUtilityEdit : false,
			utilityProviderId : '',
			utilityTypeDescription : '',
			utilityName : '',
			utilityPhone : '',
			utilityAddress1 : '',
			utilityAddress2 : '',
			utilityCity : '',
			utilityState : '',
			utilityZipCode : '',
			utilityEmail : '',
			utilityWebAddress : ''
		});
	} 

	handleShowAddUtility() {
		this.setState({ 
			showUtilityAdd  : true,
			showUtilityEdit : false,
		});
	}
	
    handleCloseAddUtility() {
		this.setState({ 
			showUtilityAdd : false,
			utilityProviderId : '',
			utilityTypeDescription : '',
			utilityName : '',
			utilityPhone : '',
			utilityAddress1 : '',
			utilityAddress2 : '',
			utilityCity : '',
			utilityState : '',
			utilityZipCode : '',
			utilityEmail : '',
			utilityWebAddress : ''
		 });
	}

	handleShowAppliance() {
		this.setState({ 
			showApplianceEdit : true,
			showApplianceAdd  : false 
		});
	}
	
    handleCloseAppliance() {
		this.setState({ 
			showApplianceEdit : false,
			applianceId : '',
			appliancePurchaseDate: '',
			applianceDescription : '',
			applianceNotes : '',
			applianceReceiptURL : '',
			applianceWarrantyURL : ''
		});
	} 

	handleShowAddAppliance() {
		this.setState({ 
			showApplianceAdd  : true,
			showApplianceEdit : false
		});
	}
	
    handleCloseAddAppliance() {
		this.setState({ 
			showApplianceAdd : false ,
			applianceId : '',
			appliancePurchaseDate: '',
			applianceDescription : '',
			applianceNotes : '',
			applianceReceiptURL : '',
			applianceWarrantyURL : ''
		});
	} 

	handleShowContact() {
		this.setState({ 
			showContactEdit : true,
			showContactAdd  : false
		});
	}
	
    handleCloseContact() {
		this.setState({ 
			showContactEdit : false,
			contactId : '',
			contactTypeId : '',
			contactTypeDescription : '',
			contactName : '',
			contactCompany : '',
			contactPhone : '',
			contactAddress1 : '',
			contactAddress2 : '',
			contactCity : '',
			contactState : '',
			contactZipCode : '',
			contactEmail : '',
			contactWebsite: ''
		});
	} 

	handleShowAddContact() {
		this.setState({ 
			showContactAdd  : true,
			showContactEdit : false
		});
	}
	
    handleCloseAddContact() {
		this.setState({ showContactAdd : false });
	}

	componentDidMount() {
		setTimeout(
			function() {
				this.loadInitial();
			}
			.bind(this),
			500
		);
	}
	
	loadInitial(){

		axios.defaults.headers.common = {
			'Authorization': 'Bearer ' + sessionStorage.getItem('token')
		};

		const userId = parseInt(this.props.match.params.userId);
		// console.log('the user id : ', userId);
		sessionStorage.setItem("logged_in", 'true');
		this.setState({ userId : userId });
		this.getFirstProperty();
	}

	initGooglePlaces(){
		// console.log('this is happening initGooglePlaces');

		this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
			{"types": ["geocode"]});
	
		this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
	}

	handlePlaceSelect() {
		let addressObject = this.autocomplete.getPlace()
		// console.log('the address: ', addressObject)
		let address = addressObject.address_components;

		var addressDisplay = `${address[0].long_name} ${address[1].long_name}`;
		var city    = address[2].long_name;
		var state   = address[4].short_name;
		var zipCode = address[6].short_name;

		if (addressDisplay.length === 0){
			addressDisplay = '';
		}
		if (city.length === 0){
			city = '';
		}
		if (state.length === 0){
			state = '';
		}
		if (zipCode.length === 0){
			zipCode = '';
		}

		this.setState({
			propertyAddress1 : addressDisplay,
			propertyCity     : city,
			propertyState    : state,
			propertyZipCode  : zipCode,
		});
	}

	initGooglePlacesContact(){
		// console.log('this is happening initGooglePlaces');

		this.autocompleteContact = new google.maps.places.Autocomplete(this.autocompleteInputContact.current,
			{"types": ["geocode"]});
	
		this.autocompleteContact.addListener('place_changed', this.handlePlaceSelectContact);
	}

	handlePlaceSelectContact() {
		let addressObject = this.autocompleteContact.getPlace()
		// console.log('the address: ', addressObject)
		let address = addressObject.address_components;

		var addressDisplay = `${address[0].long_name} ${address[1].long_name}`;
		var city    = address[2].long_name;
		var state   = address[4].short_name;
		var zipCode = address[6].short_name;

		if (addressDisplay.length === 0){
			addressDisplay = '';
		}
		if (city.length === 0){
			city = '';
		}
		if (state.length === 0){
			state = '';
		}
		if (zipCode.length === 0){
			zipCode = '';
		}

		this.setState({
			contactAddress1 : addressDisplay,
			contactCity     : city,
			contactState    : state,
			contactZipCode  : zipCode,
		});
	}

	initGooglePlacesUtility(){
		// console.log('this is happening initGooglePlaces');

		this.autocompleteUtility = new google.maps.places.Autocomplete(this.autocompleteInputUtility.current,
			{"types": ["geocode"]});
	
		this.autocompleteUtility.addListener('place_changed', this.handlePlaceSelectUtility);
	}

	handlePlaceSelectUtility() {
		let addressObject = this.autocompleteUtility.getPlace()
		// console.log('the address: ', addressObject)
		let address = addressObject.address_components;

		var addressDisplay = `${address[0].long_name} ${address[1].long_name}`;
		var city    = address[2].long_name;
		var state   = address[4].short_name;
		var zipCode = address[6].short_name;

		if (addressDisplay.length === 0){
			addressDisplay = '';
		}
		if (city.length === 0){
			city = '';
		}
		if (state.length === 0){
			state = '';
		}
		if (zipCode.length === 0){
			zipCode = '';
		}

		this.setState({
			utilityAddress1 : addressDisplay,
			utilityCity     : city,
			utilityState    : state,
			utilityZipCode  : zipCode,
		});
	}

	getFirstProperty(){
		this.setState({ 
			showLoader : true,
		});
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Properties/user/" + this.state.userId)
			.then(res => {
				const properties_data = res.data.result;
				// console.log('user result ', user_data);
				properties_data.forEach(property => {
					// console.log('single property: ', property);
					if (property.is_Default){
						var defaultId = property.id;
						// console.log('defaultId: ', defaultId);
						if (sessionStorage.getItem('currentActiveProperty') === null){
							sessionStorage.setItem('currentActiveProperty', defaultId);
						}
					}
				});
				
				this.setState({ 
					properties : properties_data
				});

				this.getPropertiesDisplay(this.state.userId);
			});	
		} catch(ex){
			console.log('getFirstProperty exception : ', ex);
		}
	}

	getPropertiesDisplay(userId){
		try{
			this.getCurrentProperty();
			this.getUserData(userId);
			// this.getContractorsData();
		} catch(ex){
			console.log('getPropertiesDisplay exception : ', ex);
		}
	}
	getUserData(userId){
		// console.log('another user id : ', userId)
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Users/" + userId)
			.then(res => {
				const user_data = res.data.result;
				this.setState({ user_data });
			});
		} catch(ex){
			console.log('getUserData exception : ', ex);
		}
	}

	getContactData(){
		
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Contacts/prop?propId=" + this.state.propertyId)
			.then(res => {
				const contact_data = res.data.result;
				// console.log('contact_data ', contact_data);
				this.getContactTypes();
				this.setState({ 
					propertyContacts : contact_data,
					showLoader : false 
				});
			});
		} catch(ex){
			console.log('getContactData exception : ', ex);
		}
	}

	showContactDetail(e, contactId){
		e.preventDefault();
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Contacts/" + contactId)
			.then(res => {
				const contact_detail = res.data.result;
				// console.log('contact_detail ', contact_detail);
				this.handleShowContact();
				this.setState({ 
					contactId              : contact_detail.contactId,
					contactTypeId          : contact_detail.contactTypeId,
					contactTypeDescription : contact_detail.contactTypeDesc,
					contactName            : contact_detail.name,
					contactCompany         : contact_detail.company,
					contactPhone           : contact_detail.phone,
					contactAddress1        : contact_detail.address1,
					contactAddress2        : contact_detail.address2,
					contactCity            : contact_detail.city,
					contactState           : contact_detail.state,
					contactZipCode         : contact_detail.zipCode,
					contactEmail           : contact_detail.email,
					contactWebsite         : contact_detail.website,
					contactShowHighlight   : contact_detail.showHighlight
				});
			});
		} catch(ex){
			console.log('showContactDetail exception : ', ex);
		}
	}

	deleteContact(e, contactId){
		e.preventDefault();
		var confirm = window.confirm('Are you sure you want to delete this contact?')
		if (confirm){
			this.setState({ 
				showLoader : true,
			});
			try{
				axios.delete(process.env.REACT_APP_BASE_URL+"Contacts/" + contactId +"?propid="+this.state.propertyId)
				.then(res => {
					// const contact_detail = res.data.result;
					this.handleCloseContact();
					this.getContactData();
				});
			} catch(ex){
				console.log('deleteContact exception : ', ex);
			}
		}
	}

	getContactTypes(){
		
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Contacts/types")
			.then(res => {
				const contact_types = res.data.result;
				// console.log('contact_types ', contact_types);
				this.setState({ propertyContactTypes : contact_types });
			});
		} catch(ex){
			console.log('getContactTypes exception : ', ex);
		}
	}

	postContactData(e){
		e.preventDefault();
		try{
			axios.post(process.env.REACT_APP_BASE_URL+"Contacts/",{
			
				propertyId             : this.state.propertyId,
				contactTypeId          : this.state.contactTypeId,
				contactTypeDescription : this.state.contactTypeDescription,
				name                   : this.state.contactName,
				company                : this.state.contactCompany,
				phone                  : this.state.contactPhone,
				address1               : this.state.contactAddress1,
				address2               : this.state.contactAddress2,
				city                   : this.state.contactCity,
				state                  : this.state.contactState,
				zipCode                : this.state.contactZipCode,
				email                  : this.state.contactEmail,
				website                : this.state.contactWebsite
			})
			.then(res => {
				this.handleCloseAddContact();
				this.getContactData();
				// this.getBottomRowHeights();
				// console.log('hey',this.state)
			});
		} catch(ex){
			console.log('postContactData exception : ', ex);
		}
	}

	updateContactData(e){
		e.preventDefault();
		try{
			axios.put(process.env.REACT_APP_BASE_URL+"Contacts/" + this.state.contactId,{
			
				contactId              : this.state.contactId,
				contactTypeId          : this.state.contactTypeId,
				contactTypeDescription : this.state.contactTypeDescription,
				name                   : this.state.contactName,
				company                : this.state.contactCompany,
				phone                  : this.state.contactPhone,
				address1               : this.state.contactAddress1,
				address2               : this.state.contactAddress2,
				city                   : this.state.contactCity,
				state                  : this.state.contactState,
				zipCode                : this.state.contactZipCode,
				email                  : this.state.contactEmail,
				website                : this.state.contactWebsite,
				showContactEdit        : false
			})
			.then(res => {
				this.handleCloseContact();
				this.getContactData();
			});
		} catch(ex){
			console.log('updateContactData exception : ', ex);
		}
	}

	// getContractorsData(){
	// 	try{
	// 		axios.get(process.env.REACT_APP_BASE_URL+"Contractors/")
	// 		.then(res => {
	// 			const contractor_data = res.data.result;
	// 			// console.log('user result ', user_data);
	// 			if (contractor_data.length > 0) {
	// 				this.setState({ 
	// 					contractor_data,
	// 					// loadUtilities : true
	// 				});
	// 			}
	// 			// console.log('hey',this.state)
	// 		});	
	// 	} catch(ex){
	// 		console.log('getContractorsData exception : ', ex);
	// 	}
	// }

	setSelectedApplianceImage(e){
		// console.log('file', file);
		// console.log('file', file.name);
		// let name  = e.target.name;
		let value = e.target.value;

		this.setState({ 
			applianceImage : value,
			imgFormFile       : e.target.files[0],
			imgFileName       : e.target.files[0].name,
			showImageAlert : false,
		});
	}

	setSelectedImage(e){
		// console.log('file', file);
		// console.log('file', file.name);
		// let name  = e.target.name;
		let value = e.target.value;

		this.setState({ 
			propertyImage  : value,
			imgFormFile       : e.target.files[0],
			imgFileName       : e.target.files[0].name,
			showImageAlert : false,
		});
	}

	setSelectedDocument(e){
		// console.log('file', file);
		// console.log('file', file.name);
		// let name  = e.target.name;
		let value = e.target.value;

		this.setState({ 
			applianceDocument : value,
			docFormFile          : e.target.files[0],
			docFileName          : e.target.files[0].name,
			showDocAlert      : false,
		});
	}

	postPropertyImage(e) {
		this.setState({ 
			showLoader : true,
		});
		e.preventDefault();
		// console.log(this.state.formFile);
		// console.log(this.state.fileName);

		const formData = new FormData();

		var file = this.state.imgFormFile;
		// console.log('file size: ', file);

		var upload = true;

		if (file.size > 15000000){			
			upload = false;
			this.setState({ 
				showLoader : false,
				showImageAlert : true,
				message : 'This file is too large. Please choose a smaller file.'
			});
		}

		if (!file.name.match(/.(jpg|jpeg|png|gif)$/i)){
			upload = false;
			this.setState({ 
				showLoader: false,
				showImageAlert : true,
				message : 'Please choose an image file.'
			});
		}

		if (upload){
			formData.append('formFile', this.state.imgFormFile);
			formData.append('fileName', this.state.imgFileName);
			var propertyId = this.state.propertyId;
	
			try{
				axios.post(process.env.REACT_APP_BASE_URL+'properties/image/'+propertyId, formData, {
					headers: {
					  'Content-Type': 'multipart/form-data'
					}
				})
				.then(res => {
					// console.log('image result ', res);
					const image_response = res.data.result;
					// console.log("image response : ", image_response)
					if (image_response) {
						this.setState({ 
							propertyImagePath : image_response.path,
							showPropertyEdit  : false,
						});
						// console.log('property image path: ', this.state.propertyImagePath)
					}
					this.getCurrentProperty();
				});
			} catch(ex){
				console.log('savefile exception : ', ex);
			}
		}
	};

	addApplianceDocument(e){
		this.setState({ 
			showLoader : true,
		});
		e.preventDefault();

		var applianceId = this.state.applianceId;
		// console.log(this.state.formFile);
		// console.log(this.state.fileName);

		var file = this.state.docFormFile;
		// console.log('file size: ', file);

		var upload = true;

		if (file.size > 15000000){			
			upload = false;
			this.setState({ 
				showDocAlert : true,
				message      : 'This file is too large. Please choose a smaller file.'
			});
		}

		if (!file.name.match(/.(odt|xls|xlsx|ods|ppt|pptx|xlsm|rtf|tex|wpd|txt|pdf|jpg|jpeg|png|gif|doc|docx)$/i)){
			upload = false;
			
			// console.log('not file type');
			this.setState({ 
				showDocAlert : true,
				message      : 'Not an accepted file type. Please choose a different file.'
			});
		}

		if (upload){

			const formData = new FormData();

			formData.append('formFile', this.state.docFormFile);
			formData.append('fileName', this.state.docFileName);
		
			try{
				axios.post(process.env.REACT_APP_BASE_URL+'Appliances/Document/'+applianceId, formData, {
					headers: {
					'Content-Type': 'multipart/form-data'
					}
				})
				.then((result) => {

					// console.log('document result ', result);
					const document_response = result.data.result;
					// console.log("document_response : ", document_response)
					if (document_response) {
						this.setState({ 
							applianceDocumentPath : document_response.path 
						});
						// console.log('record document path: ', this.state.recordDocumentPath)
					}
					this.getCurrentProperty();
					
				});
			} catch(ex){
				console.log('addApplianceDocument exception : ', ex);
			}
		}
	}

	postApplianceImage(e) {
		this.setState({ 
			showLoader : true,
		});
		e.preventDefault();
		// console.log(this.state.formFile);
		// console.log(this.state.fileName);

		const formData = new FormData();

		var file = this.state.imgFormFile;
		// console.log('file size: ', file);

		var upload = true;

		if (file.size > 15000000){			
			upload = false;
			this.setState({ 
				showLoader : false,
				showApplianceImageAlert : true,
				message : 'This file is too large. Please choose a smaller file.'
			});
		}

		if (!file.name.match(/.(jpg|jpeg|png|gif)$/i)){
			upload = false;
			this.setState({ 
				showLoader: false,
				showApplianceImageAlert : true,
				message : 'Please choose an image file.'
			});
		}

		if (upload){
			formData.append('formFile', this.state.imgFormFile);
			formData.append('fileName', this.state.imgFileName);
			var applianceId = this.state.applianceId;
	
			try{
				axios.post(process.env.REACT_APP_BASE_URL+'Appliances/Image/'+applianceId, formData, {
					headers: {
					  'Content-Type': 'multipart/form-data'
					}
				})
				.then(res => {
					// console.log('image result ', res);
					const image_response = res.data.result;
					// console.log("image response : ", image_response)
					if (image_response) {
						this.setState({ 
							appliancesImagePath : image_response.path,
							showApplianceEdit  : false,
						});
						// console.log('property image path: ', this.state.propertyImagePath)
					}
					this.getApplianceData();
				});
			} catch(ex){
				console.log('savefile appliance exception : ', ex);
			}
		}
	};

	deleteImage(e, index){
		if (e){
			e.preventDefault();
		}
		
		var imgId = this.state.allImages[index].imageId;
		// console.log('this image : ', imgId);
		// console.log('the index : ', index);
		var confirm = window.confirm('Are you sure you want to delete this image?')
		if (confirm){
			this.setState({ 
				showLoader : true,
			});
			try{
				// console.log('delete image ');
				axios.delete(process.env.REACT_APP_BASE_URL+"Images/"+imgId)
				.then((result) => {
	
					// console.log('document result ', result);
					const image_response = result.data.result;
					// console.log("image_response delete : ", image_response)
			
					this.getCurrentProperty();
				});
			} catch(ex){
				console.log('deleteImage exception : ', ex);
			}
		}
	}

	deleteApplianceImage (e, index){
		if (e){
			e.preventDefault();
		}
		
		var imgId = this.state.allApplianceImages[index].imageId;
		// console.log('this image : ', imgId);
		// console.log('the index : ', index);
		var confirm = window.confirm('Are you sure you want to delete this image?')
		if (confirm){
			this.setState({ 
				showLoader : true,
			});
			try{
				// console.log('delete appliance image ');
				axios.delete(process.env.REACT_APP_BASE_URL+"Images/"+imgId)
				.then((result) => {
	
					// console.log('document result ', result);
					const image_response = result.data.result;
					// console.log("appliance_image_response delete : ", image_response)
					this.setState({ 
						showApplianceEdit  : false,
					});
					this.getApplianceData();
				});
			} catch(ex){
				console.log('deleteApplianceImage exception : ', ex);
			}
		}
	}

	deleteDocument(e, docId){
		
		if (e){
			e.preventDefault();
		}
		var confirm = window.confirm('Are you sure you want to delete this document?')
		if (confirm){
			this.setState({ 
				showLoader : true,
			});
			try{
				// console.log('document delete ');
				axios.delete(process.env.REACT_APP_BASE_URL+"Documents/"+docId)
				.then((result) => {

					// console.log('document result ', result);
					const document_response = result.data.result;
					// console.log("document_response delete : ", document_response)
					this.handleCloseAppliance();
					// this.showDetail(this.state.recordId)
				});
			} catch(ex){
				console.log('deleteDocument exception : ', ex);
			}
		}
	}

	renderImageDelete = props => {
		return	<FontAwesomeIcon className="red-btn icon img_delete_home" onClick={(e) => { this.deleteImage(e, this.refImgHome.getCurrentIndex()) }} icon={ faTimesCircle }/>;
	};

	renderApplianceImageDelete = props => {
		return	<FontAwesomeIcon className="red-btn icon img_delete_app" onClick={(e) => { this.deleteApplianceImage(e, this.refImgApplianceHome.getCurrentIndex()) }} icon={ faTimesCircle }/>;
	};

	getUtilityData(){
		
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Utilities/prop?propId=" + this.state.propertyId)
			.then(res => {
				const utililty_data = res.data.result;
				// console.log('utililty_data ', utililty_data);
				this.getUtilityTypes();
				this.setState({ 
					propertyUtilities : utililty_data,
					showLoader        : false 
				});
				// this.getBottomRowHeights();
				// console.log('hey',this.state)
			});
		} catch(ex){
			console.log('getUtilityData exception : ', ex);
		}
	}

	showUtilityDetail(e, utilityId){
		e.preventDefault();
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Utilities/" + utilityId)
			.then(res => {
				const utility_detail = res.data.result;
				// console.log('utility_detail ', utility_detail);
				this.handleShowUtility();
				this.setState({ 
					utilityProviderId      : utility_detail.utilityProviderId,
					utilityTypeId          : utility_detail.utilityTypeId,
					utilityTypeDescription : utility_detail.utilityTypeDesc,
					utilityName            : utility_detail.name,
					utilityPhone           : utility_detail.phone,
					utilityAddress1        : utility_detail.address1,
					utilityAddress2        : utility_detail.address2,
					utilityCity            : utility_detail.city,
					utilityState           : utility_detail.state,
					utilityZipCode         : utility_detail.zipCode,
					utilityEmail           : utility_detail.email,
					utilityWebAddress      : utility_detail.webAddress
				});				
			});
		} catch(ex){
			console.log('showUtilityDetail exception : ', ex);
		}
	}

	deleteUtility(e, utilityId){
		e.preventDefault();
		var confirm = window.confirm('Are you sure you want to delete this utility?')
		if (confirm){
			this.setState({ 
				showLoader : true,
			});
			try{
				axios.delete(process.env.REACT_APP_BASE_URL+"Utilities/" + utilityId+"?propid="+this.state.propertyId)
				.then(res => {
					// const contact_detail = res.data.result;
					this.handleCloseUtility();
					this.getUtilityData();
				});
			} catch(ex){
				console.log('deleteUtility exception : ', ex);
			}
		}
	}

	getUtilityTypes(){
		
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Utilities/types")
			.then(res => {
				const utility_types = res.data.result;
				// console.log('utility_types ', utility_types);
			
				this.setState({ propertyUtilityTypes : utility_types });
			});
		} catch(ex){
			console.log('getUtilityTypes exception : ', ex);
		}
	}

	postUtilityData(e){
		e.preventDefault();
		// console.log('state type: ', this.state.utilityTypeDescription);
		try{
			axios.post(process.env.REACT_APP_BASE_URL+"Utilities/",{
			
				propertyId             : this.state.propertyId,
				utilityTypeId          : this.state.utilityTypeId,
				utilityTypeDescription : this.state.utilityTypeDescription,
				name                   : this.state.utilityName,
				phone                  : this.state.utilityPhone,
				address1               : this.state.utilityAddress1,
				address2               : this.state.utilityAddress2,
				city                   : this.state.utilityCity,
				state                  : this.state.utilityState,
				zipCode                : this.state.utilityZipCode,
				email                  : this.state.utilityEmail,
				webAddress             : this.state.utilityWebAddress
			})
			.then(res => {
				this.getUtilityData();
				this.handleCloseAddUtility();
				// this.getBottomRowHeights();
			});
		} catch(ex){
			console.log('postUtilityData exception : ', ex);
		}
	}

	updateUtilityData(e){
		e.preventDefault();
		// console.log('state type: ', this.state.utilityTypeDescription);
		try{
			axios.put(process.env.REACT_APP_BASE_URL+"Utilities/"+ this.state.utilityProviderId,{
			
				propertyId             : this.state.propertyId,
				utilityProviderId      : this.state.utilityProviderId,
				utilityTypeId          : this.state.utilityTypeId,
				utilityTypeDescription : this.state.utilityTypeDescription,
				name                   : this.state.utilityName,
				phone                  : this.state.utilityPhone,
				address1               : this.state.utilityAddress1,
				address2               : this.state.utilityAddress2,
				city                   : this.state.utilityCity,
				state                  : this.state.utilityState,
				zipCode                : this.state.utilityZipCode,
				email                  : this.state.utilityEmail,
				webAddress             : this.state.utilityWebAddress
			})
			.then(res => {
				this.handleCloseUtility();
				this.getUtilityData();
			});
		} catch(ex){
			console.log('updateUtilityData exception : ', ex);
		}
	}

	getApplianceData(){
		
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Appliances/prop?propId=" + this.state.propertyId)
			.then(res => {
				const appliance_data = res.data.result;
				// console.log('appliance_data ', appliance_data);
			
				this.setState({ 
					propertyAppliances : appliance_data,
					showLoader         : false
				});
				// this.getBottomRowHeights();
			});
		} catch(ex){
			console.log('getApplianceData exception : ', ex);
		}
	}

	showApplianceDetail(e, applianceId){
		e.preventDefault();
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Appliances/" + applianceId)
			.then(res => {
				const appliance_detail = res.data.result;

				if (appliance_detail.applianceImages.length > 0){
					var images     = appliance_detail.applianceImages;
					var the_images = images.map(image => ({'original': image.urL_Full, 'thumbnail': image.urL_Thumb ? image.urL_Thumb  : '', originalAlt: 'image_'+image.imageId, thumbnailAlt: 'image_'+image.imageId }));
					this.setState({ 
						applianceImageGallery : the_images,
						loadApplianceImage    : true,
						allApplianceImages    : images
					})
				} else {
					this.setState({ 
						applianceImageGallery : '',
						loadApplianceImage    : false
					})
				}
				// console.log('appliance_detail ', appliance_detail);
				this.handleShowAppliance();
				// var purchase_date = moment(appliance_detail.purchaseDate).format('YYYY-MM-DD');
				// console.log('purchase date : ', purchase_date)

				if (appliance_detail.applianceDocuments.length > 0){
					var docs = appliance_detail.applianceDocuments;
					
					var docs_array = [];
					var icon       = '';
					for(var i = 0; i < docs.length; i++){
						if (docs[i].urL_Full.match(/.(jpg|jpeg|png|gif)$/i)){
							icon = 'image';
						} else if (docs[i].urL_Full.match(/.(xls|xlsx|xlsm)$/i)){
							icon = 'excel';
					 	} else if (docs[i].urL_Full.match(/.(doc|docx)$/i)){
							icon = 'word';
						} else if (docs[i].urL_Full.match(/.(pdf)$/i)){
							icon = 'pdf';
						} else {
							icon = 'file';
						}
						docs_array.push({'path': docs[i].urL_Full, 'icon': icon, 'documentId' : docs[i].documentId, description: docs[i].description});
					}
				
					// console.log('the docs: ', docs_array);
					this.setState({ 
						docsDisplay : docs_array,
						loadDocument   : true
					})
				} else {
					this.setState({ 
						docsDisplay     : '',
						loadDocument    : false
					})
				}

				this.setState({ 
					applianceId           : appliance_detail.applianceId,
					appliancePurchaseDate : appliance_detail.purchaseDate,
					applianceDescription  : appliance_detail.description,
					applianceNotes        : appliance_detail.notes,
					applianceReceiptURL   : appliance_detail.receiptUrl,
					applianceWarrantyURL  : appliance_detail.warrantyUrl,
					
				});				
			});
		} catch(ex){
			console.log('showUtilityDetail exception : ', ex);
		}
	}

	deleteAppliance(e, applianceId){
		e.preventDefault();
		var confirm = window.confirm('Are you sure you want to delete this appliance?')
		if (confirm){
			this.setState({ 
				showLoader : true,
			});
			try{
				axios.delete(process.env.REACT_APP_BASE_URL+"Appliances/" + applianceId)
				.then(res => {
					// const contact_detail = res.data.result;
					this.handleCloseAppliance();
					this.getApplianceData();
				});
			} catch(ex){
				console.log('deleteAppliance exception : ', ex);
			}
		}
	}

	postApplianceData(e){
		e.preventDefault();
		try{
			axios.post(process.env.REACT_APP_BASE_URL+"Appliances/",{
			
				propertyId   : this.state.propertyId,
				applianceId  : this.state.applianceId,
				purchaseDate : this.state.appliancePurchaseDate,
				description  : this.state.applianceDescription,
				notes        : this.state.applianceNotes,
				receiptUrl   : this.state.applianceReceiptURL,
				warrantyUrl  : this.state.applianceWarrantyURL,
				// applianceDocument : this.state.applianceDocument,
				// applianceImage : this.state.applianceImage
			})
			.then(res => {
				const appliance_detail = res.data.result;
				var value = $('.applianceAddImage').val();
				// console.log('appliance_detail: ', appliance_detail);
				if (value.length < 3) {
					this.setState({
						applianceImage : '',
						imgFormFile      : '',
						imgFileName      : '',
						showApplianceAdd : false,
					})
				} else if (this.state.applianceImage){
					// console.log(e.target.files[0]);
					// console.log('image: ', propertyImage);
					
					this.setState({ 
						applianceId : appliance_detail.applianceId
					
					}, () => {
						this.postApplianceImage(e)
					})
				}

				var doc_value = $('.applianceDocument').val();
				// console.log('value: ', value);
				if (doc_value.length < 3) {
					this.setState({
						applianceDocument : '',
						docFormFile      : '',
						docFileName      : '',
						showApplianceEdit : false,
					})
				} else if (this.state.applianceDocument){
					// console.log(e.target.files[0]);
					// console.log('image: ', propertyImage);
					this.setState({ 
						applianceId : appliance_detail.applianceId
					
					}, () => {
						this.addApplianceDocument(e)
					})
				}

				this.handleCloseAddAppliance();
				this.getApplianceData();
				// this.getBottomRowHeights();
			});
		} catch(ex){
			console.log('postApplianceData exception : ', ex);
		}
	}

	updateApplianceData(e){
		e.preventDefault();
		try{
			axios.put(process.env.REACT_APP_BASE_URL+"Appliances/"+ this.state.applianceId,{
			
				propertyId   : this.state.propertyId,
				applianceId  : this.state.applianceId,
				purchaseDate : this.state.appliancePurchaseDate,
				description  : this.state.applianceDescription,
				notes        : this.state.applianceNotes,
				receiptUrl   : this.state.applianceReceiptURL,
				warrantyUrl  : this.state.applianceWarrantyURL,
				// applianceDocument : this.state.applianceDocument,
				// applianceImage : this.state.applianceImage
			})
			.then(res => {

				var value = $('.applianceEditImage').val();
				// console.log('value: ', value);
				if (value.length < 3) {
					this.setState({
						applianceImage : '',
						imgFormFile      : '',
						imgFileName      : '',
						showApplianceEdit : false,
					})
				} else if (this.state.applianceImage){
					
					// console.log(e.target.files[0]);
					// console.log('image: ', propertyImage);
					this.postApplianceImage(e)
				}

				var doc_value = $('.applianceDocument').val();
				// console.log('value: ', value);
				if (doc_value.length < 3) {
					this.setState({
						applianceDocument : '',
						docFormFile      : '',
						docFileName      : '',
						showApplianceEdit : false,
					})
				} else if (this.state.applianceDocument){
					// console.log(e.target.files[0]);
					// console.log('image: ', propertyImage);
					this.addApplianceDocument(e)
				}

				this.handleCloseAppliance();
				this.getApplianceData();
			});
		} catch(ex){
			console.log('updateApplianceData exception : ', ex);
		}
	}
	
	updateProperty(e){
		this.setState({ 
			showLoader : true,
		});
		e.preventDefault();
		var propertyId           = this.state.propertyId;
		var userId               = this.state.userId;
		var propertyDescription  = this.state.propertyDescription;
		var propertyPhone        = this.state.propertyPhone;
		var propertyAddress1     = this.state.propertyAddress1; 
		var propertyAddress2     = this.state.propertyAddress2; 
		var propertyCity         = this.state.propertyCity;
		var propertyZipCode      = this.state.propertyZipCode; 
		var propertyState        = this.state.propertyState; 
		var propertyYearBuilt    = this.state.propertyYearBuilt; 
		var propertyPurchaseDate = this.state.propertyPurchaseDate; 
		var propertyRoomCt       = this.state.propertyRoomCt; 
		var propertyBathCt       = this.state.propertyBathCt;
		var propertyImage        = this.state.propertyImage;
		var propertyDefault      = this.state.propertyDefault;
		var propertyHOA          = this.state.propertyHOA;
		var propertyFootage      = this.state.propertyFootage;
		var propertyRoof         = this.state.propertyRoof;
		// var propertyDefault      = this.state.propertyDefault;
		// console.log('roof : ', this.state.propertyRoof);
		// console.log('purchase date : ', this.state.propertyPurchaseDate);
		

		try{
			axios.put(process.env.REACT_APP_BASE_URL+'Properties/' + propertyId, { 
				userId       : userId,
				description  : propertyDescription,
				phoneNumber  : propertyPhone, 
				address1     : propertyAddress1, 
				address2     : propertyAddress2, 
				city         : propertyCity, 
				zipCode      : propertyZipCode, 
				state        : propertyState, 
				yearBuilt    : propertyYearBuilt, 
				purchaseDate : propertyPurchaseDate, 
				roomCt       : propertyRoomCt,
				bathCt       : propertyBathCt,
				is_Default   : propertyDefault,
				hoa          : propertyHOA,
				footage      : propertyFootage,
				roof_updated : propertyRoof,
				// is_Default   : propertyDefault
			})
			.then((result) => {

				var value = $('.propertyEditImage').val();
				// console.log('value: ', value);
				if (value.length < 3) {
					this.setState({
						propertyImage : '',
						imgFormFile      : '',
						imgFileName      : '',
						showPropertyEdit : false,
					})
				} else if (this.state.propertyImage){
					// console.log(e.target.files[0]);
					// console.log('image: ', propertyImage);
					this.postPropertyImage(e)
				}
				this.getCurrentProperty();	
			});
		} catch(ex){
			console.log('updateProperty exception : ', ex);
		}
	}

	getCurrentProperty(){
		try{
			if (sessionStorage.getItem('currentActiveProperty') === null){
				axios.get(process.env.REACT_APP_BASE_URL+"properties/user/" + this.state.userId)
				.then(res => {
					const property = res.data.result[0];
					// console.log('property1 : ', property);
				
					if (property.propertyImages.length > 0){
						var images     = property.propertyImages;
						var the_images = images.map(image => ({'original': image.urL_Full, 'thumbnail': image.urL_Thumb ? image.urL_Thumb  : '', originalAlt: 'image_'+image.imageId, thumbnailAlt: 'image_'+image.imageId }));
						this.setState({ 
							imageGallery : the_images,
							loadImage    : true,
							allImages    : images
						})
					} else {
						this.setState({ 
							imageGallery : '',
							loadImage    : false
						})
					}

					this.setState({ 
						loadProperty            : true,
						loadUtilities           : true,
						loadAppliances          : true,
						loadContacts            : true,
						propertyId              : property.id, 
						propertyDescription     : property.name, 
						propertyPhone           : property.phoneNumber,  
						propertyAddress1        : property.address1,  
						propertyAddress2        : property.address2, 
						propertyCity            : property.city, 
						propertyZipCode         : property.zipCode, 
						propertyState           : property.state, 
						propertyPurchaseDate    : property.purchaseDate,  
						propertyYearBuilt       : property.yearBuilt, 
						propertyRoomCt          : property.roomCt, 
						propertyBathCt          : property.bathCt,
						propertyImages          : property.propertyImages,
						propertyHOA             : property.hoa,
						propertyFootage         : property.footage,
						propertyDocuments       : property.propertyDocuments,
						propertyRoof            : property.roof_Updated,
						propertyDefault         : property.is_Default
					});

					this.getContactData();
					this.getUtilityData();
					this.getApplianceData();
					// this.getBottomRowHeights();
					// $('.PropertyEditForm').hide();
					this.setState({ 
						showLoader : false,
					});
				});
			} else {
				let currentProperty = sessionStorage.getItem('currentActiveProperty');

				axios.get(process.env.REACT_APP_BASE_URL+"Properties/" + currentProperty)
				.then(res => {
					const property = res.data.result;
					// console.log('property2 : ', property);
					if (property.propertyImages.length > 0){
						var images     = property.propertyImages;
						var the_images = images.map(image => ({'original': image.urL_Full, 'thumbnail': image.urL_Thumb ? image.urL_Thumb  : '', originalAlt: 'image_'+image.imageId, thumbnailAlt: 'image_'+image.imageId }));
						this.setState({ 
							imageGallery : the_images,
							loadImage    : true,
							allImages    : images
						})
					} else {
						this.setState({ 
							imageGallery : '',
							loadImage    : false
						})
					}
					
					this.setState({ 
						loadProperty            : true,
						loadUtilities           : true,
						loadAppliances          : true,
						loadContacts            : true,
						propertyId              : property.id, 
						propertyDescription     : property.name, 
						propertyPhone           : property.phoneNumber,  
						propertyAddress1        : property.address1,  
						propertyAddress2        : property.address2, 
						propertyCity            : property.city, 
						propertyZipCode         : property.zipCode, 
						propertyState           : property.state, 
						propertyPurchaseDate    : property.purchaseDate,  
						propertyYearBuilt       : property.yearBuilt, 
						propertyRoomCt          : property.roomCt, 
						propertyBathCt          : property.bathCt,
						propertyImages          : property.propertyImages,
						propertyHOA             : property.hoa,
						propertyFootage         : property.footage,
						propertyDocuments       : property.propertyDocuments,
						propertyRoof            : property.roof_Updated,
						propertyDefault         : property.is_Default
						
					});

					this.getContactData();
					this.getUtilityData();
					this.getApplianceData();
					this.setState({ 
						showLoader : false,
					});
					// this.getBottomRowHeights();
					// $('.PropertyEditForm').hide();
				});
			}
			// console.log("state :: ", this.state)
		} catch(ex){
			console.log('getCurrentProperty exception : ', ex);
		}
	}

	// getPropertiesByUser(){
	// 	try{
	// 		axios.get(process.env.REACT_APP_BASE_URL+"properties/user/" + this.state.userId)
	// 		.then(res => {
	// 			const properties = res.data.result;
	// 			// console.log('properties2 : ', properties);
	// 			if (properties.length > 0) {
					
	// 				this.setState({ 
	// 					properties, 
	// 					loadProperty : true
	// 				});
					
	// 				$('.PropertyEditForm').hide();
	// 				this.setState({ showPropertyEdit : false });
	// 			}
	// 		});
	// 	} catch(ex){
	// 		console.log('getPropertiesByUser exception : ', ex);
	// 	}
	// }

	_handleInput(event) {
		let obj  =  {}
		let key  = event.target.name;
		obj[key] = event.target.value;
		this.setState(obj);
	}

	_handleInputUtility(event) {

		let obj  =  {}
		let key  = event.target.name;
		obj[key] = event.target.value;
		// let type = event.target.innerHTML;
		let type = $("#add_utility_type option:selected").html();
		this.setState({
			utilityTypeDescription : type
		})
		// console.log('this type: ', type);
		this.setState(obj);
	}

	_handleInputContact(event) {

		let obj  =  {}
		let key  = event.target.name;
		obj[key] = event.target.value;
		// let type = event.target.innerHTML;
		let type = $("#add_contact_type option:selected").html();
		this.setState({
			contactTypeDescription : type
		})
		// console.log('this type: ', type);
		this.setState(obj);
	}

	_handleCheckbox(event){
		const { checked } = event.target;
		this.setState({
			contactShowHighlight : checked
		},() => {
			this.updateContactHighlight(event);
		});
	}

	updateContactHighlight(e){

		try{
			axios.put(process.env.REACT_APP_BASE_URL+"Contacts/highlight",{
			
				propertyId : this.state.propertyId,
				contactId  : this.state.contactId,
				isShown    : this.state.contactShowHighlight,
			
			})
			.then(res => {
				// console.log('the res: ', res);
			});
		} catch(ex){
			console.log('updateContactHighlight exception : ', ex);
		}
	}

	getBottomRowHeights(){
		
		var utility_height   = $("#utility_container").height();
		var appliance_height = $("#appliance_container").height();
		var contact_height   = $("#contact_container").height();

		var highest = Math.max(utility_height, appliance_height, contact_height);
		// console.log('highest box: ', highest);
		this.setBottomRowHeights(highest);
	}

	setBottomRowHeights(height){
		// $('.bottom_row_box').height(height);
	}
	
	render() {

		const showLoader = this.state.showLoader;
		
		let loaderDisplay;

		if (showLoader) {
			loaderDisplay = 
						<div className="loader_container">
							<Loader
								type="Oval"
								color="#5FCE7F"
								height={100}
								width={100}
								timeout={10000} //10 secs
								visible={this.state.showLoader}
							/>
						</div>
		} else {
			loaderDisplay = "";
		}
							
		const showImageAlert = this.state.showImageAlert;
		
		let alertDisplay;

		if (showImageAlert) {
			alertDisplay = 	
							<Row>
								<Col md={1}></Col>
								<Col md={5}>
									<Alert variant="danger" style={{ width: "26rem" }}>
										{/* <Alert.Heading> */}
											{this.state.message}
										{/* </Alert.Heading> */}
									</Alert>
								</Col>
								<Col md={6}></Col>
							</Row>;
							
		} else {
			alertDisplay = "";
		}

		const loadProperty = this.state.loadProperty;
		let propertyDisplay;
    	if (loadProperty) {
			propertyDisplay = 
							<div className="property_container"> 
			 				<Modal show={this.state.showPropertyEdit} size='lg' onHide={this.handleClose}>
								<Modal.Header closeButton>
									<Modal.Title>Edit Property</Modal.Title>
								</Modal.Header>
									<Modal.Body>
										<Form 
											className="formCard PropertyEditForm" 
											onSubmit={this.updateProperty}
											>
											<div className="cardTitle">General Home Info</div>
											<Form.Group as={Row}  controlId="formHorizontalPropertyDescription">
												<Col md={4}>
													<Form.Label className="formCardTitle">Description: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														// placeholder="name" 
														name='propertyDescription' 
														onChange={this._handleInput} 
														value={this.state.propertyDescription} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalAddress1">
												<Col md={4}>
													<Form.Label className="formCardTitle">Address 1: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														name='propertyAddress1' 
														onChange={this._handleInput} 
														ref={this.autocompleteInput}
														onFocus={this.initGooglePlaces}
														value={this.state.propertyAddress1} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalAddress2">
												<Col md={4}>
													<Form.Label className="formCardTitle">Address 2: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														name='propertyAddress2' 
														onChange={this._handleInput} 
														value={this.state.propertyAddress2} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalCity">
												<Col md={4}>
													<Form.Label className="formCardTitle">City: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														name='propertyCity' 
														onChange={this._handleInput} 
														value={this.state.propertyCity} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalState">
												<Col md={4}>
													<Form.Label className="formCardTitle">State: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														as="select" 
														name='propertyState' 
														onChange={this._handleInput} 
														value={this.state.propertyState}
														// defaultValue={this.state.propertyState}
														>
														<option value="">Choose...</option>
														<option value="AL">Alabama</option>
														<option value="AK">Alaska</option>
														<option value="AZ">Arizona</option>
														<option value="AR">Arkansas</option>
														<option value="CA">California</option>
														<option value="CO">Colorado</option>
														<option value="CT">Connecticut</option>
														<option value="DE">Delaware</option>
														<option value="DC">District Of Columbia</option>
														<option value="FL">Florida</option>
														<option value="GA">Georgia</option>
														<option value="HI">Hawaii</option>
														<option value="ID">Idaho</option>
														<option value="IL">Illinois</option>
														<option value="IN">Indiana</option>
														<option value="IA">Iowa</option>
														<option value="KS">Kansas</option>
														<option value="KY">Kentucky</option>
														<option value="LA">Louisiana</option>
														<option value="ME">Maine</option>
														<option value="MD">Maryland</option>
														<option value="MA">Massachusetts</option>
														<option value="MI">Michigan</option>
														<option value="MN">Minnesota</option>
														<option value="MS">Mississippi</option>
														<option value="MO">Missouri</option>
														<option value="MT">Montana</option>
														<option value="NE">Nebraska</option>
														<option value="NV">Nevada</option>
														<option value="NH">New Hampshire</option>
														<option value="NJ">New Jersey</option>
														<option value="NM">New Mexico</option>
														<option value="NY">New York</option>
														<option value="NC">North Carolina</option>
														<option value="ND">North Dakota</option>
														<option value="OH">Ohio</option>
														<option value="OK">Oklahoma</option>
														<option value="OR">Oregon</option>
														<option value="PA">Pennsylvania</option>
														<option value="RI">Rhode Island</option>
														<option value="SC">South Carolina</option>
														<option value="SD">South Dakota</option>
														<option value="TN">Tennessee</option>
														<option value="TX">Texas</option>
														<option value="UT">Utah</option>
														<option value="VT">Vermont</option>
														<option value="VA">Virginia</option>
														<option value="WA">Washington</option>
														<option value="WV">West Virginia</option>
														<option value="WI">Wisconsin</option>
														<option value="WY">Wyoming</option>
													</Form.Control>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalZipcode">
												<Col md={4}>
													<Form.Label className="formCardTitle">Zip Code: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="number" 
														name='propertyZipCode' 
														onChange={this._handleInput} 
														value={this.state.propertyZipCode} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalYearBuilt">
												<Col md={4}>
													<Form.Label className="formCardTitle">Year Built: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="number" 
														name='propertyYearBuilt' 
														onChange={this._handleInput} 
														value={this.state.propertyYearBuilt} 
														/>
												</Col>
											</Form.Group>
										
											<Form.Group as={Row} controlId="formHorizontalPropId">
												<Form.Control 
													type="hidden" 
													name='propertyId' 
													value={this.state.propertyId} 
													// onChange={this._handleInput} 
													required/>
												{/* <Col md={4}>
													<Form.Label className="formCardTitle">Phone: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														name='propertyPhone' 
														onChange={this._handleInput} 
														value={this.state.propertyPhone} 
														/>
												</Col> */}
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalFootage">
												<Col md={4}>
													<Form.Label className="formCardTitle">Footage: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="number" 
														name='propertyFootage' 
														onChange={this._handleInput} 
														value={this.state.propertyFootage} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalRoomCnt">
												<Col md={4}>
													<Form.Label className="formCardTitle">Room Count: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="number" 
														name='propertyRoomCt' 
														onChange={this._handleInput} 
														value={this.state.propertyRoomCt} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalBathCnt">
												<Col md={4}>
													<Form.Label className="formCardTitle">Bath Count: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														name='propertyBathCt' 
														onChange={this._handleInput} 
														value={this.state.propertyBathCt} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalPurchaseDate">
												<Col md={4}>
													<Form.Label className="formCardTitle">Purchase Date: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="date" 
														name='propertyPurchaseDate' 
														onChange={this._handleInput} 
														// defaultValue={dateFormat(this.state.propertyPurchaseDate, 'yyyy-mm-dd')} 
														value={ this.state.propertyPurchaseDate ? moment(this.state.propertyPurchaseDate).format('YYYY-MM-DD') : null } 
														// value={this.state.propertyPurchaseDate} 
														/>
												</Col>
											</Form.Group>
										
											<Form.Group as={Row} controlId="formHorizontalHOA">
												<Col md={4}>
													<Form.Label className="formCardTitle">HOA Fees</Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														// className="form_display_data"
														name='propertyHOA' 
														onChange={this._handleInput} 
														value={this.state.propertyHOA} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalRoof">
												<Col md={4}>
													<Form.Label className="formCardTitle">Roof Updated</Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="date" 
														// className="form_display_data"
														name='propertyRoof' 
														onChange={this._handleInput} 
														value={ this.state.propertyRoof ? moment(this.state.propertyRoof).format('YYYY-MM-DD') : null } 
														// defaultValue={dateFormat(this.state.propertyRoof, 'yyyy-mm-dd')} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalPropetyImage">
												<Col md={4}>
													<Form.Label className="formCardTitle">Property Image: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="file" 
														className='propertyEditImage'
														name='propertyImage' 
														onChange={(e) => this.setSelectedImage(e)}
														/>
												</Col>
											</Form.Group>
											{alertDisplay}
											<Form.Group as={Row}>
												<Col sm={{ span: 10 }}>
													<Button type="submit">Save</Button>
												</Col>
											</Form.Group>
										</Form>
									</Modal.Body>
									<Modal.Footer>
										<Button variant="secondary" className="white-btn"  onClick={this.handleClose}>
											Close
										</Button>
									</Modal.Footer>
								</Modal>
								<div>										
										<div className="property_container" >
											<Form 
												className="formCard non_edit PropertyForm" 
												>
												<div className="cardTitle propertyCardLine">General Home Info
													<Button className="property_edit_btn_home" onClick={this.handleShow}>Edit</Button>
												</div>
												<Form.Group as={Row} className="form_display_row" controlId="formHorizontalPropertyName">
													<Col lg={4} md={6}>
														<Form.Label className="formCardTitle">Name</Form.Label>
													</Col>
													<Col lg={8} md={6}>
														<Form.Control 
															type="text" 
															className="form_display_data"
															name='propertyDescription' 
															value={this.state.propertyDescription} 
															readOnly
															/>
													</Col>
												</Form.Group>
												{this.state.propertyContacts.filter(contact => contact.showHighlight === true).map(contact =>
													<Form.Group as={Row} className="form_display_row" key={contact.contactId} controlId={contact.contactId}>
														<Col lg={4} md={6}>
															<Form.Label className="formCardTitle"> {contact.contactTypeDesc} </Form.Label>
														</Col>
														<Col lg={8} md={6}>
															{/* <Form.Control 
																type="text" 
																name='contactName' 
																className="form_display_data"
																// onChange={this._handleInput} 
																value={contact.name}
																readOnly
																/> */}
															<a href='#' className="form_display_data" onClick={(e) => { this.showContactDetail(e, contact.contactId) }}>
																{contact.name}
															</a>
														</Col>
													</Form.Group>
												)}
												{/* <Form.Group as={Row} className="form_display_row" controlId="formHorizontalAddress1">
													<Col md={4}>
														<Form.Label className="formCardTitle">Address 1</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															className="form_display_data"
															name='propertyAddress1' 
															// onChange={this._handleInput} 
															value={this.state.propertyAddress1} 
															readOnly
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="form_display_row" controlId="formHorizontalAddress2">
													<Col md={4}>
														<Form.Label className="formCardTitle">Address 2</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															className="form_display_data"
															name='propertyAddress2' 
															// onChange={this._handleInput} 
															value={this.state.propertyAddress2} 
															readOnly
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="form_display_row" controlId="formHorizontalCity">
													<Col md={4}>
														<Form.Label className="formCardTitle">City</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															className="form_display_data"
															name='propertyCity' 
															// onChange={this._handleInput} 
															value={this.state.propertyCity} 
															readOnly
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="form_display_row"  controlId="formHorizontalState">
													<Col md={4}>
														<Form.Label className="formCardTitle">State</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															className="form_display_data"
															name='propertyState' 
															// onChange={this._handleInput} 
															value={this.state.propertyState} 
															readOnly
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="form_display_row" controlId="formHorizontalZipCode">
													<Col md={4}>
														<Form.Label className="formCardTitle">Zip Code</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															className="form_display_data"
															name='propertyZipCode' 
															// onChange={this._handleInput} 
															value={this.state.propertyZipCode} 
															readOnly
															/>
													</Col>
												</Form.Group> */}
												<Form.Group as={Row} className="form_display_row" controlId="formHorizontalYearBuilt">
													<Col lg={4} md={6}>
														<Form.Label className="formCardTitle">Year Built</Form.Label>
													</Col>
													<Col lg={8} md={6}>
														<Form.Control 
															type="number" 
															className="form_display_data"
															name='propertyYearBuilt' 
															// onChange={this._handleInput} 
															value={this.state.propertyYearBuilt} 
															readOnly
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="form_display_row" controlId="formHorizontalFootage">
													<Col lg={4} md={6}>
														<Form.Label className="formCardTitle">Footage</Form.Label>
													</Col>
													<Col lg={8} md={6}>
														<Form.Control 
															type="number" 
															className="form_display_data"
															name='propertyFootage' 
															value={this.state.propertyFootage} 
															readOnly
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="form_display_row" controlId="formHorizontalRoomCount">
													<Col lg={4} md={6}>
														<Form.Label className="formCardTitle">Rooms</Form.Label>
													</Col>
													<Col lg={8} md={6}>
														<Form.Control 
															type="text" 
															className="form_display_data"
															name='propertyRoomCt' 
															// onChange={this._handleInput} 
															value={this.state.propertyRoomCt} 
															readOnly
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="form_display_row" controlId="formHorizontalBathCount">
													<Col lg={4} md={6}>
														<Form.Label className="formCardTitle">Baths</Form.Label>
													</Col>
													<Col lg={8} md={6}>
														<Form.Control 
															type="text" 
															className="form_display_data"
															name='propertyBathCt' 
															// onChange={this._handleInput} 
															value={this.state.propertyBathCt} 
															readOnly
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="form_display_row" controlId="formHorizontalPurchaseDate">
													<Col lg={4} md={6}>
														<Form.Label className="formCardTitle">Purchase Date</Form.Label>
													</Col>
													<Col lg={8} md={6}>
														<Form.Control 
															type="text" 
															className="form_display_data"
															name='propertyPurchaseDate' 
															// onChange={this._handleInput} 
															// value={dateFormat(this.state.propertyPurchaseDate, 'mmmm d, yyyy')} 
															value={ this.state.propertyPurchaseDate ? moment(this.state.propertyPurchaseDate).format('LL') : null } 
															readOnly
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="form_display_row" controlId="formHorizontalHOA">
													<Col lg={4} md={6}>
														<Form.Label className="formCardTitle">HOA Fees</Form.Label>
													</Col>
													<Col lg={8} md={6}>
														<Form.Control 
															type="text" 
															className="form_display_data"
															name='propertyHOA' 
															// onChange={this._handleInput} 
															value={this.state.propertyHOA} 
															readOnly
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="form_display_row" controlId="formHorizontalRoof">
													<Col lg={4} md={6}>
														<Form.Label className="formCardTitle">Roof Updated</Form.Label>
													</Col>
													<Col lg={8} md={6}>
														<Form.Control 
															type="text" 
															className="form_display_data"
															name='propertyRoof' 
															// onChange={this._handleInput} 
															// value={dateFormat(this.state.propertyRoof, 'mmmm d, yyyy')} 
															// value={dateFormat(this.state.propertyRoof, 'mmmm d, yyyy')} 
															value={ this.state.propertyRoof ? moment(this.state.propertyRoof).format('LL') : null } 
															readOnly
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="form_display_row"  controlId="formHorizontalPropertyId">
													<Form.Control 
														type="hidden" 
														className="form_display_data"
														name='propertyId' 
														value={this.state.propertyId} 
														// onChange={this._handleInput} 
														// readOnly
														required/>
													{/* <Col md={4}>
														<Form.Label className="formCardTitle">Phone</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															className="form_display_data"
															name='propertyPhone' 
															// onChange={this._handleInput} 
															value={this.state.propertyPhone} 
															readOnly
															/>
													</Col> */}
												</Form.Group>
												{/* <Form.Group as={Row}>
													<Col sm={{ span: 10 }}>
														<Button type="submit">Save</Button>
													</Col>
												</Form.Group> */}
											</Form>
										</div>
									{/* )
								} */}
								</div>
								{/* <div>
									<Form 
										className="formCard PropertyImageForm" 
										onSubmit={this.postPropertyImage}
										>
										<div className="cardTitle">Property Image</div>
										<Form.Group as={Row} controlId="formHorizontalPropetyImage">
											<Col md={4}>
												<Form.Label className="formCardTitle">Property Image: </Form.Label>
											</Col>
											<Col md={8}>
												<Form.Control 
													type="file" 
													// placeholder="name" 
													name='propertyImage' 
													// onChange={this._handleInput} 
													onChange={(e) => this.setSelectedImage(e)}
													/>
											</Col>
										</Form.Group>

										<Form.Group as={Row}>
											<Col sm={{ span: 10 }}>
												<Button type="submit">Save</Button>
											</Col>
										</Form.Group>
									</Form>
								</div> */}
							</div>
		} else {
			propertyDisplay = <div></div>
		} 

		const loadUtilities= this.state.loadUtilities;
		let utilitiesDisplay;
    	if (loadUtilities) {
			utilitiesDisplay =  <div className="utility_container" id="utility_container">
									<Form 
									className="formCard non_edit UtilityForm bottom_row_box" 
									>
									<div className="cardTitle propertyCardLine">Utilities
										<Button onClick={this.handleShowAddUtility}>Add</Button>
									</div>
									{this.state.propertyUtilities.map(utility => 
										<Form.Group as={Row} className="form_display_row" key={utility.utilityProviderId} controlId={utility.utilityProviderId}>
											<Col md={4}>
												<Form.Label className="formCardTitle"> {utility.utilityTypeDesc} </Form.Label>
											</Col>
											<Col md={8}>
												{/* <Form.Control 
													type="text" 
													name='contactName' 
													className="form_display_data"
													// onChange={this._handleInput} 
													value={contact.name}
													readOnly
													/> */}
												<a href='#' className="form_display_data" onClick={(e) => { this.showUtilityDetail(e, utility.utilityProviderId) }}>
													{utility.name}
												</a>
												
											</Col>
										</Form.Group>
									)}
								</Form>

								<Modal show={this.state.showUtilityEdit} size='lg' onHide={this.handleCloseUtility}>
									<Modal.Header closeButton>
										<Modal.Title>Edit Utility</Modal.Title>
									</Modal.Header>
										<Modal.Body>
											<Form 
												className="formCard UtilityEditForm" 
												onSubmit={this.updateUtilityData}
												>
												<div className="cardTitle">Utility</div>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Type</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='utilityTypeId' 
															readOnly
															value={this.state.utilityTypeDescription}
															// onChange={this._handleInput}
															/>
															{/* {this.state.contactTypeDescription} */}
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Name</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='utilityName' 
															value={this.state.utilityName}
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Phone</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="tel" 
															name='utilityPhone' 
															value={this.state.utilityPhone}
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Address 1</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='utilityAddress1' 
															ref={this.autocompleteInputUtility}
															onFocus={this.initGooglePlacesUtility}
															value={this.state.utilityAddress1}
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Address 2</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='utilityAddress2' 
															value={this.state.utilityAddress2}
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">City</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='utilityCity' 
															value={this.state.utilityCity}
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalState">
													<Col md={4}>
														<Form.Label className="formCardTitle">State: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="select" 
															name='utilityState' 
															onChange={this._handleInput} 
															value={this.state.utilityState}>
															<option value="">Choose...</option>
															<option value="AL">Alabama</option>
															<option value="AK">Alaska</option>
															<option value="AZ">Arizona</option>
															<option value="AR">Arkansas</option>
															<option value="CA">California</option>
															<option value="CO">Colorado</option>
															<option value="CT">Connecticut</option>
															<option value="DE">Delaware</option>
															<option value="DC">District Of Columbia</option>
															<option value="FL">Florida</option>
															<option value="GA">Georgia</option>
															<option value="HI">Hawaii</option>
															<option value="ID">Idaho</option>
															<option value="IL">Illinois</option>
															<option value="IN">Indiana</option>
															<option value="IA">Iowa</option>
															<option value="KS">Kansas</option>
															<option value="KY">Kentucky</option>
															<option value="LA">Louisiana</option>
															<option value="ME">Maine</option>
															<option value="MD">Maryland</option>
															<option value="MA">Massachusetts</option>
															<option value="MI">Michigan</option>
															<option value="MN">Minnesota</option>
															<option value="MS">Mississippi</option>
															<option value="MO">Missouri</option>
															<option value="MT">Montana</option>
															<option value="NE">Nebraska</option>
															<option value="NV">Nevada</option>
															<option value="NH">New Hampshire</option>
															<option value="NJ">New Jersey</option>
															<option value="NM">New Mexico</option>
															<option value="NY">New York</option>
															<option value="NC">North Carolina</option>
															<option value="ND">North Dakota</option>
															<option value="OH">Ohio</option>
															<option value="OK">Oklahoma</option>
															<option value="OR">Oregon</option>
															<option value="PA">Pennsylvania</option>
															<option value="RI">Rhode Island</option>
															<option value="SC">South Carolina</option>
															<option value="SD">South Dakota</option>
															<option value="TN">Tennessee</option>
															<option value="TX">Texas</option>
															<option value="UT">Utah</option>
															<option value="VT">Vermont</option>
															<option value="VA">Virginia</option>
															<option value="WA">Washington</option>
															<option value="WV">West Virginia</option>
															<option value="WI">Wisconsin</option>
															<option value="WY">Wyoming</option>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Zip Code</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="number" 
															name='utilityZipCode' 
															value={this.state.utilityZipCode}
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Email</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="email" 
															name='utilityEmail' 
															value={this.state.utilityEmail}
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Website</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="url" 
															name='utilityWebAddress' 
															value={this.state.utilityWebAddress}
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row}>
													<Col sm={{ span: 10 }}>
														<Button type="submit">Save</Button>
													</Col>
													<Col sm={{ span: 2 }}>
														<Button type="button" className="delete_btn" onClick={(e) => { this.deleteUtility(e, this.state.utilityProviderId) }}>Delete</Button>
													</Col>
												</Form.Group>
											</Form>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" className="white-btn"  onClick={this.handleCloseUtility}>
												Close
											</Button>
										</Modal.Footer>
									</Modal>
									<Modal show={this.state.showUtilityAdd} size='lg' onHide={this.handleCloseAddUtility}>
									<Modal.Header closeButton>
										<Modal.Title>Add Utility</Modal.Title>
									</Modal.Header>
										<Modal.Body>
											<Form 
												className="formCard UtilityAddForm" 
												onSubmit={this.postUtilityData}
												>
												<div className="cardTitle">Utility</div>
												<Form.Group as={Row} >
													<Col md={4}>
														<Form.Label className="formCardTitle">Category: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="select" 
															name='utilityTypeId' 
															id="add_utility_type"
															onChange={this._handleInputUtility} 
															// defaultValue={this.state.contractorCategoryId}
															>
															{this.state.propertyUtilityTypes.map(utilityType => 
																<option key={utilityType.key} value={utilityType.key}>{utilityType.value}</option>
															)};
															<option value="0">Other</option>
														</Form.Control>
													</Col>
												</Form.Group>
												
												{/* <Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle"> Contact Description</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='contactTypeDescription' 
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group> */}
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Name</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='utilityName' 
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Phone</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="tel" 
															name='utilityPhone' 
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Address 1</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='utilityAddress1' 
															ref={this.autocompleteInputUtility}
															onFocus={this.initGooglePlacesUtility}
															onChange={this._handleInput}
															value={this.state.utilityAddress1}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Address 2</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='utilityAddress2' 
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">City</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='utilityCity' 
															onChange={this._handleInput}
															value={this.state.utilityCity}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalState">
													<Col md={4}>
														<Form.Label className="formCardTitle">State: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="select" 
															name='utilityState' 
															onChange={this._handleInput} 
															value={this.state.utilityState}>
															<option value="">Choose...</option>
															<option value="AL">Alabama</option>
															<option value="AK">Alaska</option>
															<option value="AZ">Arizona</option>
															<option value="AR">Arkansas</option>
															<option value="CA">California</option>
															<option value="CO">Colorado</option>
															<option value="CT">Connecticut</option>
															<option value="DE">Delaware</option>
															<option value="DC">District Of Columbia</option>
															<option value="FL">Florida</option>
															<option value="GA">Georgia</option>
															<option value="HI">Hawaii</option>
															<option value="ID">Idaho</option>
															<option value="IL">Illinois</option>
															<option value="IN">Indiana</option>
															<option value="IA">Iowa</option>
															<option value="KS">Kansas</option>
															<option value="KY">Kentucky</option>
															<option value="LA">Louisiana</option>
															<option value="ME">Maine</option>
															<option value="MD">Maryland</option>
															<option value="MA">Massachusetts</option>
															<option value="MI">Michigan</option>
															<option value="MN">Minnesota</option>
															<option value="MS">Mississippi</option>
															<option value="MO">Missouri</option>
															<option value="MT">Montana</option>
															<option value="NE">Nebraska</option>
															<option value="NV">Nevada</option>
															<option value="NH">New Hampshire</option>
															<option value="NJ">New Jersey</option>
															<option value="NM">New Mexico</option>
															<option value="NY">New York</option>
															<option value="NC">North Carolina</option>
															<option value="ND">North Dakota</option>
															<option value="OH">Ohio</option>
															<option value="OK">Oklahoma</option>
															<option value="OR">Oregon</option>
															<option value="PA">Pennsylvania</option>
															<option value="RI">Rhode Island</option>
															<option value="SC">South Carolina</option>
															<option value="SD">South Dakota</option>
															<option value="TN">Tennessee</option>
															<option value="TX">Texas</option>
															<option value="UT">Utah</option>
															<option value="VT">Vermont</option>
															<option value="VA">Virginia</option>
															<option value="WA">Washington</option>
															<option value="WV">West Virginia</option>
															<option value="WI">Wisconsin</option>
															<option value="WY">Wyoming</option>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Zip Code</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="number" 
															name='utilityZipCode' 
															value={this.state.utilityZipCode}
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Email</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="email" 
															name='utilityEmail' 
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Website</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="url" 
															name='utilityWebAddress' 
															onChange={this._handleInput}
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row}>
													<Col sm={{ span: 10 }}>
														<Button type="submit">Save</Button>
													</Col>
												</Form.Group>
											</Form>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" className="white-btn"  onClick={this.handleCloseAddUtility}>
												Close
											</Button>
										</Modal.Footer>
									</Modal>
								</div>
							
		} else {
			utilitiesDisplay = <div></div>
		} 

		const loadApplianceImage = this.state.loadApplianceImage;
		let applianceImageDisplay;

		if (loadApplianceImage) {
			applianceImageDisplay = <div className="img_container">
										<ImageGallery 
											items={this.state.applianceImageGallery} 
											showBullets={true}
											ref={i => this.refImgApplianceHome = i}
											renderCustomControls={this.renderApplianceImageDelete}
										/>
									</div>
		} else {
			applianceImageDisplay = <div className="img_container"></div>
		}

		const loadDocument = this.state.loadDocument;
		let documentDisplay;
    	if (loadDocument) {
			documentDisplay = <div className="document_container">
								<Row>
									{this.state.docsDisplay.map((doc, index) => 
										<Col md={2}>
											<a href={doc.path}  className="doc_link" download={doc.description} key={doc.decscription} target="_blank" rel="noopener noreferrer">
												{doc.icon === 'word'  ? <FontAwesomeIcon className="icon doc_icon" icon={faFileWord}     key={index}/> : null }
												{doc.icon === 'excel' ? <FontAwesomeIcon className="icon doc_icon" icon={faFileExcel}    key={index}/> : null }
												{doc.icon === 'pdf'   ? <FontAwesomeIcon className="icon doc_icon" icon={faFilePdf}      key={index}/> : null }
												{doc.icon === 'file'  ? <FontAwesomeIcon className="icon doc_icon" icon={faFileDownload} key={index}/> : null }
												{doc.icon === 'image' ? <FontAwesomeIcon className="icon doc_icon" icon={faFileImage}    key={index}/> : null }
											</a>
											{/* <Button variant="secondary" className="doc_delete" onClick={(e) => { this.deleteDocument(e, doc.documentId) }} key={doc.documentId}>
												X
											</Button> */}
											<FontAwesomeIcon className="red-btn icon doc_delete_app" onClick={(e) => { this.deleteDocument(e, doc.documentId) }} key={doc.documentId} icon={ faTimesCircle }/>
										</Col>
										
									)}
				 				</Row>
							</div>
		} else {
			documentDisplay = <div className="document_container"></div>
		}

		const loadAppliances = this.state.loadAppliances;
		let appliancesDisplay;
    	if (loadAppliances) {
			appliancesDisplay =  <div className="appliance_container" id="appliance_container">
									<Form 
										className="formCard non_edit ApplianceForm bottom_row_box" 
										>
										<div className="cardTitle propertyCardLine">Appliances
											<Button onClick={this.handleShowAddAppliance}>Add</Button>
										</div>
										{this.state.propertyAppliances.map(appliance => 
											<Form.Group as={Row} className="form_display_row" key={appliance.applianceId} controlId={appliance.applianceId}>
												<Col md={4}>
													<Form.Label className="formCardTitle"> {dateFormat(appliance.purchaseDate, 'm/dd/yyyy')} </Form.Label>
												</Col>
												<Col md={8}>
													{/* <Form.Control 
														type="text" 
														name='contactName' 
														className="form_display_data"
														// onChange={this._handleInput} 
														value={contact.name}
														readOnly
														/> */}
													<a href='#' className="form_display_data" onClick={(e) => { this.showApplianceDetail(e, appliance.applianceId) }}>
														{appliance.description}
													</a>
													
												</Col>
											</Form.Group>
										)}
									</Form>

									<Modal show={this.state.showApplianceEdit} size='lg' onHide={this.handleCloseAppliance}>
										<Modal.Header closeButton>
											<Modal.Title>Edit Appliance</Modal.Title>
										</Modal.Header>
											<Modal.Body>
												<Form 
													className="formCard ApplianceEditForm" 
													onSubmit={this.updateApplianceData}
													>
													<div className="cardTitle">Appliance</div>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Purchase Date</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="date" 
																name='appliancePurchaseDate' 
																// defaultValue={dateFormat(this.state.appliancePurchaseDate, 'yyyy-mm-dd')}
																value={ this.state.appliancePurchaseDate ? moment(this.state.appliancePurchaseDate).format('YYYY-MM-DD') : '' } 
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Description</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='applianceDescription' 
																value={this.state.applianceDescription}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Notes</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='applianceNotes' 
																value={this.state.applianceNotes}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Receipt URL</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="url" 
																name='appianceReceiptURL' 
																value={this.state.applianceReceiptURL}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Warranty URL</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="url" 
																name='applianceWarrantyURL' 
																value={this.state.applianceWarrantyURL}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalApplianceImage">
														<Col md={4}>
															<Form.Label className="formCardTitle">Appliance Image</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="file" 
																className='applianceEditImage'
																name='applianceImage' 
																onChange={(e) => this.setSelectedApplianceImage(e)}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalApplianceDocument">
														<Col md={4}>
															<Form.Label className="formCardTitle">Document</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="file" 
																// placeholder="name" 
																className='applianceDocument'
																name='applianceDocument' 
																// onChange={this._handleInput} 
																onChange={(e) => this.setSelectedDocument(e)}
																/>
														</Col>
													</Form.Group>
													<Row>
														<Col md={1}></Col>
														<Col md={10}>
															{applianceImageDisplay}
														</Col>
														<Col md={1}></Col>
													</Row>
													<Row>
														<Col md={2}></Col>
														<Col md={8}>
															<div>
																{documentDisplay}
															</div>
														</Col>
														<Col md={2}></Col>
													</Row>
													<Form.Group as={Row}>
														<Col sm={{ span: 10 }}>
															<Button type="submit">Save</Button>
														</Col>
														<Col sm={{ span: 2 }}>
															<Button type="button" className="delete_btn" onClick={(e) => { this.deleteAppliance(e, this.state.applianceId) }}>Delete</Button>
														</Col>
													</Form.Group>
												</Form>
												
											</Modal.Body>
											<Modal.Footer>
												<Button variant="secondary" className="white-btn"  onClick={this.handleCloseAppliance}>
													Close
												</Button>
											</Modal.Footer>
										</Modal>
										<Modal show={this.state.showApplianceAdd} size='lg' onHide={this.handleCloseAddAppliance}>
										<Modal.Header closeButton>
											<Modal.Title>Add Appliance</Modal.Title>
										</Modal.Header>
											<Modal.Body>
												<Form 
													className="formCard ApplianceAddForm" 
													onSubmit={this.postApplianceData}
													>
													<div className="cardTitle">Appliance</div>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Purchase Date</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="date" 
																name='appliancePurchaseDate' 
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Description</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='applianceDescription' 
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Notes</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='applianceNotes' 
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Receipt URL</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="url" 
																name='applianceReceiptURL' 
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Warranty URL</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="url" 
																name='applianceWarrantyURL' 
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalApplianceImage">
														<Col md={4}>
															<Form.Label className="formCardTitle">Image</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="file" 
																className='applianceAddImage'
																name='applianceImage' 
																onChange={(e) => this.setSelectedApplianceImage(e)}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalApplianceDocument">
														<Col md={4}>
															<Form.Label className="formCardTitle">Document</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="file" 
																// placeholder="name" 
																className='applianceDocument'
																name='applianceDocument' 
																// onChange={this._handleInput} 
																onChange={(e) => this.setSelectedDocument(e)}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row}>
														<Col sm={{ span: 10 }}>
															<Button type="submit">Save</Button>
														</Col>
													</Form.Group>
												</Form>
											</Modal.Body>
											<Modal.Footer>
												<Button variant="secondary" className="white-btn"  onClick={this.handleCloseAddAppliance}>
													Close
												</Button>
											</Modal.Footer>
										</Modal>
									</div>
		} else {
			appliancesDisplay = <div></div>
		} 

		const loadContacts = this.state.loadContacts;
		let contactsDisplay;
    	if (loadContacts) {
			contactsDisplay =  	<div className="contact_container" id="contact_container">
									<Form 
										className="formCard non_edit ContactForm bottom_row_box" 
										>
										<div className="cardTitle propertyCardLine">Contacts
											<Button onClick={this.handleShowAddContact}>Add</Button>
										</div>
										{this.state.propertyContacts.map(contact => 
											<Form.Group as={Row} className="form_display_row" key={contact.contactId} controlId={contact.contactId}>
												<Col md={6}>
													<Form.Label className="formCardTitle"> {contact.contactTypeDesc} </Form.Label>
												</Col>
												<Col md={6}>
													{/* <Form.Control 
														type="text" 
														name='contactName' 
														className="form_display_data"
														// onChange={this._handleInput} 
														value={contact.name}
														readOnly
														/> */}
														<a href='#' className="form_display_data" onClick={(e) => { this.showContactDetail(e, contact.contactId) }}>
														{contact.name}
													</a>
													
												</Col>
											</Form.Group>
										)}
									</Form>
									<Modal show={this.state.showContactEdit} size='lg' onHide={this.handleCloseContact}>
										<Modal.Header closeButton>
											<Modal.Title>Edit Contacts</Modal.Title>
										</Modal.Header>
											<Modal.Body>
												<Form 
													className="formCard ContactEditForm" 
													onSubmit={this.updateContactData}
													>
													<div className="cardTitle">Contacts</div>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Type</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='contactTypeId' 
																readOnly
																value={this.state.contactTypeDescription}
																// onChange={this._handleInput}
																/>
																{/* {this.state.contactTypeDescription} */}
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalShowHighlight">
														<Col md={4}>
															<Form.Label className="formCardTitle">Show Highlight: </Form.Label>
														</Col>
														<Col md={2}>
															<Form.Check 
																type="checkbox" 
																// placeholder="name" 
																name='contactShowHighlight' 
																onChange={this._handleCheckbox} 
																value={this.state.contactShowHighlight} 
																checked={this.state.contactShowHighlight}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Name</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='contactName' 
																value={this.state.contactName}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Company</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='contactCompany' 
																value={this.state.contactCompany}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Phone</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="tel" 
																name='contactPhone' 
																value={this.state.contactPhone}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Address 1</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='contactAddress1' 
																ref={this.autocompleteInputContact}
																onFocus={this.initGooglePlacesContact}
																value={this.state.contactAddress1}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Address 2</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='contactAddress2' 
																value={this.state.contactAddress2}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">City</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='contactCity' 
																value={this.state.contactCity}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalState">
														<Col md={4}>
															<Form.Label className="formCardTitle">State: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																as="select" 
																name='contactState' 
																onChange={this._handleInput} 
																value={this.state.contactState}>
																<option value="">Choose...</option>
																<option value="AL">Alabama</option>
																<option value="AK">Alaska</option>
																<option value="AZ">Arizona</option>
																<option value="AR">Arkansas</option>
																<option value="CA">California</option>
																<option value="CO">Colorado</option>
																<option value="CT">Connecticut</option>
																<option value="DE">Delaware</option>
																<option value="DC">District Of Columbia</option>
																<option value="FL">Florida</option>
																<option value="GA">Georgia</option>
																<option value="HI">Hawaii</option>
																<option value="ID">Idaho</option>
																<option value="IL">Illinois</option>
																<option value="IN">Indiana</option>
																<option value="IA">Iowa</option>
																<option value="KS">Kansas</option>
																<option value="KY">Kentucky</option>
																<option value="LA">Louisiana</option>
																<option value="ME">Maine</option>
																<option value="MD">Maryland</option>
																<option value="MA">Massachusetts</option>
																<option value="MI">Michigan</option>
																<option value="MN">Minnesota</option>
																<option value="MS">Mississippi</option>
																<option value="MO">Missouri</option>
																<option value="MT">Montana</option>
																<option value="NE">Nebraska</option>
																<option value="NV">Nevada</option>
																<option value="NH">New Hampshire</option>
																<option value="NJ">New Jersey</option>
																<option value="NM">New Mexico</option>
																<option value="NY">New York</option>
																<option value="NC">North Carolina</option>
																<option value="ND">North Dakota</option>
																<option value="OH">Ohio</option>
																<option value="OK">Oklahoma</option>
																<option value="OR">Oregon</option>
																<option value="PA">Pennsylvania</option>
																<option value="RI">Rhode Island</option>
																<option value="SC">South Carolina</option>
																<option value="SD">South Dakota</option>
																<option value="TN">Tennessee</option>
																<option value="TX">Texas</option>
																<option value="UT">Utah</option>
																<option value="VT">Vermont</option>
																<option value="VA">Virginia</option>
																<option value="WA">Washington</option>
																<option value="WV">West Virginia</option>
																<option value="WI">Wisconsin</option>
																<option value="WY">Wyoming</option>
															</Form.Control>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Zip Code</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="number" 
																name='contactZipCode' 
																value={this.state.contactZipCode}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Email</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="email" 
																name='contactEmail' 
																value={this.state.contactEmail}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Website</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="url" 
																name='contactWebsite' 
																value={this.state.contactWebsite}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row}>
														<Col sm={{ span: 10 }}>
															<Button type="submit">Save</Button>
														</Col>
														<Col sm={{ span: 2 }}>
															<Button type="button" className="delete_btn" onClick={(e) => { this.deleteContact(e, this.state.contactId) }}>Delete</Button>
														</Col>
													</Form.Group>
												</Form>
											</Modal.Body>
											<Modal.Footer>
												<Button variant="secondary" className="white-btn"  onClick={this.handleCloseContact}>
													Close
												</Button>
											</Modal.Footer>
										</Modal>
										<Modal show={this.state.showContactAdd} size='lg' onHide={this.handleCloseAddContact}>
											<Modal.Header closeButton>
												<Modal.Title>Add Contact</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												<Form 
													className="formCard ContactAddForm" 
													onSubmit={this.postContactData}
													>
													<div className="cardTitle">Contact</div>
													<Form.Group as={Row} >
														<Col md={4}>
															<Form.Label className="formCardTitle">Category: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																as="select" 
																name='contactTypeId' 
																id="add_contact_type"
																onChange={this._handleInputContact} 
																// defaultValue={this.state.contractorCategoryId}
																>
																
																{this.state.propertyContactTypes.map(contactType => 
																	<option key={contactType.key} value={contactType.key}>{contactType.value}</option>
																)}
																<option value="0">Other</option>
															</Form.Control>
														</Col>
													</Form.Group>
													
													{/* <Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle"> Contact Description</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='contactTypeDescription' 
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group> */}
													{/* <Form.Group as={Row} controlId="formHorizontalShowHighlight">
														<Col md={4}>
															<Form.Label className="formCardTitle">Show Highlight: </Form.Label>
														</Col>
														<Col md={2}>
															<Form.Check 
																type="checkbox" 
																// placeholder="name" 
																name='contactShowHighlight' 
																onChange={this._handleCheckbox} 
																// value={this.state.showHighlight} 
																// checked={this.state.showHighlight}
																/>
														</Col>
													</Form.Group> */}
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Name</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='contactName' 
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Company</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='contactCompany' 
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Phone</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="tel" 
																name='contactPhone' 
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Address 1</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='contactAddress1' 
																ref={this.autocompleteInputContact}
																onFocus={this.initGooglePlacesContact}
																onChange={this._handleInput}
																value={this.state.contactAddress1}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Address 2</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='contactAddress2' 
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">City</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="text" 
																name='contactCity' 
																onChange={this._handleInput}
																value={this.state.contactCity}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} controlId="formHorizontalState">
														<Col md={4}>
															<Form.Label className="formCardTitle">State: </Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																as="select" 
																name='contactState' 
																onChange={this._handleInput} 
																value={this.state.contactState}>
																<option value="">Choose...</option>
																<option value="AL">Alabama</option>
																<option value="AK">Alaska</option>
																<option value="AZ">Arizona</option>
																<option value="AR">Arkansas</option>
																<option value="CA">California</option>
																<option value="CO">Colorado</option>
																<option value="CT">Connecticut</option>
																<option value="DE">Delaware</option>
																<option value="DC">District Of Columbia</option>
																<option value="FL">Florida</option>
																<option value="GA">Georgia</option>
																<option value="HI">Hawaii</option>
																<option value="ID">Idaho</option>
																<option value="IL">Illinois</option>
																<option value="IN">Indiana</option>
																<option value="IA">Iowa</option>
																<option value="KS">Kansas</option>
																<option value="KY">Kentucky</option>
																<option value="LA">Louisiana</option>
																<option value="ME">Maine</option>
																<option value="MD">Maryland</option>
																<option value="MA">Massachusetts</option>
																<option value="MI">Michigan</option>
																<option value="MN">Minnesota</option>
																<option value="MS">Mississippi</option>
																<option value="MO">Missouri</option>
																<option value="MT">Montana</option>
																<option value="NE">Nebraska</option>
																<option value="NV">Nevada</option>
																<option value="NH">New Hampshire</option>
																<option value="NJ">New Jersey</option>
																<option value="NM">New Mexico</option>
																<option value="NY">New York</option>
																<option value="NC">North Carolina</option>
																<option value="ND">North Dakota</option>
																<option value="OH">Ohio</option>
																<option value="OK">Oklahoma</option>
																<option value="OR">Oregon</option>
																<option value="PA">Pennsylvania</option>
																<option value="RI">Rhode Island</option>
																<option value="SC">South Carolina</option>
																<option value="SD">South Dakota</option>
																<option value="TN">Tennessee</option>
																<option value="TX">Texas</option>
																<option value="UT">Utah</option>
																<option value="VT">Vermont</option>
																<option value="VA">Virginia</option>
																<option value="WA">Washington</option>
																<option value="WV">West Virginia</option>
																<option value="WI">Wisconsin</option>
																<option value="WY">Wyoming</option>
															</Form.Control>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Zip Code</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="number" 
																name='contactZipCode' 
																value={this.state.contactZipCode}
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Email</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="email" 
																name='contactEmail' 
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row} className="" >
														<Col md={4}>
															<Form.Label className="formCardTitle">Website</Form.Label>
														</Col>
														<Col md={8}>
															<Form.Control 
																type="url" 
																name='contactWebsite' 
																onChange={this._handleInput}
																/>
														</Col>
													</Form.Group>
													<Form.Group as={Row}>
														<Col sm={{ span: 10 }}>
															<Button type="submit">Save</Button>
														</Col>
													</Form.Group>
												</Form>
											</Modal.Body>
											<Modal.Footer>
												<Button variant="secondary" className="white-btn"  onClick={this.handleCloseAddContact}>
													Close
												</Button>
											</Modal.Footer>
										</Modal>
									</div>
							
		} else {
			contactsDisplay = <div></div>
		} 

		const loadImage = this.state.loadImage;
		let imageDisplay;

		if (loadImage) {
			imageDisplay = 	<div className="img_container">
								<ImageGallery 
									items={this.state.imageGallery} 
									showBullets={true}
									ref={i => this.refImgHome = i}
									renderCustomControls={this.renderImageDelete}
								/>
							</div>
		} else {
			imageDisplay = <div className="img_container"></div>
		}

		return (
			<div className="parent-container home-container Fade">
				{loaderDisplay}
				<Container className="home_container_inner">
					<Row className="">
						<Col md={6}>
							{propertyDisplay}
						</Col>
						<Col md={6}>
							<div className="fea">
								{imageDisplay}
							</div>
						</Col>
					</Row>
					<div className="bottom_box_container">
						<Row className="bottom_box_row">
							<Col lg={4} md={6}> 
								{utilitiesDisplay}
							</Col>
							<Col lg={4} md={6}> 
								{appliancesDisplay}
							</Col>
							<Col lg={4} md={6}> 
								{contactsDisplay}
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		);
  	}
}

export default Home;