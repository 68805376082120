import React, { Component } from "react";
import "./css/Register.css";
import new_logo_png from "./images/HH_Logo_w_Name.png";

import { Container,Col,Row,Button,Form, Alert} from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
// import dateFormat from 'dateformat';
import moment from 'moment';
import Stepper from 'react-stepper-horizontal';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement} from '@stripe/react-stripe-js';
// import {PaymentElement} from '@stripe/react-stripe-js';
import {ElementsConsumer} from "@stripe/react-stripe-js";
import { useAuth0 } from "@auth0/auth0-react";
import history from "../history";
// import { withRouter } from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import LogoutStartover from './LogoutStartover.js';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
LogRocket.init('mafvxv/home-history');
setupLogRocketReact(LogRocket);
/* global google */

class RegisterComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show           : false,
			error          : null,
			userName       : "",
			password       : "",
			email          : "",
			firstName      : "",
			lastName       : "",
			dateOfBirth    : "",
			address1       : "",
			address2       : "",
			city           : "",
			zipCode        : "",
			state          : "",
			auth           : "",
			message        : "",
			imageMessage   : "",
			switchToCreate  : false,
			isLoading       : false,
			loadPayment     : false,
			loadProperty    : false,
			loadRegister    : false,
			step_1_complete : false,
			step_2_complete : false,
			propertyId           : '', 
			propertyDescription  : '', 
			propertyPhone        : '', 
			propertyAddress1     : '', 
			propertyAddress2     : '', 
			propertyCity         : '', 
			propertyZipCode      : '', 
			propertyState        : '', 
			propertyPurchaseDate : '', 
			propertyYearBuilt    : '', 
			propertyRoomCt       : '', 
			propertyBathCt       : '',
			showPropertyEdit     : false,
			propertyFootage      : 0,
			propertyImage        : '',
			propertyHOA          : '',
			propertyRoof         : '',
			step_1_off           : false,
			step_2_off           : true,
			step_3_off           : true, 
			currentStep          : 0,
			sub_type             : '',
			showAlert            : false,
			showAlertExisting    : false,
			showImageAlert       : false,
			displaySubMessage    : false,
			sub_message       : '',
			userId            : '',
			formFile          : '',
			fileName          : '',
			propertyImagePath : '',
			loadStripeDisplay : false,
			user_email        : '',
			stripeEmail       : '', 
			userPaymentStatus : '',
			showLoader        : false,
			emailMessage      : '',
			showEmailMessage  : false,
			promoCode         : '',
			promo_message     : '',
			displayPromoMessage : false,
			promoColor : '',
			showPromo  : false,
			promoRedirect : false
		};

		this.postUserCreate        = this.postUserCreate.bind(this);
		this._handleInput          = this._handleInput.bind(this);
		// this._handleInputEmail     = this._handleInputEmail.bind(this);
		this.addProperty           = this.addProperty.bind(this);
		this.retrieveToken         = this.retrieveToken.bind(this);
		this.createStripeCustomer  = this.createStripeCustomer.bind(this);
		// this.displayError          = this.displayError.bind(this);
		this.createPaymentMethod   = this.createPaymentMethod.bind(this);
		this.updateUserToken       = this.updateUserToken.bind(this);
		this.callLoginRedirect     = this.callLoginRedirect.bind(this);
		this.sendToken             = this.sendToken.bind(this);
		this.freeTrialLogin        = this.freeTrialLogin.bind(this);
		this.setSelectedImage      = this.setSelectedImage.bind(this);
		this.postPropertyImage     = this.postPropertyImage.bind(this);
		this.getPropertiesByUser   = this.getPropertiesByUser.bind(this);
		this.getUserData           = this.getUserData.bind(this);
		this.initGooglePlaces      = this.initGooglePlaces.bind(this);
		this.handlePlaceSelect     = this.handlePlaceSelect.bind(this);
		this.autocomplete          = null;
		this.autocompleteInput     = React.createRef();
		this.goToHome              = this.goToHome.bind(this);
		this.callLoginRedirectExisting = this.callLoginRedirectExisting.bind(this);
		this.setSubTypeDisplay         = this.setSubTypeDisplay.bind(this);
		this.getUserId                 = this.getUserId.bind(this);
		this.postPromoCode             = this.postPromoCode.bind(this);
		this.showPromoSection          = this.showPromoSection.bind(this);
		this.goToYearly                = this.goToYearly.bind(this);
	}

	componentDidMount(){
		localStorage.setItem("redirect_url", '/register');
		localStorage.setItem("previous_page", 'register');
		this.retrieveToken();
		// console.log(this.props.match && this.props.match.params);
		// set Interval
		setTimeout(
			function() {
				this.loadInitial();
			}
			.bind(this),
			500
		);
	}

	loadInitial() {
		var userId     = localStorage.getItem('userId');
		var user_email = localStorage.getItem('user_email');
		var sub_type   = localStorage.getItem('sub_type');
		this.setState({
			message    : "",
			userId     : userId,
			user_email : user_email, 
			sub_type   : sub_type,
		});
		this.setSubTypeDisplay();

		if (userId !== null){
			this.getUserData(userId);
		}
		
		// this.getPropertiesByUser();
	};

	showPromoSection(){
		this.setState({
			showPromo : true,
		});
	}

	getUserId(token){
		var userId = null;
		axios.post(process.env.REACT_APP_BASE_URL+"Users/Auth")
		.then(res => {
			const user_data = res.data.result;
			// console.log('in new user function: ' , user_data);
			userId = user_data.userId;
			this.setState({
				userId     : user_data.userId,
				user_email : user_data.user_email, 
			});

			var logged_in = sessionStorage.getItem("logged_in");
			if (logged_in !== 'true'){
				// console.log('updating the token');
				this.updateUserToken(token, userId);
				this.getPropertiesByUser();
				sessionStorage.setItem("token", token);
			}
		}).catch((err) => {
			// console.log('token doesnt match');
			if (err.response) {
				// console.log("error.Response.status", err.response.status);
				switch (err.response.status) {
					case 401:
						// console.log('in 401: ', this.state.userId);
						// this.callLoginRedirect();
						// this.callLoginRedirectExisting();
						// this.callLoginRedirect_zt();
						
						if (!this.state.userId){
							// const { loginWithRedirect } = this.props.auth0;
							localStorage.setItem("redirect_url", '/');
							localStorage.setItem("previous_page", '');
							var confirm = window.confirm('This email address is already registered, please return to the home page and click login.');
                            if (confirm) {

								localStorage.clear();
								sessionStorage.clear();
								sessionStorage.setItem("logged_in", 'false');
								localStorage.setItem("redirect_url", '/');

								// const { logout } = useAuth0();
								// logout({ returnTo: '/' })
                                history.push('/');

                            }

							// loginWithRedirect({
							// 	appState: { 
							// 		delegate : this.state.email,
							// 		returnTo : '/' 
							// 	},
							// 	screen_hint  : "signup",
							// 	login_hint   : this.state.email,
							// 	returnTo     : '/', 
							// 	redirect_uri : '/'
							// })

						} else {

							this.setState({
								step_1_complete : false,
								step_1_off      : false,
								step_2_off      : true,
								step_3_off      : true,
								currentStep     : 0,
								loadProperty    : false,
								loadStripeDisplay : false,
								loadRegister    : true
							});
						}
						
					break;
					default:
						this.setState({
							showAlert : true,
							message   : "There was an error - please try again!",
						});
					localStorage.setItem("redirect_url", '/');
					localStorage.setItem("previous_page", '');
					return (history.push('/'));
				}
			}
		})
	}

	setSubTypeDisplay(){
		if(this.state.sub_type === 'daily'){
			this.setState({
				sub_message       : "Daily Subscription: Test",
				displaySubMessage : true
			});

		} else if(this.state.sub_type === 'monthly'){
			this.setState({
				sub_message       : "Monthly Subscription: $3/month",
				displaySubMessage : true
			});

		} else if(this.state.sub_type === 'yearly'){
			this.setState({
				sub_message       : "Yearly Subscription: $15/year",
				displaySubMessage : true
			});

		} else if(this.state.sub_type === 'unlimited'){
			this.setState({
				sub_message       : "Lifetime Subscription: $125",
				displaySubMessage : true
			});
		}
	}

	initGooglePlaces(){
		// console.log('this is happening initGooglePlaces');

		this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
			{"types": ["geocode"]});
	
		this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
	}

	handlePlaceSelect() {
		let addressObject = this.autocomplete.getPlace()
		// console.log('the address: ', addressObject)
		let address = addressObject.address_components;

		var addressDisplay = `${address[0].long_name} ${address[1].long_name}`;
		var city    = address[2].long_name;
		var state   = address[4].short_name;
		var zipCode = address[6].short_name;

		if (addressDisplay.length === 0){
			addressDisplay = '';
		}
		if (city.length === 0){
			city = '';
		}
		if (state.length === 0){
			state = '';
		}
		if (zipCode.length === 0){
			zipCode = '';
		}

		this.setState({
			propertyAddress1 : addressDisplay,
			propertyCity     : city,
			propertyState    : state,
			propertyZipCode  : zipCode,
		});
	}

	getUserData(userId){
		// console.log('another user id : ', userId)
		this.setState({ 
			showLoader : true,
		});
		try{
			
			axios.get(process.env.REACT_APP_BASE_URL+"Users/" + userId)
			.then(res => {
				const user_data = res.data.result;
				// console.log('user result ', user_data);
			
				this.setState({ user_data });
				// console.log('hey',this.state)
				if(user_data.userPaymentStatus === 'Ok'){
					this.setState({
						step_2_complete   : true,
						currentStep       : 2,
						loadProperty      : true,
						loadStripeDisplay : false,
						loadRegister      : false,
						step_1_off        : true,
						step_2_off        : true,
						step_3_off        : false,
						userPaymentStatus : 'Ok',
						userId            : user_data.userId
					});

					if(this.state.sub_type === 'daily'){
						this.setState({
							sub_message       : "Daily Subscription: Test - payment complete",
							displaySubMessage : true
						});
			
					} else if(this.state.sub_type === 'monthly'){
						this.setState({
							sub_message       : "Monthly Subscription: $3/month - payment complete",
							displaySubMessage : true
						});
			
					} else if(this.state.sub_type === 'yearly'){
						this.setState({
							sub_message       : "Yearly Subscription: $15/year - payment complete",
							displaySubMessage : true
						});
			
					} else if(this.state.sub_type === 'unlimited'){
						this.setState({
							sub_message       : "Lifetime Subscription: $125 - payment complete",
							displaySubMessage : true
						});
					}
					this.setState({ 
						showLoader : false,
					});
					this.getPropertiesByUser();
				} else if (user_data.userPaymentStatus === 'Bad'){
					this.setState({
						step_2_complete   : false,
						currentStep       : 1,
						loadProperty      : false,
						loadStripeDisplay : true,
						loadRegister      : false,
						step_1_off        : true,
						step_2_off        : false,
						step_3_off        : true,
						userPaymentStatus : 'Bad',
						step_1_complete : true,
					});

					if(this.state.sub_type === 'daily'){
						this.setState({
							sub_message       : "Daily Subscription: Test - payment incomplete",
							displaySubMessage : true
						});
			
					} else if(this.state.sub_type === 'monthly'){
						this.setState({
							sub_message       : "Monthly Subscription: $3/month - payment incomplete",
							displaySubMessage : true
						});
			
					} else if(this.state.sub_type === 'yearly'){
						this.setState({
							sub_message       : "Yearly Subscription: $15/year - payment incomplete",
							displaySubMessage : true
						});
			
					} else if(this.state.sub_type === 'unlimited'){
						this.setState({
							sub_message       : "Lifetime Subscription: $125 - payment incomplete",
							displaySubMessage : true
						});
					}
					this.setState({ 
						showLoader : false,
					});
					this.getPropertiesByUser();
				}

				LogRocket.identify(userId, {
					name : user_data.userName,
					email: user_data.email,
				  
					// Add your own custom user variables here, ie:
					subscriptionType: this.state.sub_type
				});
			});
		} catch(ex){
			console.log('getUserData exception : ', ex);
		}
	}

	// displayError(event) {
	// 	let displayError = document.getElementById('card-element-errors');
	// 	if (event.error) {
	// 		displayError.textContent = event.error.message;
	// 		this.setState({
	// 			showAlert : true,
	// 			message   : event.error.message,
	// 		});
	// 	} else {
	// 		displayError.textContent = '';
	// 		this.setState({
	// 			showAlert : false,
	// 			message   : '',
	// 		});
	// 	}
	// }

	createStripeCustomer(e) {

		e.preventDefault();

		let billingEmail = this.state.stripeEmail;
		try{
			axios
			.post(process.env.REACT_APP_BASE_URL+"Payments/create-customer", {
				email: billingEmail,
			})
			.then((response) => {
				// console.log('stripe email response : ', response);
				// return response.json();
			})
			.then((result) => {
				// result.customer.id is used to map back to the customer object
				this.setState({
					stripeCustomerId : result.customer.id
				});
				return result;
			});
		} catch(ex){
			console.log('createStripeCustomer exception : ', ex);
		}
	}

	setSelectedImage(e){
		// console.log('file', file);
		// console.log('file', file.name);
		// let name  = e.target.name;
		let value = e.target.value;

		this.setState({ 
			propertyImage  : value,
			formFile       : e.target.files[0],
			fileName       : e.target.files[0].name,
			showImageAlert : false,
		});
	}

	addProperty(e){
		this.setState({ 
			showLoader : true,
		});
		e.preventDefault();

		var userId               = this.state.userId;
		var propertyDescription  = this.state.propertyDescription;
		var propertyPhone        = this.state.propertyPhone;
		var propertyAddress1     = this.state.propertyAddress1; 
		var propertyAddress2     = this.state.propertyAddress2; 
		var propertyCity         = this.state.propertyCity;
		var propertyZipCode      = this.state.propertyZipCode; 
		var propertyState        = this.state.propertyState; 
		var propertyYearBuilt    = this.state.propertyYearBuilt; 
		var propertyPurchaseDate = this.state.propertyPurchaseDate; 
		var propertyRoomCt       = this.state.propertyRoomCt; 
		var propertyBathCt       = this.state.propertyBathCt;
		var propertyImage        = this.state.propertyImage;
		var propertyFootage      = this.state.propertyFootage;
		var propertyDefault      = 1;
		var propertyHOA          = this.state.propertyHOA;
		var propertyRoof         = this.state.propertyRoof;

		// console.log('state in property put ', this.state)
	
		try{
			axios.put(process.env.REACT_APP_BASE_URL+'Properties/' + this.state.propertyId, { 
				userId       : userId,
				description  : propertyDescription,
				phoneNumber  : propertyPhone, 
				address1     : propertyAddress1, 
				address2     : propertyAddress2, 
				city         : propertyCity, 
				zipCode      : propertyZipCode, 
				state        : propertyState, 
				yearBuilt    : propertyYearBuilt, 
				purchaseDate : propertyPurchaseDate, 
				roomCt       : propertyRoomCt,
				bathCt       : propertyBathCt,
				is_Default   : propertyDefault,
				footage      : propertyFootage,
				roof_updated : propertyRoof,
				hoa          : propertyHOA
			})
			.then((res) => {
				// console.log('we created a property', result);
				const property_response = res.data.result;

				var value = $('.propertyEditImage').val();
				if (value.length < 3) {
					this.setState({
						propertyImage : '',
						formFile      : '',
						fileName      : ''
					})
					// window.location = "/home/user=" + this.state.userId;
					this.setState({ 
						showLoader : false,
					});
					history.push("/home/user=" + this.state.userId);
				} else if (this.state.propertyImage){
					// console.log(e.target.files[0]);
					// console.log('image: ', propertyImage);
					this.postPropertyImage(e)
				}
				localStorage.setItem("redirect_url", '/');
				localStorage.setItem("previous_page", '');
			});
		} catch(ex){
			console.log('addProperty exception : ', ex);
		}
	}

	getPropertiesByUser(){

		try{
			axios.get(process.env.REACT_APP_BASE_URL+"properties/user/" + this.state.userId)
			.then(res => {
				const properties = res.data.result;
				// console.log('properties register : ', properties);
				if (properties.length > 0) {
					
					this.setState({ 
						propertyId : properties[0].id, 
					});
					// console.log('this is state: ', this.state)
				}
			});
		} catch(ex){
			console.log('getPropertiesByUser exception : ', ex);
		}
	}

	postPropertyImage(e) {
		e.preventDefault();

		// console.log(this.state.formFile);
		// console.log(this.state.fileName);
		var file = this.state.formFile;
		// console.log('file size: ', file);

		var upload = true;

		if (file.size > 15000000){
			
			upload = false;
			this.setState({ 
				showLoader : false,
				showImageAlert : true,
				imageMessage   : 'This file is too large. Please choose a smaller file.'
			});
		}

		if (!file.name.match(/.(jpg|jpeg|png|gif)$/i)){
			upload = false;
			this.setState({ 
				showLoader: false,
				showImageAlert : true,
				imageMessage   : 'Please choose an image file.'
			});
		}

		if (upload){

			const formData = new FormData();

			formData.append('formFile', this.state.formFile);
			formData.append('fileName', this.state.fileName);
			// console.log('state in image: ', this.state)
			var propertyId = this.state.propertyId;

			try{
				axios.post(process.env.REACT_APP_BASE_URL+'properties/image/'+propertyId, formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(res => {
					// console.log('image result ', res);
					const image_response = res.data.result;
					// console.log("image response : ", image_response)
					if (image_response) {
						this.setState({ 
							propertyImagePath : image_response.path 
						});
						// console.log('property image path: ', this.state.propertyImagePath)
					}
					this.setState({ 
						showLoader : false,
					});
					// window.location = "/home/user=" + this.state.userId;
					history.push("/home/user=" + this.state.userId);
				});
			} catch(ex){
				console.log('savefile exception : ', ex);
			}
		}
	};

	// todo: add user get on initial load, if works, set user stuff
	// check if user is paid, chain property get to property put 

	createPaymentMethod(e) {
		
		e.preventDefault();
		const {stripe, elements} = this.props;
		stripe.createToken(elements.getElement(CardElement))
		// stripe.createToken(elements.getElement('payment'))
		
		.then((result) => {
			this.setState({ 
				showLoader : true,
			});
			if (result.error) {
				// console.log('this is the create payment response', result)
				// this.displayError(result);
				this.setState({
					showAlert : true,
					message: result.error,
					showLoader : false
				});
			} else {
				// console.log('this is the create payment response', result)
				// console.log(result.token.id);
				this.sendToken(result.token.id);
				this.setState({
					showAlert : false,
					message: '',
				});
			}
		});
	}

	postPromoCode(e){
		e.preventDefault();
		try{

			this.setState({ 
				showLoader : true,
			});

			axios
			.post(process.env.REACT_APP_BASE_URL+"Payments/coupon-avail", {
				email     : this.state.user_email,
				promoCode : this.state.promoCode,
				planType  : this.state.sub_type
			})
			.then((res) => {
				// console.log("our promo response", res.data.result);
				var promo_res = res.data.result;

				if (promo_res.couponIsAvailable){
					// console.log('in promo true');

					if(this.state.sub_type === 'daily'){
						this.setState({
							promo_message       : "Daily Subscription: Test - payment complete",
							displayPromoMessage : true,
							promoColor : 'success'
						});
			
					} else if(this.state.sub_type === 'monthly'){
						this.setState({
							promo_message       : "Promo Code Successful, your card will be charged $0",
							displayPromoMessage : true,
							promoColor : 'success'
						});
			
					} else if(this.state.sub_type === 'yearly'){
						this.setState({
							promo_message       : "Promo Code Successful, your card will be charged $0",
							displayPromoMessage : true,
							promoColor : 'success'
						});
			
					} else if(this.state.sub_type === 'unlimited'){
						this.setState({
							promo_message       : "Promo Code Successful, your card will be charged $0",
							displayPromoMessage : true,
							promoColor : 'success'
						});
					}
					this.setState({ 
						showLoader : false,
					});

				} else {
					if (promo_res.message === 'invalidPlan'){
						this.setState({
							promo_message       : "This promo code does not match your selected subscription plan, click below to select the plan that corresponds to your promo code.",
							displayPromoMessage : true,
							promoColor : 'danger',
							promoRedirect : true
						});

					} else if (promo_res.message === 'userError'){
						this.setState({
							promo_message       : "Registration Error, please try again",
							displayPromoMessage : true,
							promoColor : 'danger'
						});

					} else if (promo_res.message === 'prevUsed'){
						this.setState({
							promo_message       : "This promo code has already been used.",
							displayPromoMessage : true,
							promoColor : 'danger'
						});
					} else if (promo_res.message === 'invalidCode'){
						this.setState({
							promo_message       : "Promo Code Invalid",
							displayPromoMessage : true,
							promoColor : 'danger'
						});
					} else {
						this.setState({
							promo_message       : "Promo Code Invalid",
							displayPromoMessage : true,
							promoColor : 'danger'
						});
					}
					// console.log('in promo false');
					// if(this.state.sub_type === 'daily'){
					// 	this.setState({
					// 		promo_message       : "Daily Subscription: Test - payment incomplete",
					// 		displayPromoMessage : true,
					// 		promoColor : 'danger'
					// 	});
			
					// } else if(this.state.sub_type === 'monthly'){
					// 	this.setState({
					// 		promo_message       : "Promo Code Invalid",
					// 		displayPromoMessage : true,
					// 		promoColor : 'danger'
					// 	});
			
					// } else if(this.state.sub_type === 'yearly'){
					// 	this.setState({
					// 		promo_message       : "Promo Code Invalid",
					// 		displayPromoMessage : true,
					// 		promoColor : 'danger'
					// 	});
			
					// } else if(this.state.sub_type === 'unlimited'){
					// 	this.setState({
					// 		promo_message       : "Promo Code Invalid",
					// 		displayPromoMessage : true,
					// 		promoColor : 'danger'
					// 	});
					// }
					this.setState({ 
						showLoader : false,
					});
				}
			
			}).catch((err) => {

				if(this.state.sub_type === 'daily'){
					this.setState({
						promo_message       : "Daily Subscription: Test - payment incomplete",
						displayPromoMessage : true,
						promoColor : 'danger'
					});
		
				} else if(this.state.sub_type === 'monthly'){
					this.setState({
						promo_message       : "Error, please try again",
						displayPromoMessage : true,
						promoColor : 'danger'
					});
		
				} else if(this.state.sub_type === 'yearly'){
					this.setState({
						promo_message       : "Error, please try again",
						displayPromoMessage : true,
						promoColor : 'danger'
					});
		
				} else if(this.state.sub_type === 'unlimited'){
					this.setState({
						promo_message       : "Error, please try again",
						displayPromoMessage : true,
						promoColor : 'danger'
					});
				}
				this.setState({ 
					showLoader : false,
				});
			})
		} catch(ex){
			console.log('postPromoCode exception : ', ex);
		}
	}

	sendToken(token){
		try{
			// let priceId = 'price_1Iq4OVBFuLUmdTYNxXbUtv2N';
			// console.log('calling the send token function', this.state.user_email)

			// TODO: daily for testing, monthly or yearly for prod
			axios
			.post(process.env.REACT_APP_BASE_URL+"Payments/Subscribe?plan="+this.state.sub_type+"&promoCode="+this.state.promoCode, {
				email       : this.state.user_email,
				stripeToken : token,
				// promoCode   : this.state.promoCode
			})
			.then((res) => {
				console.log("our stripe response", res.data.result);
				var stripe_res = res.data.result;

				if (stripe_res.userPaymentStatus === 'Ok'){
					this.setState({
						step_2_complete : true,
						currentStep     : 2,
						loadProperty    : true,
						loadStripeDisplay : false,
						loadRegister    : false,
						step_1_off      : true,
						step_2_off      : true,
						step_3_off      : false,
						userPaymentStatus : stripe_res.userPaymentStatus
					});

					if(this.state.sub_type === 'daily'){
						this.setState({
							sub_message       : "Daily Subscription: Test - payment complete",
							displaySubMessage : true
						});
			
					} else if(this.state.sub_type === 'monthly'){
						this.setState({
							sub_message       : "Monthly Subscription: $3/month - payment complete",
							displaySubMessage : true
						});
			
					} else if(this.state.sub_type === 'yearly'){
						this.setState({
							sub_message       : "Yearly Subscription: $15/year - payment complete",
							displaySubMessage : true
						});
			
					} else if(this.state.sub_type === 'unlimited'){
						this.setState({
							sub_message       : "Lifetime Subscription: $125 - payment complete",
							displaySubMessage : true
						});
					}
					this.setState({ 
						showLoader : false,
					});
				} 
			
			}).catch((err) => {
			
				// if (err.response) {
				// console.log('stripe payment error');

				if(this.state.sub_type === 'daily'){
					this.setState({
						sub_message       : "Daily Subscription: Test - payment incomplete",
						displaySubMessage : true
					});
		
				} else if(this.state.sub_type === 'monthly'){
					this.setState({
						sub_message       : "Monthly Subscription: $3/month - payment incomplete",
						displaySubMessage : true
					});
		
				} else if(this.state.sub_type === 'yearly'){
					this.setState({
						sub_message       : "Yearly Subscription: $15/year - payment incomplete",
						displaySubMessage : true
					});
		
				} else if(this.state.sub_type === 'unlimited'){
					this.setState({
						sub_message       : "Lifetime Subscription: $125 - payment incomplete",
						displaySubMessage : true
					});
				}
				this.setState({ 
					showLoader : false,
				});
			})
		
		} catch(ex){
			console.log('sendToken exception : ', ex);
		}
	}

	_handleInput(event) {
		let obj  = {};
		let key  = event.target.name;
		obj[key] = event.target.value;
		this.setState(obj);
	}

	// _handleInputEmail(event) {

	// 	var validator = require('email-validator');
	// 	var valid = validator.validate(event.target.value);
	// 	if (valid){
	// 		console.log('valid email');
	// 		let obj  = {};
	// 		let key  = event.target.name;
	// 		obj[key] = event.target.value;
	// 		this.setState(obj);
	// 		this.setState({
	// 			emailMessage     : '',
	// 			showEmailMessage : false
	// 		})
	// 	} else {
	// 		console.log('invalid email');
	// 		this.setState({
	// 			emailMessage     : 'Please enter a valid email address.',
	// 			showEmailMessage : true
	// 		})
	// 	}
		
	// }

  	postUserCreate(e) {

		e.preventDefault();

		var email       = this.state.email;
		var firstName   = this.state.firstName;
		var lastName    = this.state.lastName;
		var dateOfBirth = this.state.dateOfBirth;
		var zipCode     = this.state.zipCode;

		var validator = require('email-validator');
		var valid     = validator.validate(email);
		if (!valid){
			// console.log('not valid email');
			alert('Please enter a valid email address');
		} else {
			// console.log('valid email');
			this.setState({ isLoading: true });

			this.setState({ 
				showLoader : true,
			});
			try{

				axios
				.post(process.env.REACT_APP_BASE_URL+"Users/Register", {
					email       : email,
					firstName   : firstName,
					lastName    : lastName,
					dateOfBirth : dateOfBirth,
					planType    : this.state.sub_type,
					ZipCode     : zipCode
				})
				.then((res) => {
					// hit auth0
					var user_data = res.data.result;
					// console.log('the user data', user_data);
					this.setState({
						userId          : user_data.userId,
						user_email      : user_data.email,
						isAuthenticated : true,
					});

					localStorage.setItem("userId", user_data.userId);
					sessionStorage.setItem("userID", user_data.userId);
					localStorage.setItem("user_email", user_data.email);

				}).then(() => {
					
					this.callLoginRedirect();
				})
				.catch((err) => {
					this.setState({ isLoading: false });
					if (err.response) {
						// console.log("error.Response.status", err.response.status);
						switch (err.response.status) {
							case 409:
								this.setState({
									showAlertExisting : true,
									message : "This email has already been registered. Please login...",
								});
						
							break;
							case 400:
								this.setState({
									showAlert : true,
									message   : "This username is not available. Please try again...",
								});
							break;
							// case 423:
							// // return (window.location.href = "/thanks");
							// return (history.push('/thanks'));
							default:
								this.setState({
									showAlert : true,
									message   : "There was an error - please try again!",
								});
						}
						this.setState({ 
							showLoader : false,
						});
					}
				});
			} catch(ex){
				console.log('postUserCreate exception : ', ex);
			}
		}
	}
	
	async callLoginRedirect() {

		try {
			const { loginWithRedirect } = this.props.auth0;
			// console.log('auth0 login: ', this.props.auth0);
			// console.log('in login redirect section');

			// var userId     = sessionStorage.getItem("userId");
			var user_email = localStorage.getItem("user_email");
			
			await loginWithRedirect({
				appState: { 
					delegate : user_email,
					returnTo : '/register' 
				},
				screen_hint  : "signup",
				login_hint   : user_email,
				returnTo     : '/register', 
				redirect_uri : '/register'
			})
			this.setState({ 
				showLoader : false,
			});
		} catch (e) {
			console.error(e);
		}
	}

	async callLoginRedirectExisting() {

		try {
			const { loginWithRedirect } = this.props.auth0;
			// console.log('auth0 login: ', this.props.auth0);
			// console.log('in login redirect section');
			localStorage.setItem("redirect_url", '/');
			localStorage.setItem("previous_page", '');
			
			await loginWithRedirect({
				appState: { 
					delegate : this.state.email,
					returnTo : '/' 
				},
				screen_hint  : "signup",
				login_hint   : this.state.email,
				returnTo     : '/', 
				redirect_uri : '/'
			})
			
		} catch (e) {
			console.error(e);
		}
	}

	async retrieveToken() {
		this.setState({ 
			showLoader : true,
		});
		var token = '';
		try {
			const { getAccessTokenSilently } = this.props.auth0;
			token = await getAccessTokenSilently();
			// console.log('the token: ', token);
			
		} catch (ex) {
			console.error( 'error retrieveToken', ex);
		}

		if (token.length > 0) {
			// console.log('have token register');
			

			if(this.state.userPaymentStatus === 'Ok'){
				// console.log('in it');
				this.setState({
					step_2_complete : true,
					currentStep     : 2,
					loadProperty    : true,
					loadStripeDisplay : false,
					loadRegister    : false,
					step_1_off      : true,
					step_2_off      : true,
					step_3_off      : false,
					userPaymentStatus : this.state.userPaymentStatus,
				});

				if(this.state.sub_type === 'daily'){
					this.setState({
						sub_message       : "Daily Subscription: Test - payment complete",
						displaySubMessage : true
					});
		
				} else if(this.state.sub_type === 'monthly'){
					this.setState({
						sub_message       : "Monthly Subscription: $3/month - payment complete",
						displaySubMessage : true
					});
		
				} else if(this.state.sub_type === 'yearly'){
					this.setState({
						sub_message       : "Yearly Subscription: $15/year - payment complete",
						displaySubMessage : true
					});
		
				} else if(this.state.sub_type === 'unlimited'){
					this.setState({
						sub_message       : "Lifetime Subscription: $125 - payment complete",
						displaySubMessage : true
					});
				}
				// console.log('hitting 928')
				this.getPropertiesByUser();
				$('.PropertyEditForm').show();
			} else {
				this.setState({
					step_1_complete : true,
					step_1_off      : true,
					step_2_off      : false,
					step_3_off      : true,
					currentStep     : 1,
					loadProperty    : false,
					loadStripeDisplay : true,
					loadRegister      : false,
					userPaymentStatus : this.state.userPaymentStatus,
				});

				if (this.state.userPaymentStatus === 'Bad'){
					if(this.state.sub_type === 'daily'){
						this.setState({
							sub_message       : "Daily Subscription: Test - payment incomplete",
							displaySubMessage : true
						});
			
					} else if(this.state.sub_type === 'monthly'){
						this.setState({
							sub_message       : "Monthly Subscription: $3/month - payment incomplete",
							displaySubMessage : true
						});
			
					} else if(this.state.sub_type === 'yearly'){
						this.setState({
							sub_message       : "Yearly Subscription: $15/year - payment incomplete",
							displaySubMessage : true
						});
			
					} else if(this.state.sub_type === 'unlimited'){
						this.setState({
							sub_message       : "Lifetime Subscription: $125 - payment incomplete",
							displaySubMessage : true
						});
					}
				}
			}
			// this.redirectLoggedIn(token);
			var userId = localStorage.getItem("userId");
			if (userId == null){
				this.getUserId(token);
			} else {
				// var user_email = sessionStorage.getItem("user_email");
				var logged_in = sessionStorage.getItem("logged_in");
				if (logged_in !== 'true'){
					// console.log('updating the token');
					this.updateUserToken(token, userId);
					this.getPropertiesByUser();
					sessionStorage.setItem("token", token);
				}
			}
			this.setState({ 
				showLoader : false,
			});
		} else {
			// console.log('no token register');
			this.setState({
				step_1_complete : false,
				step_1_off      : false,
				step_2_off      : true,
				step_3_off      : true,
				currentStep     : 0,
				loadProperty    : false,
				loadStripeDisplay : false,
				loadRegister    : true,
				showLoader      : false
			});
		}
	}

	updateUserToken(token, userId){
		try{
			// console.log('userid: ', userId);
			// var userId = userId;
			// console.log('token: ', token);
			axios.post(process.env.REACT_APP_BASE_URL+'Users/register-auth', { 
				userId    : userId,
				authToken : token
			})
			.then((res) => {
				// console.log('update the user with the token');
				sessionStorage.setItem("logged_in", 'true');
			});
		} catch(ex){
			console.log('updateUserToken exception : ', ex);
		}
	}

	goToHome(){
		localStorage.setItem("redirect_url", '/');
		localStorage.setItem("previous_page", '');
		// window.location = "/";
		history.push('/');
	}
	goToYearly(){
		localStorage.setItem('sub_type', 'yearly');
		window.location = '/register/sub_type=yearly'
		// history.push('/register/sub_type=yearly');
	}

	freeTrialLogin(){
		console.log('free trial button clicked')
	}

	render() {
		// let { hasMesssage }     = this.state.message;
		// let { step_1_complete } = this.state.step_1_complete;
		// let { step_2_complete } = this.state.step_2_complete;
		// let switchForm;

		const showLoader = this.state.showLoader;
		
		let loaderDisplay;

		if (showLoader) {
			loaderDisplay = 
						<div className="loader_container">
							<Loader
								type="Oval"
								color="#5FCE7F"
								height={100}
								width={100}
								timeout={10000} //10 secs
								visible={this.state.showLoader}
							/>
						</div>
		} else {
			loaderDisplay = "";
		}

		const loadProperty = this.state.loadProperty;
		let propertyDisplay;

		const loadRegister = this.state.loadRegister;
		let registerDisplay;

		const loadStripeDisplay   = this.state.loadStripeDisplay;
	
		let stripeDisplay;

		const showAlert = this.state.showAlert;
		const showAlertExisting = this.state.showAlertExisting;
		let alertDisplay;

		const showImageAlert = this.state.showImageAlert;
		
		let alertImageDisplay;

		if (showImageAlert) {
			alertImageDisplay = 	
							<Row>
								<Col md={1}></Col>
								<Col md={5}>
									<Alert variant="danger" style={{ width: "26rem" }}>
										{/* <Alert.Heading> */}
											{this.state.imageMessage}
										{/* </Alert.Heading> */}
									</Alert>
								</Col>
								<Col md={6}></Col>
							</Row>;
							
		} else {
			alertImageDisplay = "";
		}

		const showPriceAlert = this.state.displaySubMessage;
		
		let alertPriceDisplay;

		if (showPriceAlert) {
			alertPriceDisplay = 	
								<Alert variant="primary" className="sub_alert" style={{ width: "26rem" }}>
									{/* <Alert.Heading> */}
										{this.state.sub_message}
									{/* </Alert.Heading> */}
								</Alert>;
							
		} else {
			alertPriceDisplay = "";
		}

		const showPromoRedirect = this.state.promoRedirect;
		
		let promoRedirectDisplay;

		if (showPromoRedirect) {
			promoRedirectDisplay = <div><br /><a href="#" onClick={this.goToYearly} className="home_link" >Click Here</a></div>;
										
		} else {
			promoRedirectDisplay = "";
		}
		
		const showPromoAlert = this.state.displayPromoMessage;
		
		let alertPromoDisplay;

		if (showPromoAlert) {
			alertPromoDisplay = 	
								<Alert variant={this.state.promoColor} className="sub_alert" style={{ width: "26rem" }}>
									{/* <Alert.Heading> */}
										{this.state.promo_message}
									{/* </Alert.Heading> */}
									{promoRedirectDisplay}
								</Alert>;
							
		} else {
			alertPromoDisplay = "";
		}

		const StripeForm = ({ stripe, elements }) => {
		
			return (
				// {this.state.step_1_complete ? () : ()}
				<Form
					// className="formCard stripeForm card_off "
					className={`formCard stripeForm ${this.state.step_2_off ? "card_off" : ""}`}
					id="stripe_form"
					onSubmit={this.createPaymentMethod}
					>
					<Form.Group as={Row} >
						<Col sm={12} >
							{/* <PaymentElement */}
							<CardElement 
								className="credit_card"
								options={{
									style: {
										base: {
											backgroundColor: "white"
										} 
									},
								}}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row} >
					<Col sm={4}>
							<Button
								className="white-btn"
								type="button"
								disabled={!this.props}
								onClick={this.showPromoSection}
							>
								Promo Code
							</Button>
						</Col>
						<Col sm={4}></Col>
						<Col sm={4}>
							<Button
								className="white-btn submit_"
								type="submit"
								disabled={!this.props}
							>
								Pay Now
							</Button>
						</Col>
					</Form.Group>
				</Form>
			)
		}

		let stepsDisplay = <div className="stepper_container">
								<Stepper steps={[
													{title: 'Register'}, 
													{title: 'Payment'}, 
													{title: 'Create Property'}
												]} 
										activeStep={ this.state.currentStep } 
										defaultColor = {'#0A55A3'}
										completeColor = {'#008000'}
										size = {42}
										/>
							</div>
		if (showAlertExisting) {
			alertDisplay = 	<Alert variant="danger" style={{ width: "26rem" }}>
								{/* <Alert.Heading> */}
									{this.state.message}
									<Alert.Link href="#" onClick={this.callLoginRedirectExisting}>Click Here to Log In</Alert.Link>
								{/* </Alert.Heading> */}
							</Alert>
		} else if(showAlert){
			alertDisplay = 	<Alert variant="danger" style={{ width: "26rem" }}>
								{/* <Alert.Heading> */}
									{this.state.message}
								{/* </Alert.Heading> */}
							</Alert>;
		} else {
			alertDisplay = "";
		}

    	if (loadStripeDisplay) {
			stripeDisplay = <Col md={4}>
								<div className="payment_container">
									<Form
										className={`formCard stripePromoForm ${this.state.step_2_off ? "card_off" : ""}`}
										id="promo_form"
										onSubmit={this.postPromoCode}
										style={{display: this.state.showPromo ? 'block': 'none'}}
										>
										<Form.Group
											as={Row}
											controlId="formHorizontalPromoCode"
										>
											<Col sm={4}>
												<Form.Label>Promo Code: </Form.Label>
											</Col>
											<Col sm={8}>
												<Form.Control
													type="text"
													placeholder="code"
													name="promoCode"
													// value={this.state.promoCode}
													onChange={this._handleInput}
												/>
											</Col>
										</Form.Group>
										<Form.Group as={Row}>
											<Col sm={8}></Col>
											<Col sm={4}>
											<Button
												className="white-btn"
												type="submit"
												disabled={this.state.isLoading}
											>
												Apply Code
											</Button>
											</Col>
										</Form.Group>
									</Form>
									{this.state.displayPromoMessage ? (
										<p className="login-message">
											{alertPromoDisplay}
										</p>
										) : (
										<p></p>
									)}
									<StripeForm />
								</div>
							</Col>
					
		} else {
			stripeDisplay = "";
		}

		if (loadRegister){
			registerDisplay = 
							<Col md={4}>
								{this.state.step_1_complete ? (
									<div className="step_1" >Step 1: Register User<span className="complete_text"> - COMPLETE</span></div>
								) : (
									<div className="step_1" >Step 1: Register User</div>
								)}
								<Form
								className={`formCard createForm_register ${this.state.step_1_off ? "card_off" : ""}`}
								id="register_form"
								onSubmit={this.postUserCreate}
								>
									<Form.Group
										as={Row}
										controlId="formHorizontalEmail"
									>
										<Col sm={4}>
											<Form.Label>Email</Form.Label>
										</Col>
										<Col sm={8}>
											<Form.Control
												type="email"
												placeholder="Email"
												name="email"
												value={this.state.email}
												onChange={this._handleInput}
												required
											/>
										</Col>
									</Form.Group>
									<Form.Group
										as={Row}
										controlId="formHorizontalFirstName"
									>
										<Col sm={4}>
										<Form.Label>First Name</Form.Label>
										</Col>
										<Col sm={8}>
										<Form.Control
											type="text"
											placeholder="First Name"
											name="firstName"
											value={this.state.firstName}
											onChange={this._handleInput}
											required
										/>
										</Col>
									</Form.Group>
									<Form.Group
										as={Row}
										controlId="formHorizontalLastName"
									>
										<Col sm={4}>
										<Form.Label>Last Name</Form.Label>
										</Col>
										<Col sm={8}>
										<Form.Control
											type="text"
											placeholder="Last Name"
											name="lastName"
											value={this.state.lastName}
											onChange={this._handleInput}
											required
										/>
										</Col>
									</Form.Group>
									<Form.Group
										as={Row}
										controlId="formHorizontalDOB"
									>
										<Col sm={4}>
											<Form.Label>DOB</Form.Label>
										</Col>
										<Col sm={8}>
											<Form.Control
												type="date"
												placeholder="Date of Birth"
												name="dateOfBirth"
												value={this.state.dateOfBirth}
												onChange={this._handleInput}
												required
											/>
										</Col>
									</Form.Group>
									<Form.Group
										as={Row}
										controlId="formHorizontalBillingZipCode"
									>
										<Col sm={4}>
											<Form.Label>Billing Zip Code</Form.Label>
										</Col>
										<Col sm={8}>
											<Form.Control
												type="number"
												placeholder="Billing ZipCode"
												name="zipCode"
												value={this.state.zipCode}
												onChange={this._handleInput}
												required
											/>
										</Col>
									</Form.Group>
									<Form.Group as={Row}>
										<Col sm={8}></Col>
										<Col sm={4}>
										<Button
											className="white-btn"
											type="submit"
											disabled={this.state.isLoading}
										>
											Submit
										</Button>
										</Col>
									</Form.Group>
								</Form>
								
							
								{this.state.message ? (
									<p className="login-message">
										{alertDisplay}
									</p>
									) : (
									<p></p>
								)}
							</Col>
		} else {
			registerDisplay = ''
		}

		if(loadProperty){
			propertyDisplay = <Col md={4}>
								<div className="step_3" >Step 3: Create your first property</div>
								<Form 
									// className="formCard PropertyEditForm card_off" 
									className={`formCard PropertyEditForm ${this.state.step_3_off ? "card_off" : ""}`}
									id="property_form"
									onSubmit={this.addProperty}
									>
									<div className="cardTitle">General Home Information</div>
									<Form.Group as={Row}  controlId="formHorizontalPropertyDescription">
										<Col md={4}>
											<Form.Label className="formCardTitle">Description: </Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="text" 
												// placeholder="name" 
												name='propertyDescription' 
												onChange={this._handleInput} 
												required
												value={this.state.propertyDescription} 
												/>
										</Col>
									</Form.Group>
									{/* <Form.Group as={Row} controlId="formHorizontalPhone">
										<Col md={4}>
											<Form.Label className="formCardTitle">Phone: </Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="text" 
												name='propertyPhone' 
												onChange={this._handleInput} 
												value={this.state.propertyPhone} 
												/>
										</Col>
									</Form.Group> */}
									<Form.Group as={Row} controlId="formHorizontalAddress1">
										<Col md={4}>
											<Form.Label className="formCardTitle">Address 1: </Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="text" 
												name='propertyAddress1'
												// autocomplete="off"  
												onChange={this._handleInput} 
												value={this.state.propertyAddress1} 
												ref={this.autocompleteInput}
												required
												onFocus={this.initGooglePlaces}
												/>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formHorizontalAddress2">
										<Col md={4}>
											<Form.Label className="formCardTitle">Address 2: </Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="text" 
												name='propertyAddress2' 
												onChange={this._handleInput} 
												value={this.state.propertyAddress2} 
												/>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formHorizontalCity">
										<Col md={4}>
											<Form.Label className="formCardTitle">City: </Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="text" 
												name='propertyCity' 
												onChange={this._handleInput} 
												required
												value={this.state.propertyCity} 
												/>
										</Col>
									</Form.Group>
									
									<Form.Group as={Row} controlId="formHorizontalState">
										<Col md={4}>
											<Form.Label className="formCardTitle">State: </Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												as="select" 
												name='propertyState' 
												required
												onChange={this._handleInput} 
												value={this.state.propertyState}
												// defaultValue={this.state.propertyState}
												>
												<option value="">Choose...</option>
												<option value="AL">Alabama</option>
												<option value="AK">Alaska</option>
												<option value="AZ">Arizona</option>
												<option value="AR">Arkansas</option>
												<option value="CA">California</option>
												<option value="CO">Colorado</option>
												<option value="CT">Connecticut</option>
												<option value="DE">Delaware</option>
												<option value="DC">District Of Columbia</option>
												<option value="FL">Florida</option>
												<option value="GA">Georgia</option>
												<option value="HI">Hawaii</option>
												<option value="ID">Idaho</option>
												<option value="IL">Illinois</option>
												<option value="IN">Indiana</option>
												<option value="IA">Iowa</option>
												<option value="KS">Kansas</option>
												<option value="KY">Kentucky</option>
												<option value="LA">Louisiana</option>
												<option value="ME">Maine</option>
												<option value="MD">Maryland</option>
												<option value="MA">Massachusetts</option>
												<option value="MI">Michigan</option>
												<option value="MN">Minnesota</option>
												<option value="MS">Mississippi</option>
												<option value="MO">Missouri</option>
												<option value="MT">Montana</option>
												<option value="NE">Nebraska</option>
												<option value="NV">Nevada</option>
												<option value="NH">New Hampshire</option>
												<option value="NJ">New Jersey</option>
												<option value="NM">New Mexico</option>
												<option value="NY">New York</option>
												<option value="NC">North Carolina</option>
												<option value="ND">North Dakota</option>
												<option value="OH">Ohio</option>
												<option value="OK">Oklahoma</option>
												<option value="OR">Oregon</option>
												<option value="PA">Pennsylvania</option>
												<option value="RI">Rhode Island</option>
												<option value="SC">South Carolina</option>
												<option value="SD">South Dakota</option>
												<option value="TN">Tennessee</option>
												<option value="TX">Texas</option>
												<option value="UT">Utah</option>
												<option value="VT">Vermont</option>
												<option value="VA">Virginia</option>
												<option value="WA">Washington</option>
												<option value="WV">West Virginia</option>
												<option value="WI">Wisconsin</option>
												<option value="WY">Wyoming</option>
											</Form.Control>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formHorizontalZipcode">
										<Col md={4}>
											<Form.Label className="formCardTitle">Zip Code: </Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="number" 
												name='propertyZipCode' 
												onChange={this._handleInput} 
												required
												value={this.state.propertyZipCode} 
												/>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formHorizontalYearBuilt">
										<Col md={4}>
											<Form.Label className="formCardTitle">Year Built: </Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="number" 
												name='propertyYearBuilt' 
												onChange={this._handleInput} 
												value={this.state.propertyYearBuilt} 
												/>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formHorizontalFootage">
										<Col md={4}>
											<Form.Label className="formCardTitle">Footage: </Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="number" 
												name='propertyFootage' 
												onChange={this._handleInput} 
												value={this.state.propertyFootage} 
												/>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formHorizontalRoomCnt">
										<Col md={4}>
											<Form.Label className="formCardTitle">Room Count: </Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="number" 
												name='propertyRoomCt' 
												onChange={this._handleInput} 
												value={this.state.propertyRoomCt} 
												/>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formHorizontalBathCnt">
										<Col md={4}>
											<Form.Label className="formCardTitle">Bath Count: </Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="text" 
												name='propertyBathCt' 
												onChange={this._handleInput} 
												value={this.state.propertyBathCt} 
												/>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formHorizontalPurchaseDate">
										<Col md={4}>
											<Form.Label className="formCardTitle">Purchase Date: </Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="date" 
												name='propertyPurchaseDate' 
												onChange={this._handleInput} 
												// value={dateFormat(this.state.propertyPurchaseDate, 'yyyy-mm-dd')} 
												value={ this.state.propertyPurchaseDate ? moment(this.state.propertyPurchaseDate).format('YYYY-MM-DD') : null } 
												/>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formHorizontalHOA">
										<Col md={4}>
											<Form.Label className="formCardTitle">HOA Fees</Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="text" 
												// className="form_display_data"
												name='propertyHOA' 
												onChange={this._handleInput} 
												value={this.state.propertyHOA} 
												/>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formHorizontalRoof">
										<Col md={4}>
											<Form.Label className="formCardTitle">Roof Updated</Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="date" 
												// className="form_display_data"
												name='propertyRoof' 
												onChange={this._handleInput} 
												// value={dateFormat(this.state.propertyRoof, 'yyyy-mm-dd')} 
												value={ this.state.propertyRoof ? moment(this.state.propertyRoof).format('YYYY-MM-DD') : null } 
												/>
										</Col>
									</Form.Group>
								
									<Form.Group as={Row} controlId="formHorizontalPropetyImage">
										<Col md={4}>
											<Form.Label className="formCardTitle">Property Image: </Form.Label>
										</Col>
										<Col md={8}>
											<Form.Control 
												type="file" 
												className='propertyEditImage'
												name='propertyImage' 
												onChange={(e) => this.setSelectedImage(e)}
												/>
										</Col>
									</Form.Group>
									{alertImageDisplay}
									<Form.Group as={Row}>
										<Col sm={8}></Col>
										<Col sm={4}>
											<Button
												className="white-btn"
												type="submit"
											>
											Submit
											</Button>
										</Col>
									</Form.Group>
								</Form>
							</Col>
		} else {
			propertyDisplay = <div></div>
		}

		return (
			<div className="parent-container Fade">
				<div className="banner_register">
					<Container>
						<Row className="banner-content_right">
							<Col sm={10}>
							<div className="banner-text ">
								<div className="text-border">
									<img
									onClick={this.goToHome}
									className="responsive-img logo_large banner_logo_png img_go_home"
									src={new_logo_png}
									alt="home_history_logo"
									/>
								</div>
								<div className="intro-para_new quote">
									<p className="tag-line">
										Track work done on your home in 1 safe place
									</p>
								</div>
							</div>
							<LogoutStartover/></Col>
						</Row>
					</Container>
				</div>
				<section id="feature" className="section-padding light_grey_background">
					{loaderDisplay}
					<Container>
						<Row className="stepsRow">
							<Col sm={2}></Col>
							<Col sm={8}>
								{alertPriceDisplay}
								{stepsDisplay}
							</Col>
						</Row>
						<Row className="feature-info">
							<Col md={4}></Col>
							{/* <Col md={4}> */}
								{registerDisplay}
								{stripeDisplay}
								{propertyDisplay}
							{/* </Col> */}
							{/* <Col md={4}></Col> */}
						</Row>
					</Container>
				</section>
			</div>
		);
	}
}

const InjectedCheckoutForm = () => {
	const auth0 = useAuth0();

	return (
		<ElementsConsumer>
			{({stripe, elements}) => (
				<RegisterComponent stripe={stripe} elements={elements} auth0={auth0} />
			)}
		</ElementsConsumer>
	)
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Register = () => {

   	return (
		<Elements stripe={stripePromise}  >
			<InjectedCheckoutForm />
		</Elements>
   	);
};

export default Register;

