import React, { Component } from 'react';
import './css/Footer.css';
import { Col,Row } from 'react-bootstrap';
import history from '../history';


class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
	
		};

		this.navPrivacy = this.navPrivacy.bind(this);
		this.navTerms = this.navTerms.bind(this);
	}

	_handleInput(event) {
		let obj  =  {}
		let key  = event.target.name;
		obj[key] = event.target.value;
		this.setState(obj);
		// console.log('key: ', key);
	}

	componentDidMount(){
		// set Interval
		// setTimeout(
		// 	function() {
		// 		this.loadInitial();
		// 	}
		// 	.bind(this),
		// 	500
		// );
	}

	navTerms(){
		history.push('/terms');
	}
	
	navPrivacy(){
		history.push('/privacy_policy');
	}

	// loadInitial(){


	// }

	render() {
		return (
			<footer className="navbar" id="footer">
				<Row className="footer_row">
					<Col md="2">
					
					</Col>
					<Col md="2">
						<a href="#" className="term_links" onClick={this.navPrivacy}>Privacy Policy</a>
						<a href="#" className="term_links" onClick={this.navTerms}>Terms</a>
					</Col>
					<Col md="4"></Col>
					<Col md="4"><p className="trademark">©{new Date().getFullYear()} Home History LLC, All Rights Reserved</p></Col>
				</Row>
			</footer>
		);
	}
}

export default Footer;