import React, { Component } from 'react';
import './css/SideNavigation.css';
import SideNav, { NavItem, NavIcon} from '@trendmicro/react-sidenav';
import {ListGroup, Col, Row, Modal, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import $ from 'jquery';
import axios from 'axios';
import history from "../history";
// import { withAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSort, faCalendar } from '@fortawesome/free-solid-svg-icons'
import dateFormat from 'dateformat';
// import moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Calendar, momentLocalizer } from "react-big-calendar";
import plus_image from "./images/custom_icons/add_icon.svg";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment';
moment.locale("en-US");


class SideNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error            : null,
			categoriesLoaded : false,
			categories       : [],
			properties       : [],
			expanded         : false,
			loadList         : false,
			listData         : [],
			records          : [],
			contractors      : [],
			propertyId             : null,
			recordsLoaded          : false, 
            showRecordAdd          : false,
			recordDetailLoaded     : false,
			startDate              : '',
			endDate                : '',
			showRecordEdit         : false,
			display_date           : '',
			totalCost              : '',
			contractor_name_hidden : true,
			contractorName         : '',
			contractorCategoryId   : '',
			recordCostsLoaded      : false,
			recordCosts            : [],
			showAddCost            : false,
			showAddImage           : false,
			loadImage              : false,
			showAddDocument        : false,
			loadDocument           : false,
			recordDocuments        : [],
			recordImagePath        : '',
			userId       : '',
			categoryId   : '',
			amount       : '',
			detail       : '',
			title        : '',
			contractorId : '',
			recordId     : '',
			paymentDate  : '',
			categoryName : '',
			recordsCount : 0,
			showLoader   : false,
			showRecordAddGeneric : false,
			serviceTechName    : '',
			serviceTechPhone   : '',
			serviceTechEmail   : '',
			recordLink         : '',
			nextSort           : 'DESC',
			currentSort        : 'ASC',
			all_records        : [],
			unalteredlistData  : [],
			cal_events    : [],
			loadCalendar  : false,
		};

		this._handleInput   = this._handleInput.bind(this);
		this.handleAddShow  = this.handleAddShow.bind(this);
		this.handleAddClose = this.handleAddClose.bind(this);
		this.loadInitial    = this.loadInitial.bind(this);
		this.getCategories  = this.getCategories.bind(this);
		this.showList       = this.showList.bind(this);
		this.hideList       = this.hideList.bind(this);
		this.getRecordsByProperty = this.getRecordsByProperty.bind(this);
		this.getCurrentProperty   = this.getCurrentProperty.bind(this);
		this.showDetail           = this.showDetail.bind(this);
		this.prepListData         = this.prepListData.bind(this);
		this.addRecord            = this.addRecord.bind(this);
		this.recordPost           = this.recordPost.bind(this);
		this.addRecordCost        = this.addRecordCost.bind(this);
		this.handleAddShowGeneric = this.handleAddShowGeneric.bind(this);
		this.handleAddCloseGeneric = this.handleAddCloseGeneric.bind(this);
		this.sortRecords           = this.sortRecords.bind(this);
		this.searchRecords         = this.searchRecords.bind(this);
		this.calRecordSelected     = this.calRecordSelected.bind(this);
		this.handleCalendarClose   = this.handleCalendarClose.bind(this);
		this.handleCalendarShow    = this.handleCalendarShow.bind(this);
		// this.logOut = this.logOut.bind(this);
		// this.checkExpanded = this.checkExpanded.bind(this);
		// this.reSize = this.reSize.bind(this);
	}

	componentDidMount() {

		this.getCategories();

		axios.defaults.headers.common = {
			'Authorization': 'Bearer ' + sessionStorage.getItem('token')
		};
		const userId = sessionStorage.getItem('userID');

		this.setState({
			expanded: false,
			userId : userId
		})
		// set Interval
		setTimeout(
			function() {
				this.loadInitial();
			}
			.bind(this),
			2500
		);
	}

	loadInitial(){

		this.getCurrentProperty();
		let smallScreen = (window.innerWidth <= 850);
		// console.log('the width: ', window.innerWidth);
		// console.log('small screen: ', smallScreen);
		if (smallScreen){
			$('.parent-container').css( { "padding-left" : "4.7em"} );
		} else {
			$('.parent-container').css( { "padding-left" : "2em"} );
		}

		window.addEventListener("resize", this.reSize.bind(this));
		this.reSize();
	}

	reSize() {

		let smallScreen = (window.innerWidth <= 850);
		// console.log('the width: ', window.innerWidth);
		// console.log('small screen: ', smallScreen);
		if (smallScreen){
			$('.parent-container').css( { "padding-left" : "4.7em"} );
		} else {
			$('.parent-container').css( { "padding-left" : "2em"} );
		}
	}

	handleCalendarClose() {
		this.setState({ loadCalendar : false });
	}

	handleCalendarShow() {
		this.setState({ loadCalendar : true });
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.reSize.bind(this));
	}

	showDetail(recordId, categoryId){
		this.hideList();
		sessionStorage.setItem("record_detail", recordId);
		// window.location = '/records/user='+this.state.userId+'/category='+categoryId;
		history.push('/records/user='+this.state.userId+'/category='+categoryId);
	}

	getCurrentProperty(){

		try{
			if (sessionStorage.getItem('currentActiveProperty') === null){
				axios.get(process.env.REACT_APP_BASE_URL+"properties/user/" + this.state.userId)
				.then(res => {
					const property = res.data.result[0];
					// console.log('property1 : ', property);
		
					this.setState({ 
						propertyId : property.id, 
					});
					this.getRecordsByProperty(property.id);
				});
			} else {
				let currentProperty = sessionStorage.getItem('currentActiveProperty');

				axios.get(process.env.REACT_APP_BASE_URL+"Properties/" + currentProperty)
				.then(res => {
					const property = res.data.result;
					// console.log('property2 : ', property);
					
					this.setState({ 
						propertyId : property.id, 
					});	
					this.getRecordsByProperty(property.id);
				});
			}
		} catch(ex){
			console.log('getCurrentProperty exception : ', ex);
		}
	}

	sortRecords (e, unsorted_records, dir, categoryId, show){
		// console.log('this cat: ', categoryId);
		// console.log('the records : ', unsorted_records);
		if (e){
			e.preventDefault();
		}
		// console.log('sorting');
		try{
			if (dir === 'ASC'){
				var records = unsorted_records.sort(function(a, b){
					var keyA = new Date(a.startDate),
						keyB = new Date(b.startDate);
					// Compare the 2 dates
					if(keyA < keyB) return -1;
					if(keyA > keyB) return 1;
					return 0;
				});	

				this.setState(
					{
						records,
						recordsCount  : records.length,
						showLoader    : false,
						nextSort      : 'DESC',
						recordsLoaded : true, 
						currentSort   : 'ASC'
					},
					() => {                 
						if (show === 'yes'){
							this.showList(categoryId);   
						}                 
					}
				);
	
			} else {
				var records = unsorted_records.sort(function(a, b){
					var keyA = new Date(a.startDate),
						keyB = new Date(b.startDate);
					// Compare the 2 dates
					if(keyA > keyB) return -1;
					if(keyA < keyB) return 1;
					return 0;
				});

				this.setState(
					{
						records,
						recordsCount  : records.length,
						showLoader    : false,
						nextSort      : 'ASC',
						recordsLoaded : true,
						currentSort   : 'DESC'
					},
					() => {   
						if (show === 'yes'){
							this.showList(categoryId);   
						}                        
					}
				);
				
			}
		} catch(ex){
			console.log('sortRecords exception : ', ex);
		}
	}

	searchRecords(e){
		let value = e.target.value.toLowerCase();
		let original_records = this.state.unalteredlistData;
		let all_records = this.state.listData;
		// console.log('all records : ', all_records);

		if (value.length > 0){
			var records = all_records.filter((record) => {
				return record.title.toLowerCase().search(value) !== -1;
			});
	
			// console.log('after search : ', records)
			this.setState({
				listData     : records,
				recordsCount : records.length,
			});
		} else {
			// console.log('in the else: ', original_records);
			var records = original_records;
			this.setState({
				listData     : records,
				recordsCount : records.length,
			});
		}
	};

	addRecord(e){
		this.setState({ 
			showLoader : true,
		});

		e.preventDefault();

		if (this.state.contractor_name_hidden === false){

			var contractorCategoryId = this.state.categoryId;
			var contractorName       = this.state.contractorName;
			try{
				axios.post(process.env.REACT_APP_BASE_URL+'Contractors?userId='+this.state.userId, { 
					contractorCategoryId : contractorCategoryId,
					name                 : contractorName,	
				})
				.then((res) => {
					// console.log('we created a contractor', res);
					var contractorId = res.data.result.id;
					
					this.setState({
						contractorId : contractorId
					}, () => {
						this.recordPost();
					});
				});
			} catch(ex){
				console.log('addRecord exception : ', ex);
			}
		} else {
			this.recordPost();
		}
	}

	recordPost(){
		// e.preventDefault();
		var contractorId = this.state.contractorId;
		var propertyId   = this.state.propertyId;
		var categoryId   = this.state.categoryId;
		var title        = this.state.title;
		var detail       = this.state.detail;
		var startDate    = this.state.startDate;
		var endDate      = this.state.endDate;
		var amount       = this.state.amount;
		var serviceTechName  = this.state.serviceTechName;
		var serviceTechPhone = this.state.serviceTechPhone;
		var serviceTechEmail = this.state.serviceTechEmail;
		var recordLink       = this.state.recordLink;
		// var paymentDate  = this.state.paymentDate;
		try{
			axios.post(process.env.REACT_APP_BASE_URL+'records', { 
			
				contractorId : contractorId,
				propertyId   : propertyId,
				categoryId   : categoryId,
				title        : title, 
				detail       : detail,
				startDate    : startDate,
				endDate      : endDate,
				serviceTechName  : serviceTechName,
				serviceTechPhone : serviceTechPhone,
				serviceTechEmail : serviceTechEmail,
				recordLink       : recordLink
			})
			.then((res) => {
				// console.log('the record post result : ', res);
				const record_response = res.data.result;
				this.setState({ 
					recordId      : record_response.recordId,
					showRecordAdd : false,
					showRecordAddGeneric : false
					// showLoader    : false
				});
				// console.log('amount : ', amount)
				if (recordLink.length > 0){
					axios.post(process.env.REACT_APP_BASE_URL+'records/link', { 
						recordId : record_response.recordId,
						uri      : recordLink
					})
					.then((res) => {
						const record_link_response = res.data.result;
						// console.log('the record link post result : ', record_link_response);
					})
				}
				
				if (amount){
					this.addRecordCost();
				}
				this.getCurrentProperty();
				this.getCategories();
				$('.addRecordForm').hide();
				this.hideList();
				this.showDetail(record_response.recordId, categoryId);

			});
		} catch(ex){
			console.log('recordPost exception : ', ex);
		}
	}

	addRecordCost(e){
		if (e){
			e.preventDefault();
		}
	
		var recordId = this.state.recordId;
		var amount   = this.state.amount;
		if (this.state.paymentDate === undefined || this.state.paymentDate === ''){
			// this.setState(paymentDate : dateFormat(this.state.paymentDate, 'yyyy-mm-dd'))
			this.setState({ paymentDate : dateFormat('yyyy-mm-dd') });
		} 

		var paymentDate = this.state.paymentDate;
	
		try{
			axios.post(process.env.REACT_APP_BASE_URL+'Records/cost', { 
				recordId    : recordId,
				amount      : amount,
				paymentDate : paymentDate
			})
			.then((result) => {

				$('.addRecordCostForm').hide();
				// this.getRecordsByPropertyCategory(this.state.propertyId,this.state.categoryId);
				this.setState({ showAddCost : false });
				// console.log('we added a cost', result);
				this.showDetail(recordId, this.state.categoryId)
			});
		} catch(ex){
			console.log('addRecordCost exception : ', ex);
		}
	}

	getRecordsByProperty(propertyId){

		try{
			axios({
				method: "get",
				url: process.env.REACT_APP_BASE_URL+"Records/prop/"+propertyId,
			})
			.then(res => {
				
				const records = res.data.result;

				var cal_events = records.map(record => ({'start': new Date(moment(record.startDate).format('YYYY-MM-DD')), 'end': new Date(moment(record.endDate).format('YYYY-MM-DD')) , 'title': record.title, allDay: true, recordId: record.recordId, recordCat: record.recordCategory['id']}));
				
				if (records.length > 0) {
					this.setState({ 
						records,
						recordsCount : records.length,
						cal_events   : cal_events,
						all_records : records
					}, () => {
						this.sortRecords(null, records, 'DESC', this.state.categoryId, 'no');
					})
				}

				// if (records.length > 0) {
				// 	this.setState({ 
				// 		records,
				// 		all_records : records
				// 	}, () => {
				// 		this.sortRecords(null, records, 'DESC', this.state.categoryId, 'no');
				// 	})
				// }
			});
		} catch(ex){
			console.log('getRecordsByProperty exception : ', ex);
		}
	}

	calRecordSelected(event){
		// history.push("/records/user=" + this.state.userId) + "/category=";
		this.setState({ loadCalendar : false });
		this.showDetail(event.recordId, event.recordCat);
	}
	// redirectNav(to){
	// 	console.log("to : ", to);
	// 	this.props.history.push(to);
	// }

	_handleInput(event) {
		let obj  =  {}
		let key  = event.target.name;
		obj[key] = event.target.value;
		this.setState(obj);
		
		if (key === 'contractorId' && obj[key] === 'add_new'){
			this.setState({ contractor_name_hidden : false });
		} else if (key === 'contractorId' && obj[key] !== 'add_new'){
			this.setState({ contractor_name_hidden : true });
		}
	}

	getCategories(){

		try{
			axios.get(process.env.REACT_APP_BASE_URL+"categories")
			.then(res => {
				const categories = res.data.result;
				this.setState({ 
					categories,
				});
				// console.log('side nav categories : ', categories)
				
				axios({
					method: "get",
					url: process.env.REACT_APP_BASE_URL+"Contractors/user/"+this.state.userId,
				})
					.then(res => {
					const contractors = res.data.result;
					this.setState({ 
						contractors,
						contractorsLoaded : true,
						categoriesLoaded  : true,
						showLoader        : false
					});
					// console.log(' side nav contractors : ', contractors)
				});
			});
		} catch(ex){
			console.log('getCategories exception : ', ex);
		}
	}

	handleAddShowGeneric() {
		// console.log('clicking this');
		this.setState({ 
			showRecordAddGeneric   : true,
			contractor_name_hidden : true,
			title                  : '',
			detail                 : '',
			startDate              : '',
			endDate                : '',
			amount                 : '',
			paymentDate            : '',
			serviceTechName        : '',
			serviceTechPhone       : '',
			serviceTechEmail       : '',
			recordLink             : '' 
		 });
	}

	handleAddShow() {
		// console.log('clicking this');
		this.setState({ 
			showRecordAdd          : true,
			contractor_name_hidden : true,
			title                  : '',
			detail                 : '',
			startDate              : '',
			endDate                : '',
			amount                 : '',
			paymentDate            : '',
			serviceTechName        : '',
			serviceTechPhone       : '',
			serviceTechEmail       : '',
			recordLink             : '' 
		 });
	}
	
    handleAddClose() {
		this.setState({ showRecordAdd  : false });
	}

	handleAddCloseGeneric() {
		this.setState({ showRecordAddGeneric  : false });
	}

	prepListData(categoryId){
		// console.log('categoryId: ', categoryId)
		var filtered = this.state.all_records.filter( record => record.recordCategory.id === categoryId)
		// console.log('filtered : ', filtered)
		// console.log('categories: ', this.state.categories)H
		var categoryName = '';
		if (filtered[0]){
			categoryName = filtered[0].recordCategory.name
		} else {
			var filtered_cat = this.state.categories.filter( category => category.id === categoryId);
			// console.log('filtered_cat : ', filtered_cat);
			categoryName = filtered_cat[0].name
		}

		this.setState({
			listData      : filtered,
			unalteredlistData : filtered,
			recordsCount  : filtered.length,
			categoryName  : categoryName,
			showRecordAdd : false,
			showRecordAddGeneric : false,
			categoryId    : categoryId
		}, () => {
			// console.log("show list: ", this.state.listData);
			this.sortRecords(null, filtered, this.state.currentSort, categoryId, 'yes');
			// this.showList(categoryId);
		});	
	}

	showList(categoryId){
		// console.log("show list: ", this.state.listData);

		this.setState({
			loadList    : true,
			list_cat_id : categoryId,
			
		});
	} 
	
	hideList(){
		// console.log("hide list");
		// || this.state.showRecordAddGeneric === false
		if (this.state.showRecordAdd === false  ){
			this.setState({
				loadList : false
			})
		}
	}

	goToCategory(e, categoryId){
		sessionStorage.removeItem("record_detail");
		// window.location = "/records/user=" + this.state.userId + "/category=" + categoryId;
		history.push("/records/user=" + this.state.userId + "/category=" + categoryId);
	}

	render() {
		const showLoader = this.state.showLoader;
		
		let loaderDisplay;

		if (showLoader) {
			loaderDisplay = 
						<div className="loader_container">
							<Loader
								type="Oval"
								color="#5FCE7F"
								height={100}
								width={100}
								timeout={10000} //10 secs
								visible={this.state.showLoader}
							/>
						</div>
		} else {
			loaderDisplay = "";
		}
		// console.log('side nav', this.state)
		const loadList = this.state.loadList;
		let ListDisplay;
    	if (loadList) {
			ListDisplay = 	<div className="records_container_sidenav"> 
								<div>
									<div className="sidenav_records_header">
										<Row className="top_line_records">
											<Col md={10} className="record_category_name">{this.state.categoryName}</Col>
											<Col md={2} className="record_calendar_button">
												<FontAwesomeIcon className="calendar_button icon" onClick={this.handleCalendarShow} icon={ faCalendar }/>
											</Col>
										</Row>
										<Row className="records_header_line_2">
                                            <Col md={3} className="records_count">{this.state.recordsCount} Projects</Col>
											<Col md={4} className="records_search">
												<input type="text" placeholder="Search" onChange={this.searchRecords}/>
											</Col>
											<Col md={2} className="record_sort_button">
												<FontAwesomeIcon className="sort_records icon" onClick={(e) => { this.sortRecords(e, this.state.records, this.state.nextSort, 'yes') }} icon={ faSort }/>
                                            </Col>
                                            <Col md={2} className="record_add_button">
												<FontAwesomeIcon className="add_record_plus icon" onClick={this.handleAddShow} icon={ faPlusCircle }/>
                                            </Col>
                                        </Row>
									</div>
									<Modal show={this.state.showRecordAdd} size='lg' onHide={this.handleAddClose}>
										<Modal.Header closeButton>
											<Modal.Title>Add a Record</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<Form 
												className="formCard addRecordForm" 
												onSubmit={this.addRecord}>
												<div className="new_record_title">New Record</div>
												<Form.Group as={Row} controlId="formHorizontalRecordTitle">
													<Col md={4}>
														<Form.Label className="formCardTitle">Title: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															placeholder="title" 
															name='title' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalPropertyId">
													<Form.Control 
														type="hidden" 
														name='propertyId' 
														value={this.state.propertyId} 
														onChange={this._handleInput} 
														required/>
													<Col md={4}>
														<Form.Label className="formCardTitle">Category: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="select" 
															name='categoryId' 
															onChange={this._handleInput} 
															defaultValue={this.state.categoryId}>
															<option value="">Choose...</option>
															{this.state.categories.map(category => 
																<option key={category.id} value={category.id}>{category.name}</option>
															)};
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalContractor">
													<Col md={4}>
														<Form.Label className="formCardTitle">Contractor: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="select" 
															name='contractorId' 
															onChange={this._handleInput} 
															defaultValue="Choose...">
															<option value="">Choose...</option>
															<option value="add_new">Add New</option>
															{this.state.contractors.map(contractor => 
																<option key={contractor.id} value={contractor.id}>{contractor.name}</option>
															)};
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalContractorName" style={{display: this.state.contractor_name_hidden ? 'none' : 'flex' }}>
													<Col md={4}>
														<Form.Label className="formCardTitle">Contractor Name: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															// hidden = { this.state.contractor_name_hidden ? true : false } 
															type="text" 
															placeholder="Name" 
															name='contractorName' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Detail: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="textarea" 
															rows="3"
															placeholder="detail" 
															name='detail' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Start Date: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="date" 
															// value={dateFormat(this.state.startDate, 'yyyy-mm-dd')} 
															name='startDate' 
															required
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">End Date: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="date" 
															name='endDate' 
															required
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalCost">
													<Col md={4}>
														<Form.Label className="formCardTitle">Cost: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="number" 
															name='amount' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalDetail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Payment Date: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="date" 
															name='paymentDate' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalLink">
													<Col md={4}>
														<Form.Label className="formCardTitle">Link: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="url" 
															name='recordLink' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalTechName">
													<Col md={4}>
														<Form.Label className="formCardTitle">Service Tech Name: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='serviceTechName' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalTechPhone">
													<Col md={4}>
														<Form.Label className="formCardTitle">Service Tech Phone: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="tel" 
															name='serviceTechPhone' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalTechEmail">
													<Col md={4}>
														<Form.Label className="formCardTitle">Service Tech Email: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="email" 
															name='serviceTechEmail' 
															onChange={this._handleInput} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row}>
													<Col sm={{ span: 10 }}>
														<Button type="submit">Submit</Button>
													</Col>
												</Form.Group>
											</Form>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" className="white-btn"  onClick={this.handleAddClose}>
												Close
											</Button>
										</Modal.Footer>
									</Modal>
								<ListGroup className="sidenav_list" defaultActiveKey="#link1">
									{/* {this.state.records.filter(recordCategory => recordCategory.id = this.state.list_cat_id).map(record => ( */}
									{this.state.listData.map(record => 
										<div className="record_container_sidenav" key={record.recordId} >
											<ListGroup.Item action className="sidenav_list_items" onClick={() => this.showDetail(record.recordId, record.recordCategory.id)}>
												<div className="record_list_top_line">
													<div className="record_list_contractor">{record.recordContractor.name}</div>
													<div className="record_list_date">{dateFormat(record.startDate, 'm/dd/yyyy')}</div>
												</div>
												<div className="record_list_title" >{record.title}</div>
												<div className="record_list_price">{record.totalCostFormatted}</div>
											</ListGroup.Item>
										</div>
									)}
								</ListGroup>
							</div>
						</div>;
		} else {
			ListDisplay = <div></div>
		}

		const categoriesLoaded = this.state.categoriesLoaded;
		let categoriesDisplay;
		if (categoriesLoaded) {
			// console.log('category : ', this.state.categories);
			categoriesDisplay = 
				// <div className="record_add_button">
				// 	<FontAwesomeIcon onClick={this.handleAddShowGeneric} className="add_record_plus icon" icon={ faPlusCircle }/>
				// </div>
				this.state.categories.map(category => 
				<OverlayTrigger
					key={category.id}
					placement="right"
					overlay={
						<Tooltip >
							{category.name}
						</Tooltip>
				  	} >
					<NavItem className="navitem_container" key={category.id} eventKey={"/records/user=" + this.state.userId + "/category=" + category.id}  >
						<NavIcon className="nav_icon" onMouseEnter={() => this.prepListData(category.id)} onClick={(e) => {this.goToCategory(e, category.id);}} title={category.name} atl={category.name}>
							<img className="side_nav_icon" src={category.iconUrl} title={category.name} alt={category.name}></img>
						</NavIcon>
					</NavItem>
				</OverlayTrigger>
			);
		} else {
			categoriesDisplay = <div></div>;
		}

		let genericModalDisplay = 
			<Modal show={this.state.showRecordAddGeneric} size='lg' onHide={this.handleAddCloseGeneric}>
				<Modal.Header closeButton>
					<Modal.Title>Add a Record</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form 
						className="formCard addRecordForm" 
						onSubmit={this.addRecord}>
						<div className="new_record_title">New Record</div>
						<Form.Group as={Row} controlId="formHorizontalRecordTitle">
							<Col md={4}>
								<Form.Label className="formCardTitle">Title: </Form.Label>
							</Col>
							<Col md={8}>
								<Form.Control 
									type="text" 
									placeholder="title" 
									name='title' 
									onChange={this._handleInput} 
									/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formHorizontalPropertyId">
							<Form.Control 
								type="hidden" 
								name='propertyId' 
								value={this.state.propertyId} 
								onChange={this._handleInput} 
								required/>
							<Col md={4}>
								<Form.Label className="formCardTitle">Category: </Form.Label>
							</Col>
							<Col md={8}>
								<Form.Control 
									as="select" 
									name='categoryId' 
									onChange={this._handleInput} 
									// defaultValue={this.state.categoryId}
									>
									<option value="">Choose...</option>
									{this.state.categories.map(category => 
										<option key={category.id} value={category.id}>{category.name}</option>
									)};
								</Form.Control>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formHorizontalContractor">
							<Col md={4}>
								<Form.Label className="formCardTitle">Contractor: </Form.Label>
							</Col>
							<Col md={8}>
								<Form.Control 
									as="select" 
									name='contractorId' 
									onChange={this._handleInput} 
									defaultValue="Choose...">
									<option value="">Choose...</option>
									<option value="add_new">Add New</option>
									{this.state.contractors.map(contractor => 
										<option key={contractor.id} value={contractor.id}>{contractor.name}</option>
									)};
								</Form.Control>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formHorizontalContractorName" style={{display: this.state.contractor_name_hidden ? 'none' : 'flex' }}>
							<Col md={4}>
								<Form.Label className="formCardTitle">Contractor Name: </Form.Label>
							</Col>
							<Col md={8}>
								<Form.Control 
									// hidden = { this.state.contractor_name_hidden ? true : false } 
									type="text" 
									placeholder="Name" 
									name='contractorName' 
									onChange={this._handleInput} 
									/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formHorizontalDetail">
							<Col md={4}>
								<Form.Label className="formCardTitle">Detail: </Form.Label>
							</Col>
							<Col md={8}>
								<Form.Control 
									as="textarea" 
									rows="3"
									placeholder="detail" 
									name='detail' 
									onChange={this._handleInput} 
									/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formHorizontalDetail">
							<Col md={4}>
								<Form.Label className="formCardTitle">Start Date: </Form.Label>
							</Col>
							<Col md={8}>
								<Form.Control 
									type="date" 
									// value={dateFormat(this.state.startDate, 'yyyy-mm-dd')} 
									name='startDate' 
									required
									onChange={this._handleInput} 
									/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formHorizontalDetail">
							<Col md={4}>
								<Form.Label className="formCardTitle">End Date: </Form.Label>
							</Col>
							<Col md={8}>
								<Form.Control 
									type="date" 
									name='endDate' 
									required
									onChange={this._handleInput} 
									/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formHorizontalCost">
							<Col md={4}>
								<Form.Label className="formCardTitle">Cost: </Form.Label>
							</Col>
							<Col md={8}>
								<Form.Control 
									type="number" 
									name='amount' 
									onChange={this._handleInput} 
									/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formHorizontalDetail">
							<Col md={4}>
								<Form.Label className="formCardTitle">Payment Date: </Form.Label>
							</Col>
							<Col md={8}>
								<Form.Control 
									type="date" 
									name='paymentDate' 
									onChange={this._handleInput} 
									/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formHorizontalLink">
							<Col md={4}>
								<Form.Label className="formCardTitle">Link: </Form.Label>
							</Col>
							<Col md={8}>
								<Form.Control 
									type="url" 
									name='recordLink' 
									onChange={this._handleInput} 
									/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formHorizontalTechName">
							<Col md={4}>
								<Form.Label className="formCardTitle">Service Tech Name: </Form.Label>
							</Col>
							<Col md={8}>
								<Form.Control 
									type="text" 
									name='serviceTechName' 
									onChange={this._handleInput} 
									/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formHorizontalTechPhone">
							<Col md={4}>
								<Form.Label className="formCardTitle">Service Tech Phone: </Form.Label>
							</Col>
							<Col md={8}>
								<Form.Control 
									type="tel" 
									name='serviceTechPhone' 
									onChange={this._handleInput} 
									/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formHorizontalTechEmail">
							<Col md={4}>
								<Form.Label className="formCardTitle">Service Tech Email: </Form.Label>
							</Col>
							<Col md={8}>
								<Form.Control 
									type="email" 
									name='serviceTechEmail' 
									onChange={this._handleInput} 
									/>
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Col sm={{ span: 10 }}>
								<Button type="submit">Submit</Button>
							</Col>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" className="white-btn"  onClick={this.handleAddCloseGeneric}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>;

		const localizer = momentLocalizer(moment);

		const loadCalendar = this.state.loadCalendar;
		let calendarDisplay;

		if (loadCalendar) {
			calendarDisplay = <div className="record_detail_container">
									
									<Modal show={this.state.loadCalendar} size='lg' dialogClassName="calendar_modal" onHide={this.handleCalendarClose}>
										<Modal.Header closeButton>
											<Modal.Title>Records Calendar</Modal.Title>
										</Modal.Header>
											<Modal.Body>
												<Calendar
													selectable={true}
													defaultDate={moment().toDate()}
													style={{ height: 600,width: '95%' }}
													views={['month', 'week', 'day']}
													defaultView='month'
													localizer={localizer}
													events={this.state.cal_events}
													// onSelectEvent={event => this.calRecordSelected(event)}
													onSelectEvent={(e) => this.calRecordSelected(e)}
													// onSelectSlot={(this.calRecordSelected)}
													// onSelectEvent={event => alert(`${event.recordId} ${event.title}`)}

													startAccessor="start"
													endAccessor="end"
												/>
											</Modal.Body>
											<Modal.Footer>
												<Button variant="secondary" className="white-btn"  onClick={this.handleCalendarClose}>
													Close
												</Button>
											</Modal.Footer>
										</Modal>
									</div>;
		} else {
			calendarDisplay = <div></div>
		}

		return (
			<div onMouseLeave={this.hideList}>
				<SideNav id='side-nav' 
					className=""
					disabled={true}
					defaultExpanded={false}
					// onToggle={(expanded) => {
					// }}
					// onSelect={(selected) => {	
					// }}
					>
					<SideNav.Toggle />
					<SideNav.Nav >
						<div className="record_add_generic_button">
							<FontAwesomeIcon onClick={this.handleAddShowGeneric} className="add_generic_record_plus icon" icon={ faPlusCircle }/>
							{/* <img className="side_nav_icon" onClick={this.handleAddShowGeneric} src={plus_image} title="generic_add" alt="generic_add"></img> */}
						</div>
						{categoriesDisplay}
					</SideNav.Nav>
				</SideNav>
				<div>
					{ListDisplay}
					{calendarDisplay}
					{loaderDisplay}
					{genericModalDisplay}
				</div>
			</div>
		);
	}
}
export default SideNavigation;