import React, { Component } from "react";
import "./css/Promo.css";
import new_logo_png from "./images/HH_Logo_w_Name.png";
// import new_logo_svg from "./images/HH_Logo_w_Name_V2.svg";
import remember_png from "./images/Remember.png";
import laptop_png from "./images/Laptop.png";
// import { browserHistory } from 'react-router';
import history from "../history";
import { Container,Col,Row,Button,Card} from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {faHome,faListOl,faExchangeAlt} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import LoginButton from './LoginButton.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
// import RegisterLoginButton from './RegisterLoginButton.js';
// import LogoutButton from './LogoutButton.js';

import { withAuth0 } from '@auth0/auth0-react';

class Promo extends Component {
	constructor(props, context) {
		super(props, context);  

		this.state = {
			show           : false,
			error          : null,
			userName       : "",
			password       : "",
			email          : "",
			firstName      : "",
			lastName       : "",
			dateOfBirth    : "",
			address1       : "",
			address2       : "",
			city           : "",
			zipCode        : "",
			state          : "",
			auth           : "",
			message        : "",
			isLoading      : false,
			showLoader     : false, 
			userId         : ''
		};
		this.priceRef       = React.createRef();
		this.optionRef      = React.createRef();
		this.handleShow     = this.handleShow.bind(this);
		this.handleClose    = this.handleClose.bind(this);
	
		this._handleInput   = this._handleInput.bind(this);
		// this.redirectThanks = this.redirectThanks.bind(this);
		this.goToRegister   = this.goToRegister.bind(this);

		this.retrieveToken    = this.retrieveToken.bind(this);
		this.redirectLoggedIn = this.redirectLoggedIn.bind(this);
		this.executeScroll    = this.executeScroll.bind(this);
		this.executeScrollOptions  = this.executeScrollOptions.bind(this);
		this.redirectStripeBilling = this.redirectStripeBilling.bind(this);
		this.navTerms   = this.navTerms.bind(this);
		this.navPrivacy = this.navPrivacy.bind(this);
		this.goToDemo   = this.goToDemo.bind(this);
	}

	handleClose() {
		this.setState({ 
			message : "", 
			show    : false,
		});
	}

	handleShow() {
		this.setState({ 
			show : true,
		});
	}

	goToDemo(){
		window.open('https://www.youtube.com/watch?v=b0DcBwjz1HI', '_blank', 'noopener,noreferrer');
	}

	componentDidMount(){
		// super.componentDidMount();
		// set Interval
		setTimeout(
			function() {
				this.loadInitial();
			}
			.bind(this),
			500
		);

		this.backListener = history.listen(location => {
			if (location.action === "POP") {
				localStorage.setItem("redirect_url", '/');
				localStorage.setItem("previous_page", '');
			}
		});

	}

	componentWillUnmount() {
		// super.componentWillUnmount();
		this.backListener();
	}

	async retrieveToken() {
		this.setState({ 
			showLoader : true,
		});
		var token = '';
		try {
			const { getAccessTokenSilently } = this.props.auth0;
			token = await getAccessTokenSilently();
			// console.log('the token: ', token);
			
		} catch (ex) {
			console.error( 'error retrieveToken', ex);
		}

		if (token.length > 0) {
			// console.log('have token');
			sessionStorage.setItem("token", token);
			if (localStorage.getItem('previous_page') === 'register'){
				// window.location = "/register";
				this.setState({ 
					showLoader : false,
				});
				history.push("/register");
				// console.log('go to register');
			} else {

				// console.log('redirect login');
				this.redirectLoggedIn(token);
			}
			// sessionStorage.setItem("logged_in", 'true');
		} else {
			this.setState({ 
				showLoader : false,
			});
			// console.log('no token');
		}
	}

	loadInitial() {
		sessionStorage.setItem("logged_in", 'false');
		this.retrieveToken();
		localStorage.setItem("redirect_url", '/');
	};

	redirectLoggedIn(token){
		try{
			axios.post(process.env.REACT_APP_BASE_URL+"Users/Auth", {})
			.then(res => {
				var user_data = res.data.result;
				// console.log('the user data: ', user_data);
				if (localStorage.getItem('previous_page') === 'register'){
					// console.log('go to register 2: ');
					// window.location = "/register";
					this.setState({ 
						showLoader : false,
					});
					history.push("/register");

				// } else if (user_data) {
				} else if (user_data.userPaymentStatus === "Ok") {
					sessionStorage.setItem("userID", user_data.userId);
					// console.log('user result ', user_data);

					localStorage.setItem("previous_page", '');
					// window.location = "/home/user=" + user_data.userId;
					this.setState({ 
						showLoader : false,
					});
					history.push("/home/user=" + user_data.userId);
				} else if (user_data.userPaymentStatus === "Bad"){
					this.redirectStripeBilling(user_data.userId);
					// sessionStorage.setItem("userID", user_data.userId);
					// window.location = "/home/user=" + user_data.userId;
					// history.push("/home/user=" + user_data.userId);
				}
			}).catch((err) => {
				// console.log('token doesnt match');
				if (err.response) {
					// console.log("error.Response.status", err.response.status);
					switch (err.response.status) {
						case 401:
							// console.log('in 401 line 183: ', this.state.userId);
							// this.callLoginRedirect();
							// this.callLoginRedirectExisting();
							// this.callLoginRedirect_zt();


							localStorage.clear();
							sessionStorage.clear();
							sessionStorage.setItem("logged_in", 'false');
							localStorage.setItem("redirect_url", '/');
							const { logout } = this.props.auth0;
							logout({ returnTo: window.location.origin });
							this.setState({ 
								showLoader : false,
							});
							
						break;
						default:
							this.setState({ 
								showLoader : false,
							});
							localStorage.setItem("redirect_url", '/');
							localStorage.setItem("previous_page", '');
							// return (history.push('/'));
					}
				}
			})
		} catch(ex){
			console.log('redirectLoggedIn exception : ', ex);
		}
	}

	redirectStripeBilling(userId){
		
		try{
			axios
			.post(process.env.REACT_APP_BASE_URL+"Payments/create-customer-portal-session?userId=" + userId + '&returnUrl=' + window.location.origin, {
				// userId: this.state.userId,
			})
			.then((res) => {
				// console.log('stripe portal response : ', res);
				var url = res.data.result.url;
				// console.log('stripe portal url : ', url);
				// window.location.replace(url);
				this.setState({ 
					showLoader : false,
				});
				window.location.replace(url);
				// return response.json();
			}).catch((err) => {
				// console.log('token doesnt match');
				if (err.response) {
					// console.log("error.Response.status", err.response.status);
					switch (err.response.status) {
						case 401:
							// console.log('in 401 line 237: ', this.state.userId);
							// this.callLoginRedirect();
							// this.callLoginRedirectExisting();
							// this.callLoginRedirect_zt();


							localStorage.clear();
							sessionStorage.clear();
							sessionStorage.setItem("logged_in", 'false');
							localStorage.setItem("redirect_url", '/');
							const { logout } = this.props.auth0;
							logout({ returnTo: window.location.origin });
							this.setState({ 
								showLoader : false,
							});
							
						break;
						default:
						
						// localStorage.setItem("redirect_url", '/register');
						// localStorage.setItem("previous_page", '/register');
						// return (history.push('/register'));

						var confirm = window.confirm('You are logged in but have not completed registration. Click ok to finish registering');
						if (confirm) {
							this.setState({ 
								showLoader : false,
							});
							history.push('/register');


						} else {
							// console.log('line 267');
							localStorage.clear();
							sessionStorage.clear();
							sessionStorage.setItem("logged_in", 'false');
							localStorage.setItem("redirect_url", '/');
							const { logout } = this.props.auth0;
							logout({ returnTo: window.location.origin });
							this.setState({ 
								showLoader : false,
							});
						}
					}
				}
			})
		} catch(ex){
			console.log('redirectStripeBilling exception : ', ex);
		}
	}
	// displayError(event) {
	// 	// this.changeLoadingStatePrices(false);
	// 	let displayError = document.getElementById('card-element-errors');
	// 	if (event.error) {
	// 		displayError.textContent = event.error.message;
	// 	} else {
	// 		displayError.textContent = '';
	// 	}
	// }

	navTerms(){
		history.push('/terms');
	}
	
	navPrivacy(){
		history.push('/privacy_policy');
	}

	_handleInput(event) {
		let obj = {};
		let key = event.target.name;
		obj[key] = event.target.value;
		this.setState(obj);
	}

	// redirectThanks() {
	// 	// this.props.history.push("/thanks");
	// 	// window.location = "/thanks";
	// 	history.push("/thanks");
	// }
	
	goToRegister(sub_type){
		// if(e){
		// 	e.preventDefault();
		// }
		// console.log('this is hitting', sub_type);
		if(sub_type === 1){
			localStorage.setItem('sub_type', 'monthly');
			// window.location = "/register/sub_type=monthly";
			history.push("/register/sub_type=monthly");
		} else if (sub_type === 2){
			localStorage.setItem('sub_type', 'yearly');
			// window.location = "/register/sub_type=yearly";
			history.push("/register/sub_type=yearly");
		} else if (sub_type === 3){
			localStorage.setItem('sub_type', 'unlimited');
			// window.location = "/register/sub_type=unlimited";
			history.push("/register/sub_type=unlimited");
		} else if (sub_type === 4){
			localStorage.setItem('sub_type', 'daily');
			// window.location = "/register/sub_type=daily";
			history.push("/register/sub_type=daily");
		} else {
			// window.location = "/register";
			history.push("/register");
		}
	}

	executeScroll = () => this.priceRef.current.scrollIntoView();
	executeScrollOptions = () => this.optionRef.current.scrollIntoView();

	render() {

		const showLoader = this.state.showLoader;
		
		let loaderDisplay;

		if (showLoader) {
			loaderDisplay = 
						<div className="loader_container">
							<Loader
								type="Oval"
								color="#5FCE7F"
								height={100}
								width={100}
								timeout={10000} //10 secs
								visible={this.state.showLoader}
							/>
						</div>
		} else {
			loaderDisplay = "";
		}

		return (
			<div className="parent-container-home Fade">
				<section id="feature" className="promo_header_section">
					<Container>
						<Row>
							<Col md={2}>
								<div className="header-section text-center">
									<div>
										<img
											className="responsive-img logo_large promo_header_logo"
											src={new_logo_png}
											alt="home_history_logo"
										/>
									</div>
								</div>
							</Col>
							<Col md={8} ></Col>
							<Col md={1} >
								<div className="header-section text-center">
									<div>
										<Button
											// variant="primary"
											className="learn-more-btn"
											onClick={this.executeScroll}
											>
											Learn&nbsp;More
										</Button>
									</div>
								</div>
							</Col>
							<Col md={1}>
								<div className="header-section text-center">
									<div>
										<LoginButton/>
									</div>
								</div>
							</Col>
							
							<Col md={2}></Col>
						</Row>
					</Container>
				</section>
				<section>
					<div className="banner">
						{loaderDisplay}
						<Container>
							<Row className="banner-content">
								<div className="banner-text ">
									<h4 className="tag_line">Your Home.</h4>
									<h4 className="tag_line">Its Story.</h4>
									<h4 className="tag_line">Remembered.</h4>
									<Button
										variant="primary"
										className="big_promo_btn"
										onClick={this.executeScrollOptions}
										>
										Get Started
									</Button>
									
								</div>
								<div>
									<Button
										variant="primary"
										className="big_promo_btn demo"
										onClick={this.goToDemo}
										>
										Watch a Demo
									</Button>
								</div>
							</Row>
						</Container>
						<div className="banner-overlay">
						</div>
					</div>
				</section>
				<section id="pricing_section" ref={this.optionRef} className="section-padding white_background">
					<Container>
						<Row>
							<Col>
								<div className="header-section text-center">
									<h2 className="remember_text_two blue_text">Remember Your Home History</h2>
								</div>
								
							</Col>
						</Row>
						<Row className="feature-info justify-content-center">
							{/* <Col md={2}></Col> */}
							<Col md={4} className="text-center">
								<Card border="dark" className="text-center price_card">
									<Card.Header className="card_header"></Card.Header>
									<Card.Body className="basic_card">
										<Card.Title>Monthly</Card.Title>
										<Card.Text>
											<div className="price strikethrough">$5</div>&nbsp;&nbsp;&nbsp;
											<div className="price">$3</div><div className="price_time">/mo</div>
											<ul className="price_card_list">
												<li>Single Property</li>
												<li>Unlimited appliance records</li>
												<li>Unlimited maintenance records</li>
												<li>Free home file storage</li>
												<li>Unlimited Contacts</li>
											</ul>										
										</Card.Text>
										<Button
											variant="primary"
											className="get-started-btn"
											onClick={() => this.goToRegister(1)}
											>
											Get Started
										</Button>
									</Card.Body>
								</Card>
							</Col>
							<Col md={4} className="text-center">
								<Card border="dark" className="text-center price_card">
									<Card.Header className="popular_header">MOST POPULAR OPTION</Card.Header>
									<Card.Body>
										<Card.Title>Yearly</Card.Title>
										<Card.Text>
											<div className="price strikethrough">$40</div>&nbsp;&nbsp;&nbsp;
											<div className="price">$15</div><div className="price_time">/yr</div>
											<ul className="price_card_list">
												{/* <li className="bold">Everything in Basic, plus</li> */}
												<li>Unlimited appliance records</li>
												<li>Unlimited maintenance records</li>
												<li>Free home file storage</li>
												<li>Multiple properties</li>
											</ul>
										</Card.Text>
										<Button
											variant="primary"
											className="get-started-btn"
											onClick={() => this.goToRegister(2)}
											>
											Get Started
										</Button>
									</Card.Body>
									{/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
								</Card>
							</Col>
							<Col md={4} className="text-center">
								<Card border="dark" className="text-center price_card">
									<Card.Header className="card_header italics">Limited Availability</Card.Header>
									<Card.Body className="basic_card">
										<Card.Title>Lifetime</Card.Title>
										<Card.Text>
											<div className="price strikethrough">$375</div>&nbsp;&nbsp;&nbsp;
											<div className="price">$125</div>
											<ul className="price_card_list">
												<li className="bold">Everything in Yearly, plus</li>
												<li>Lifetime access at no additional cost</li>
												<li>Free custom Digital rendering of house</li>
											</ul>										
										</Card.Text>
										<Button
											variant="primary"
											className="get-started-btn"
											onClick={() => this.goToRegister(3)}
											>
											Get Started
										</Button>
									</Card.Body>
									{/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
								</Card>
							</Col>
							{/* <Col md={2}></Col> */}
						</Row>
						{/* <Row>
							<Col md={5}></Col>	
							<Col md={2}>
								<Button
									variant="primary"
									className="get-started-btn-test"
									onClick={() => this.goToRegister(4)}
									>
									Test Daily Sub
								</Button>
							</Col>
							<Col md={5}>
								<LogoutButton/>

							</Col>
						</Row> */}
						{/* <LogoutButton/> */}
					</Container>
				</section>
				<section id="feature" className="section-padding">
					<Container>
						<Row className="feature-info">
							<Col md={1}></Col>
							<Col md={5}>
								<div className="fea">
									<div className="pull-right">
										<h4 className="blue_text remember_text">Remember every detail about your home</h4>
										<p className="organize_text">
											Organize every contact, appliance, service, and maintenance job in a single place
										</p>
									</div>
								</div>
							</Col>
							<Col md={2}></Col>
							<Col md={4}>
								<div className="fea">
									<div className="fea-img pull-left">
										<img
											className="responsive-img logo_large remember_img"
											src={remember_png}
											alt="home_history_logo"
										/>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="feature" className="section-padding white_background">
					<Container>
						<Row>
							<Col>
								<div className="header-section text-center">
									<h2 className="remember_text_two blue_text">Remember with Home History</h2>
								</div>
							</Col>
						</Row>
						<Row className="feature-info">
							<Col md={1}></Col>
							<Col md={3}>
								<div className="fea">
									<div className="">
										<h4 className="grey_text text-left">Home Inventory</h4>
										<h4 className="grey_text text-left">Set Up Reminders</h4>
										<h4 className="grey_text text-left">Home Improvement</h4>
									</div>
								</div>
							</Col>
							<Col md={4}>
								<div className="fea">
									<div className="">
										<h4 className="grey_text text-center">Collect Contacts</h4>
										<h4 className="grey_text text-center">Paint Colors</h4>
										<h4 className="grey_text text-center">Service Jobs</h4>
									</div>
								</div>
							</Col>
							<Col md={3}>
								<div className="fea">
									<div className="">
										<h4 className="grey_text text-right">Store Documents</h4>
										<h4 className="grey_text text-right">Collect Photos</h4>
										<h4 className="grey_text text-right">Maintenance</h4>
									</div>
								</div>
							</Col>
							<Col md={1}></Col>
						</Row>
					</Container>
				</section>
				<section id="feature" ref={this.priceRef} className="section-padding">
					<Container>
						<Row className="feature-info">
							<Col md={1}></Col>
							<Col md={5}>
								<div className="fea">
									<div className="">
										<h4 className="blue_text remember_text">When you're ready to move, help someone else remember every detail</h4>
										<p className="organize_text">Securely transfer your Home History at the time your house sells to it's new owners. Retain your contacts, pictures, and information as you begin your next journey in your new home.</p>
									</div>
								</div>
							</Col>
							<Col md={2}></Col>
							<Col md={4}>
								<div className="fea">
									<div className="pull-left">
										<img
											className="responsive-img logo_large promo_header_logo laptop_img"
											src={laptop_png}
											alt="home_history_logo"
										/>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				
				<section id="feature" className="promo_header_section footer_section">
					<Container>
						<Row className="feature-info">
							<Col md="2">
							</Col>
							<Col md="2">
								<a href="#" className="term_links grey_text_small" onClick={this.navPrivacy}>Privacy Policy</a>
								<a href="#" className="term_links grey_text_small" onClick={this.navTerms}>Terms</a>
							</Col>
							<Col md="4"></Col>
							<Col md="4">
								<div><p className="trademark grey_text_small">©{new Date().getFullYear()} Home History LLC, All Rights Reserved</p></div>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
		);
	}
}

export default withAuth0(Promo);

