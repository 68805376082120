import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
import './css/Header.css';
import {Navbar, Form, Dropdown, DropdownButton, Row, Col, Modal, Button} from 'react-bootstrap';
import new_logo_png from "./images/HH_Logo_w_Name.png";
// import HH_logo_vertical from './images/Logo_Vertical.svg';
import axios from 'axios';
import history from '../history';
// import queryString from 'query-string'
import { withAuth0 } from '@auth0/auth0-react';
import emailjs from 'emailjs-com';
import Recaptcha from 'react-recaptcha';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
LogRocket.init('mafvxv/home-history');
setupLogRocketReact(LogRocket);
class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userName            : '',
			email               : '',
			address             : '',
			propertyDescription : '',
			propertyId          : '',
			propertySection     : false,
			userSection         : false,
			loadPage            : false,
			userId              : '',
			loadActiveProperty  : false,
			selectedProperty    : '',
			properties          : [],
			showDropdown        : false,
			showContactForm      : false,
			showSubscriptionForm : false,
			subscriptionEmailAddress : '',
			subscriptionName         : '',
			subscriptionMessage      : '',
			contactEmailAddress      : '',
			contactName     : '',
			contactMessage  : '',
			recaptchaLoad   : false,
			isVerified      : false,
			headerAddress   : '',
			headerCityState : '',
			initials        : '',
			currentProperty : '',
			showManageBilling : false,
			showLoader        : false
		};

		this._handleInput        = this._handleInput.bind(this);	
		this.getProperties       = this.getProperties.bind(this);
		this.selectProperty      = this.selectProperty.bind(this);
		this.loadInitial         = this.loadInitial.bind(this);
		this.navContractor       = this.navContractor.bind(this);
		this.navProperties       = this.navProperties.bind(this);
		this.getPropertyData     = this.getPropertyData.bind(this);
		this.getUserData         = this.getUserData.bind(this);
		this.checkActiveProperty = this.checkActiveProperty.bind(this);
		this.getFirstProperty    = this.getFirstProperty.bind(this);
		this.logOut              = this.logOut.bind(this);
		this.showDropdown        = this.showDropdown.bind(this);
		this.hideDropdown        = this.hideDropdown.bind(this);
		this.sendContactEmail      = this.sendContactEmail.bind(this);
		this.sendSubscriptionEmail = this.sendSubscriptionEmail.bind(this);
		this.handleShowSubscriptionForm  = this.handleShowSubscriptionForm.bind(this);
		this.handleCloseSubscriptionForm = this.handleCloseSubscriptionForm.bind(this);
		this.handleShowContactForm       = this.handleShowContactForm.bind(this);
		this.handleCloseContactForm      = this.handleCloseContactForm.bind(this);
		this.recaptchaLoaded   = this.recaptchaLoaded.bind(this);
		this.verifiedRecaptcha = this.verifiedRecaptcha.bind(this);
		this.redirectStripeBilling = this.redirectStripeBilling.bind(this);
		this.navHome = this.navHome.bind(this);
	}

	_handleInput(event) {
		let obj  =  {}
		let key  = event.target.name;
		obj[key] = event.target.value;
		this.setState(obj);
		// console.log('key: ', key);
	}

	componentDidMount(){
		// set Interval
		setTimeout(
			function() {
				this.loadInitial();
			}
			.bind(this),
			500
		);
	}

	loadInitial(){

		axios.defaults.headers.common = {
			'Authorization': 'Bearer ' + sessionStorage.getItem('token')
		};

		const userId = sessionStorage.getItem('userID');
		// console.log('new user id : ',userId);
		this.setState({ 
			userId   : userId,
			loadPage : false 
		});
		this.getFirstProperty();
	
		this.getUserData(userId);
	}

	recaptchaLoaded() {
		this.setState({ recaptchaLoad : true });
	}
	  
	verifiedRecaptcha(response) {
		if (response) {
		  this.setState({ isVerified : true });
		}
	}

	handleShowContactForm(){
		this.setState({ showContactForm : true });
	}
	handleCloseContactForm(){
		this.setState({ showContactForm : false });
	}
	handleShowSubscriptionForm(){
		this.setState({ showSubscriptionForm : true });
	}
	handleCloseSubscriptionForm(){
		this.setState({ showSubscriptionForm : false });
	}

	showDropdown(){
		this.setState({ 
			showDropdown : true
		});
	}
	hideDropdown(){
		this.setState({ 
			showDropdown : false
		});
	}

	redirectStripeBilling(){
		this.setState({ 
			showLoader : true,
		});
		try{
			axios
			.post(process.env.REACT_APP_BASE_URL+"Payments/create-customer-portal-session?userId=" + this.state.userId + '&returnUrl=' + window.location.origin, {
				// userId: this.state.userId,
			})
			.then((res) => {

				this.setState({ 
					showLoader : false,
				});
				// console.log('stripe portal response : ', res);
				var url = res.data.result.url;
				// console.log('stripe portal url : ', url);
				window.location.replace(url);
				// history.push(url);
				// return response.json();
			});
		} catch(ex){
			console.log('redirectStripeBilling exception : ', ex);
		}
	}

	sendContactEmail(e) {
		e.preventDefault();
		this.setState({ 
			showLoader : true,
		});

		if (this.state.recaptchaLoad && this.state.isVerified) {
			if (this.state.contactName && this.state.contactEmailAddress && this.state.contactMessage){
				
				emailjs.sendForm('service_66o1aqf', 'template_5u2g8dg', '#ConctactUsForm', process.env.REACT_APP_EMAILJS_USER)
				.then((result) => {
					this.setState({ 
						showLoader : false,
					});
					// console.log(result.text);
					this.handleCloseContactForm();
					alert('Your message has been sent successfully. We will contact you soon.');
				}, (error) => {
					this.setState({ 
						showLoader : false,
					});
					alert('There was an error sending your email, please try again.');
					console.log(error.text);
				});
			} else {
				this.setState({ 
					showLoader : false,
				});
				alert('Please fill in all the fields.');
			}
		} else {
			this.setState({ 
				showLoader : false,
			});
			alert('Please check the Recaptcha before sending your message');
		}
	}

	sendSubscriptionEmail(e) {
		e.preventDefault();

		if (this.state.recaptchaLoad && this.state.isVerified) {
			if (this.state.subscriptionName && this.state.subscriptionEmailAddress && this.state.subscriptionMessage){
				emailjs.sendForm('service_0u4z5kw', 'template_2smito3', '#SubscriptionForm', process.env.REACT_APP_EMAILJS_USER)
				.then((result) => {
					// console.log(result.text);
					this.handleCloseSubscriptionForm();
					alert('Your message has been sent successfully. We will contact you soon.');
				}, (error) => {
					alert('There was an error sending your email, please try again.');
					console.log(error.text);
				});
			} else {
				alert('Please fill in all the fields');
			}
			
		} else {
			alert('Please check the Recaptcha before sending your message');
		}
	}

	getFirstProperty(){
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Properties/user/" + this.state.userId)
			.then(res => {
				const properties_data = res.data.result;
				// console.log('user result ', user_data);
				properties_data.forEach(property => {
					// console.log('single property: ', property);
					if (property.is_Default){
						var defaultId = property.id;
						// console.log('defaultId: ', defaultId);
						if (sessionStorage.getItem('currentActiveProperty') === null){
							sessionStorage.setItem('currentActiveProperty', defaultId);
						}
					}
				});
				
				this.setState({ 
					properties : properties_data
				});
				this.checkActiveProperty();
				// console.log('properties',this.state.properties)
			});	
		} catch(ex){
			console.log('getFirstProperty exception : ', ex);
		}
	}

	checkActiveProperty() {
		if (sessionStorage.getItem('currentActiveProperty') === null){
			
			 this.setState({ 
				currentProperty    : '',
				loadActiveProperty : false,
				loadPage           : true
			 });
			//  console.log('not in the active load', sessionStorage.getItem('currentActiveProperty'))
		} else {
			var propertyId = sessionStorage.getItem('currentActiveProperty');
			this.setState({ 
				currentProperty    : sessionStorage.getItem('currentActiveProperty'),
				loadActiveProperty : true
			 });
			 this.getPropertyData(propertyId)
			//  console.log('in the active load', this.state.currentProperty)
		}
	}

	getPropertyData(propertyId){

		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Properties/"+propertyId)
			.then(res => {
				const property = res.data.result;

				if (property.address1){
					var address1 = property.address1;
				} else {
					var address1 = '';
				}
				if (property.address2){
					var address2 = property.address2;
				} else {
					var address2 = '';
				}
				if (property.city){
					var city = property.city;
				} else {
					var city = '';
				}
				if (property.state){
					var state = property.state;
				} else {
					var state = '';
				}
				if (property.zipCode){
					var zipCode = property.zipCode;
				} else {
					var zipCode = '';
				}

				this.setState({ 
					headerAddress        : address1 + ' ' + address2,
					headerCityState      : city + ', ' + state + ' ' + zipCode,
					loadProperty         : true,
					propertyId           : property.id, 
					propertyDescription  : property.name, 
					propertyPhone        : property.phoneNumber,  
					propertyAddress1     : property.address1,  
					propertyAddress2     : property.address2, 
					propertyCity         : property.city, 
					propertyZipCode      : property.zipCode, 
					propertyState        : property.state, 
					propertyPurchaseDate : property.purchaseDate,  
					propertyYearBuilt    : property.yearBuilt, 
					propertyRoomCt       : property.roomCt, 
					propertyBathCt       : property.bathCt,
					propertyImages       : property.propertyImages,
					loadPage             : true
				});
			});
		} catch(ex){
			console.log('getPropertyData exception : ', ex);
		}
	}

	logOut() {
		
		try {
			const { logout } = this.props.auth0;
			localStorage.clear();
			sessionStorage.clear();
			sessionStorage.setItem("logged_in", 'false');
			localStorage.setItem("redirect_url", '/');
			logout({ returnTo: window.location.origin });
		} catch(ex){
			console.log('logOut exception : ', ex);
		}
	}

	getUserData(userId){

		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Users/" + userId)
			.then(res => {
				const user_data = res.data.result;
				// console.log('user result ', user_data);
				var first_name = user_data.firstName;
				var last_name  = user_data.lastName;
				var initials   = first_name.charAt(0) + last_name.charAt(0);

				var plan = user_data.userSubscriptionPlanType.toLowerCase();

				if (plan.includes("unlimited")){
					// console.log('plan: ', plan);
					this.setState({ 
						showManageBilling : false,
					});
				} else {
					// console.log('plan2: ', plan);
					this.setState({ 
						showManageBilling : true,
					});
				}
				// console.log('initials ', initials);
				this.setState({ 
					userSection : true,
					userName    : user_data.userName,
					email       : user_data.email,
					initials    : initials
					// loadPage    : true
				});
				LogRocket.identify(userId, {
					name: user_data.userName,
					email: user_data.email,
				  
					// Add your own custom user variables here, ie:
					subscriptionType: plan
				});

				if (user_data.address1){
					var address1 = user_data.address1;
				} else {
					var address1 = '';
				}
				if (user_data.address2){
					var address2 = user_data.address2;
				} else {
					var address2 = '';
				}
				if (user_data.city){
					var city = user_data.city;
				} else {
					var city = '';
				}
				if (user_data.state){
					var state = user_data.state;
				} else {
					var state = '';
				}
				if (user_data.zipCode){
					var zipCode = user_data.zipCode;
				} else {
					var zipCode = '';
				}

				if (sessionStorage.getItem('currentActiveProperty') === null){
					this.setState({ 
						headerAddress     : address1 + ' ' + address2,
						headerCityState   : city + ', ' + state + ' ' + zipCode
					});
				} 
			});	
		} catch(ex){
			console.log('getUserData exception : ', ex);
		}
	}

	getProperties(){
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Properties/user/" + this.state.userId)
			.then(res => {
				const properties_data = res.data.result;
				// console.log('user result ', user_data);
				this.setState({ 
					properties : properties_data
				});
				
				// console.log('properties',this.state.properties)
			});	
		} catch(ex){
			console.log('getProperties exception : ', ex);
		}
	}

	selectProperty(event){
	
		let obj  =  {}
		let key  = event.target.name;
		obj[key] = event.target.value;
		let selectedPropertyId = event.target.value;
		
		this.setState({
			loadPage : false,
			obj,
			selectedPropertyId : selectedPropertyId,
			currentProperty    : selectedPropertyId
		});

		sessionStorage.setItem('currentActiveProperty', selectedPropertyId);
		this.getPropertyData(selectedPropertyId);
		window.location.reload(false);
	}

	navContractor(){
		history.push("/contractors/user="+this.state.userId);
	}

	navProperties(){
		history.push("/properties/user="+this.state.userId);
	}

	navHome(){
		history.push("/home/user=" + this.state.userId);
	}

	render() {

		const showLoader = this.state.showLoader;
		
		let loaderDisplay;

		if (showLoader) {
			loaderDisplay = 
						<div className="loader_container">
							<Loader
								type="Oval"
								color="#5FCE7F"
								height={100}
								width={100}
								timeout={10000} //10 secs
								visible={this.state.showLoader}
							/>
						</div>
		} else {
			loaderDisplay = "";
		}

		const loadPage = this.state.loadPage;

		let userDisplay;
    	if (loadPage) {
			userDisplay = <div className="userDisplay">
							<div className="property_section">
								<div>{this.state.headerAddress}</div>
								<div>{this.state.headerCityState}</div>
							</div>
							<div className="">								
								<DropdownButton id="user_section" title={this.state.initials} align="end" 
								show={this.state.showDropdown}
								onMouseEnter={this.showDropdown} 
								onMouseLeave={this.hideDropdown}>
									<Dropdown.Item href="#">Account:<br /><b>{this.state.email}</b></Dropdown.Item>
									<Dropdown.Item onClick={this.handleShowContactForm} href="#">Contact Us</Dropdown.Item>
									{/* <Dropdown.Item onClick={this.handleShowSubscriptionForm} href="#">Cancel Subscription</Dropdown.Item> */}
									{ this.state.showManageBilling ? <Dropdown.Item onClick={this.redirectStripeBilling} href="#">Manage Billing</Dropdown.Item> : null }
									{/* <Dropdown.Item onClick={this.redirectStripeBilling} href="#">Manage Billing</Dropdown.Item> */}
									<Dropdown.Item onClick={this.logOut} href="#"><b className="logout_text">Sign Out</b></Dropdown.Item>
								</DropdownButton>
								<Modal show={this.state.showContactForm} size='lg' onHide={this.handleCloseContactForm}>
									<Modal.Header closeButton>
										<Modal.Title>Contact Us</Modal.Title>
									</Modal.Header>
										<Modal.Body>
											<Form 
												className="formCard ConctactUsForm" 
												id="ConctactUsForm"
												onSubmit={this.sendContactEmail}
												>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Email address</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="email"
															name="contactEmailAddress" 
															value={this.state.contactEmailAddress}
															onChange={this._handleInput}
															placeholder="Enter email"
															required
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Name</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='contactName' 
															value={this.state.contactName}
															onChange={this._handleInput}
															placeholder="Enter Name"
															required
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Message</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="textarea"
															rows="3"
															name='contactMessage' 
															value={this.state.contactMessage}
															onChange={this._handleInput}
															required
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
													</Col>
													<Col md={8}>
														<Recaptcha
															sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
															render="explicit"
															onloadCallback={this.recaptchaLoaded}
															verifyCallback={this.verifiedRecaptcha}
														/>
													</Col>
												</Form.Group>
												<Form.Group as={Row}>
													<Col sm={{ span: 10 }}>
														<Button type="submit">Send</Button>
													</Col>
												</Form.Group>
											</Form>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" className="white-btn"  onClick={this.handleCloseContactForm}>
												Close
											</Button>
										</Modal.Footer>
									</Modal>
									<Modal show={this.state.showSubscriptionForm} size='lg' onHide={this.handleCloseSubscriptionForm}>
									<Modal.Header closeButton>
										<Modal.Title>Cancel Subscription</Modal.Title>
									</Modal.Header>
										<Modal.Body>
											<Form 
												className="formCard SubscriptionForm" 
												id="SubscriptionForm"
												onSubmit={this.sendSubscriptionEmail}
												>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Email address</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="email"
															name="subscriptionEmailAddress" 
															value={this.state.subscriptionEmailAddress}
															onChange={this._handleInput}
															placeholder="Enter email"
															required
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Name</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='subscriptionName' 
															value={this.state.subscriptionName}
															onChange={this._handleInput}
															placeholder="Enter Name"
															required
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
														<Form.Label className="formCardTitle">Message</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="textarea"
															rows="3"
															name='subscriptionMessage' 
															value={this.state.subscriptionMessage}
															onChange={this._handleInput}
															required
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} className="" >
													<Col md={4}>
													</Col>
													<Col md={8}>
														<Recaptcha
															sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
															render="explicit"
															onloadCallback={this.recaptchaLoaded}
															verifyCallback={this.verifiedRecaptcha}
														/>
													</Col>
												</Form.Group>
												<Form.Group as={Row}>
													<Col sm={{ span: 10 }}>
														<Button type="submit">Send</Button>
													</Col>
												</Form.Group>
											</Form>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" className="white-btn"  onClick={this.handleCloseSubscriptionForm}>
												Close
											</Button>
										</Modal.Footer>
									</Modal>
							</div>
						</div>;
		} else {
			userDisplay = <div></div>
		}
		return (
			<Navbar bg="" expand="lg">
				<div className="nav-top-container">
					<Navbar.Brand className="navbar-brand top_container" onClick={this.navHome}>
						<img
							alt="home_history_logo"
							src={new_logo_png}
							width="30"
							height="30"
							className="d-inline-block align-top nav-logo"
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<div className="propertyDropdown">
							<Form.Control 
								as="select" 
								name='propertyId' 
								className="form_display_data property_select"
								onChange={this.selectProperty} 
								value={this.state.currentProperty}
								>
									{this.state.properties.map(property => (
										<option key={property.id} value={property.id}>{property.name}</option>
									))}
							</Form.Control>
						</div>
						
						<div className="properties_button_container">
							<a 
								className="form_display_data" 
								// className="btn properties-btn white-btn" 
								id="properties-btn" 
								onClick={this.navProperties}
								href="#">
								Properties
							</a>
						</div>
						<div className="contractor_add_button_container">
							<a 
								// className="btn add-contractor-btn white-btn" 
								className="form_display_data" 
								id="add-contractor-btn" 
								onClick={this.navContractor}
								href="#">
								Contractors
							</a>
						</div>
						{userDisplay}
						{loaderDisplay}
					</Navbar.Collapse>
				</div>
			</Navbar>
		);
	}
}

export default withAuth0(Header);