import React, { Component } from 'react';
import './css/Properties.css';
import {Container, Col, Row, Button, Form, ListGroup, Modal, Alert} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import $ from 'jquery';
import dateFormat from 'dateformat';
import moment from 'moment';
import ImageGallery from 'react-image-gallery';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
/* global google */

class Properties extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error                : null,
			isLoaded             : false,
			properties           : [],
			propertiesLoaded     : false,
			showPropertyAdd      : false,
			propertyDescription  : '', 
			propertyPhone        : '',
			propertyAddress1     : '',
			propertyAddress2     : '',  
			propertyCity         : '',  
			propertyState        : '', 
			propertyZipCode      : '',
			propertyYearBuilt    : '',
			propertyPurchaseDate : '',
			propertyRoomCt       : '',
			propertyBathCt       : '',
			propertyDetailLoaded : false,
			propertyDefault      : false,
			propertyFootage      : 0,
			propertyImage        : '',
			propertyHOA          : '',
			propertyRoof         : '',
			loadImage            : false,
			propertyImagePath    : '',
			imageMessage         : '',
			showImageAlert       : false,
			imageGallery : [],
			propertyId   : '',
			formFile     : '',
			fileName     : '',
			userId       : '',
			propertiesCount  : 0,
			showPropertyEdit : false,
			showAddProperty  : false,
			showLoader       : false,
			// propertyDescription : ''

		};
		this._handleInput          = this._handleInput.bind(this);
		this._handleCheckbox       = this._handleCheckbox.bind(this);
		this.getPropertiesByUser   = this.getPropertiesByUser.bind(this);
		this.handleEditShow        = this.handleEditShow.bind(this);
		this.handleEditClose       = this.handleEditClose.bind(this);
		this.handleAddShow         = this.handleAddShow.bind(this);
		this.handleAddClose        = this.handleAddClose.bind(this);
		this.updateProperty        = this.updateProperty.bind(this);
		this.addProperty           = this.addProperty.bind(this);
		this.loadInitialProperties = this.loadInitialProperties.bind(this);
		this.deleteProperty        = this.deleteProperty.bind(this);
		this.setSelectedImage      = this.setSelectedImage.bind(this);
		this.postPropertyImage     = this.postPropertyImage.bind(this);
		this.initGooglePlaces      = this.initGooglePlaces.bind(this);
		this.handlePlaceSelect     = this.handlePlaceSelect.bind(this);
		this.autocomplete          = null;
		this.autocompleteInput     = React.createRef();
		this.getUserData           = this.getUserData.bind(this);
		// this.handleActiveProperty  = this.handleActiveProperty.bind(this);
		
	}

	componentDidMount() {
		setTimeout(
			function() {
				this.loadInitialProperties();
			}
			.bind(this),
			500
		);
	}
	
	loadInitialProperties(){
		this.setState({ 
			showLoader : true,
		});

		axios.defaults.headers.common = {
			'Authorization': 'Bearer ' + sessionStorage.getItem('token')
		};
		
		let smallScreen = (window.innerWidth <= 850);
		// console.log('the width: ', window.innerWidth);
		// console.log('small screen: ', smallScreen);
		if (smallScreen){
			$('.parent-container').css( { "padding-left" : "4.7em"} );
		} else {
			$('.parent-container').css( { "padding-left" : "2em"} );
		}
			
		const userId = parseInt(this.props.match.params.userId);

		this.setState({ userId });
		this.getPropertiesByUser();
		this.getUserData(userId)
	}

	handleEditShow() {
		this.setState({ showPropertyEdit : true });
	}
	
    handleEditClose() {
		this.setState({ showPropertyEdit : false });
	} 

	initGooglePlaces(){
		// console.log('this is happening initGooglePlaces');

		this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
			{"types": ["geocode"]});
	
		this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
	}

	handlePlaceSelect() {
		let addressObject = this.autocomplete.getPlace()
		// console.log('the address: ', addressObject)
		let address = addressObject.address_components;

		var addressDisplay = `${address[0].long_name} ${address[1].long_name}`;
		var city    = address[2].long_name;
		var state   = address[4].short_name;
		var zipCode = address[6].short_name;

		if (addressDisplay.length === 0){
			addressDisplay = '';
		}
		if (city.length === 0){
			city = '';
		}
		if (state.length === 0){
			state = '';
		}
		if (zipCode.length === 0){
			zipCode = '';
		}

		this.setState({
			// contractorName     : addressObject.name,
			propertyAddress1 : addressDisplay,
			propertyCity     : city,
			propertyState    : state,
			propertyZipCode  : zipCode,
			// contractorWebsite  : addressObject.url
		});
	}

	setSelectedImage(e){
		// console.log('file', file);
		// console.log('file', file.name);
		// let name  = e.target.name;
		let value = e.target.value;

		this.setState({ 
			propertyImage  : value,
			formFile       : e.target.files[0],
			fileName       : e.target.files[0].name, 
			showImageAlert : false,
		});
	}

	postPropertyImage(e) {
		e.preventDefault();
		// console.log(e.target.files[0]);
		// console.log(e.target.files[0].name);
		// console.log(this.state.formFile);
		// console.log(this.state.fileName);

		var file = this.state.formFile;
		// console.log('file size: ', file);

		var upload = true;

		if (file.size > 15000000){
		// if (file.size > 11000){
			
			upload = false;
			this.setState({ 
				showImageAlert : true,
				imageMessage   : 'This file is too large. Please choose a smaller file.',
				showLoader     : false
			});
		}

		if (!file.name.match(/.(jpg|png|gif|jpeg)$/i)){
			upload = false;
			this.setState({ 
				showImageAlert : true,
				imageMessage   : 'Please choose an image file.',
				showLoader     : false
			});
		}

		if (upload){

			const formData = new FormData();
			// formData.append('formFile', e.target.files[0]);
			// formData.append("fileName", e.target.files[0].name);
			formData.append('formFile', this.state.formFile);
			formData.append('fileName', this.state.fileName);
			var propertyId = this.state.propertyId;

			try{
				axios.post(process.env.REACT_APP_BASE_URL+'properties/image/'+propertyId, formData, {
					headers: {
					'Content-Type': 'multipart/form-data'
					}
				})
				.then(res => {
					// console.log('image result ', res);
					const image_response = res.data.result;
					// console.log("image response : ", image_response)
					if (image_response) {
						this.setState({ 
							propertyImagePath : image_response.path,
							showPropertyAdd   : false ,
							showPropertyEdit  : false,
							showLoader        : false
						});
						// console.log('property image path: ', this.state.propertyImagePath)
					
						// $('.addPropertyForm').hide();
					}
					// this.getCurrentProperty();
				});
			} catch(ex){
				console.log('savefile exception : ', ex);
			}
		}
	};

	updateProperty(e){

		this.setState({ 
			showLoader : true,
		});

		e.preventDefault();
		var propertyId           = this.state.propertyId;
		var userId               = this.state.userId;
		var propertyDescription  = this.state.propertyDescription;
		var propertyPhone        = this.state.propertyPhone;
		var propertyAddress1     = this.state.propertyAddress1; 
		var propertyAddress2     = this.state.propertyAddress2; 
		var propertyCity         = this.state.propertyCity;
		var propertyZipCode      = this.state.propertyZipCode; 
		var propertyState        = this.state.propertyState; 
		var propertyYearBuilt    = this.state.propertyYearBuilt; 
		var propertyPurchaseDate = this.state.propertyPurchaseDate; 
		var propertyRoomCt       = this.state.propertyRoomCt; 
		var propertyBathCt       = this.state.propertyBathCt;
		var propertyImage        = this.state.propertyImage;
		var propertyDefault      = this.state.propertyDefault;
		var propertyFootage      = this.state.propertyFootage;
		var propertyHOA          = this.state.propertyHOA;
		var propertyRoof         = this.state.propertyRoof;

		// console.log('default : ', this.state.propertyDefault)

		try{
			axios.put(process.env.REACT_APP_BASE_URL+'Properties/' + propertyId, { 
				userId       : userId,
				description  : propertyDescription,
				phoneNumber  : propertyPhone, 
				address1     : propertyAddress1, 
				address2     : propertyAddress2, 
				city         : propertyCity, 
				zipCode      : propertyZipCode, 
				state        : propertyState, 
				yearBuilt    : propertyYearBuilt, 
				purchaseDate : propertyPurchaseDate, 
				roomCt       : propertyRoomCt,
				bathCt       : propertyBathCt,
				is_Default   : propertyDefault,
				footage      : propertyFootage,
				roof_Updated : propertyRoof,
				hoa          : propertyHOA
			})
			.then((result) => {

				var value = $('.propertyEditImage').val();
				// console.log('value: ', value);
				if (value.length < 3) {
					this.setState({
						propertyImage : '',
						formFile      : '',
						fileName      : '',
						showPropertyEdit : false
					})
				} else if (this.state.propertyImage){
					// console.log(e.target.files[0]);
					// console.log('image: ', propertyImage);
					this.postPropertyImage(e)
				}
				this.getPropertiesByUser();	
			});
		} catch(ex){
			console.log('updateProperty exception : ', ex);
		}
	}
	
	getPropertiesByUser(){
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"properties/user/" + this.state.userId)
			.then(res => {
				const properties = res.data.result;
				// console.log('get properties by user : ', properties);
				if (properties.length > 0) {
					
					this.setState({ 
						properties       : properties,
						propertiesLoaded : true,
						propertiesCount  : properties.length,
						showLoader       : false
					});
					// console.log('this state properties : ', properties)
				}
			});
		} catch(ex){
			console.log('getPropertiesByUser exception : ', ex);
		}
	}

	addProperty(e){
		e.preventDefault();

		this.setState({ 
			showLoader : true,
		});

		var userId               = this.state.userId;
		var propertyDescription  = this.state.propertyDescription;
		var propertyPhone        = this.state.propertyPhone;
		var propertyAddress1     = this.state.propertyAddress1; 
		var propertyAddress2     = this.state.propertyAddress2; 
		var propertyCity         = this.state.propertyCity;
		var propertyZipCode      = this.state.propertyZipCode; 
		var propertyState        = this.state.propertyState; 
		var propertyYearBuilt    = this.state.propertyYearBuilt; 
		var propertyPurchaseDate = this.state.propertyPurchaseDate; 
		var propertyRoomCt       = this.state.propertyRoomCt; 
		var propertyBathCt       = this.state.propertyBathCt;
		var propertyImage        = this.state.propertyImage;
		var propertyFootage      = this.state.propertyFootage;
		var propertyDefault      = 0;
		var propertyHOA          = this.state.propertyHOA;
		var propertyRoof         = this.state.propertyRoof;

		// console.log('state in property put ', this.state)
	
		try{
			axios.post(process.env.REACT_APP_BASE_URL+'Properties/', { 
				userId       : userId,
				description  : propertyDescription,
				phoneNumber  : propertyPhone, 
				address1     : propertyAddress1, 
				address2     : propertyAddress2, 
				city         : propertyCity, 
				zipCode      : propertyZipCode, 
				state        : propertyState, 
				yearBuilt    : propertyYearBuilt, 
				purchaseDate : propertyPurchaseDate, 
				roomCt       : propertyRoomCt,
				bathCt       : propertyBathCt,
				is_Default   : propertyDefault,
				footage      : propertyFootage,
				roof_updated : propertyRoof,
				hoa          : propertyHOA
			})
			.then((res) => {
				// console.log('we created a property', result);
				const property_response = res.data.result;

				var value = $('.propertyEditImage').val();
				// console.log('value: ', value);
				if (value.length < 3) {
					this.setState({
						propertyImage   : '',
						formFile        : '',
						fileName        : '',
						showPropertyAdd : false,
						showLoader      : false 
					})
					// window.location = "/home/user=" + this.state.userId;
				} else if (this.state.propertyImage){
					// console.log(e.target.files[0]);
					// console.log('image: ', propertyImage);
					this.postPropertyImage(e)
				}
				window.location.reload();
				// this.getPropertiesByUser();
			});
		} catch(ex){
			console.log('addProperty exception : ', ex);
		}
	}

	deleteProperty(e){
		e.preventDefault();
		this.setState({ 
			showLoader : true,
		});
		var confirm = window.confirm('Are you sure you want to delete this property?')
		if (confirm){
			// this.setState({ 
			// 	showLoader : true,
			// });
			try{
				var propertyId = this.state.propertyId;

				this.setState({ propertyDetailLoaded : false });
				axios.delete(process.env.REACT_APP_BASE_URL+"Properties/"+propertyId)
				.then((result) => {
					// console.log('we deteled a property', result);
					this.getPropertiesByUser();
				});
			} catch(ex){
				console.log('deleteProperty exception : ', ex);
			}
		}
	}

	showDetail(propertyId){
        
        this.setState({ 
			showPropertyAdd : false,
			showLoader      : true
		});
		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Properties/"+propertyId)
			.then(res => {
				const property_detail = res.data.result;

				if (property_detail.propertyImages.length > 0){
					var images     = property_detail.propertyImages;
					var the_images = images.map(image => ({'original': image.urL_Full, 'thumbnail': image.urL_Thumb ? image.urL_Thumb  : '', originalAlt: 'image_'+image.imageId, thumbnailAlt: 'image_'+image.imageId }));
					this.setState({ 
						imageGallery : the_images,
						loadImage    : true
					})
				} else {
					this.setState({ 
						imageGallery : '',
						loadImage    : false
					})
				}
			
				this.setState({ 
					// property_detail,
					propertyDetailLoaded : true,
					propertyId           : property_detail.id, 
					propertyDescription  : property_detail.name, 
					propertyPhone        : property_detail.phone,
					propertyAddress1     : property_detail.address1,
					propertyAddress2     : property_detail.address2,  
					propertyCity         : property_detail.city,  
					propertyState        : property_detail.state, 
					propertyZipCode      : property_detail.zipCode,
					propertyYearBuilt    : property_detail.yearBuilt,
					propertyPurchaseDate : property_detail.purchaseDate,
					propertyRoomCt       : property_detail.roomCt,
					propertyBathCt       : property_detail.bathCt,
					propertyDefault      : property_detail.is_Default,
					propertyFootage      : property_detail.footage,
					propertyRoof         : property_detail.roof_Updated,
					propertyHOA          : property_detail.hoa,
					showLoader           : false
				});

				// console.log('property_detail: ', property_detail);
				// console.log('property detail state: ', this.state.propertyDefault);
			});
		} catch(ex){
			console.log('showDetail exception : ', ex);
		}
	}

	_handleInput(event) {
		let obj  =  {}
		let key  = event.target.name;
		obj[key] = event.target.value;
		this.setState(obj);
	}

	_handleCheckbox(event){
		const { checked } = event.target;
		this.setState({
			propertyDefault: checked
		})
		// console.log("this : ",this.state);
	}

	handleAddShow() {
		this.setState({ 
						showPropertyAdd      : true,
						propertyDescription  : '', 
						propertyPhone        : '',
						propertyAddress1     : '',
						propertyAddress2     : '',  
						propertyCity         : '',  
						propertyState        : '', 
						propertyZipCode      : '',
						propertyYearBuilt    : '',
						propertyPurchaseDate : '',
						propertyRoomCt       : '',
						propertyBathCt       : '',
						propertyDefault      : '',
						propertyFootage      : '',
						propertyRoof         : '',
						propertyHOA          : ''
					});
	}
	
    handleAddClose() {
		this.setState({ showPropertyAdd  : false });
	}

	getUserData(userId){

		try{
			axios.get(process.env.REACT_APP_BASE_URL+"Users/" + userId)
			.then(res => {
				const user_data = res.data.result;
				// console.log('user result properties', user_data);

				var plan = user_data.userSubscriptionPlanType.toLowerCase();

				if (plan.includes("monthly")){
					// console.log('plan: ', plan);
					this.setState({ 
						showAddProperty : false,
					});
				} else {
					// console.log('plan2: ', plan);
					this.setState({ 
						showAddProperty : true,
					});
				}
			
			});	
		} catch(ex){
			console.log('getUserData exception : ', ex);
		}
	}

	// handleActiveProperty({target}) {
		
	// 	if (target.checked){
	// 		sessionStorage.setItem('currentActiveProperty', this.state.propertyId);
	// 		this.setState({ 
	// 			headerAddress     : this.state.property_detail.address1 + ' ' + this.state.property_detail.address2,
	// 			headerCityState   : this.state.property_detail.city + ', ' + this.state.property_detail.state + ' ' + this.state.property_detail.zipCode
	// 		});
	// 	} else {
	// 		sessionStorage.removeItem('currentActiveProperty');
	// 	}
	// }
	
	render() {

		const showLoader = this.state.showLoader;
		
		let loaderDisplay;

		if (showLoader) {
			loaderDisplay = 
						<div className="loader_container">
							<Loader
								type="Oval"
								color="#5FCE7F"
								height={100}
								width={100}
								timeout={10000} //10 secs
								visible={this.state.showLoader}
							/>
						</div>
		} else {
			loaderDisplay = "";
		}

		const loadImage = this.state.loadImage;
		let imageDisplay;
    	if (loadImage) {
			imageDisplay = 	<div className="img_container">
								<ImageGallery items={this.state.imageGallery} showBullets={true}/>
							</div>
		} else {
			imageDisplay = <div className="img_container"></div>
		}

		const showImageAlert = this.state.showImageAlert;
		
		let alertImageDisplay;

		if (showImageAlert) {
			alertImageDisplay = 	
							<Row>
								<Col md={1}></Col>
								<Col md={5}>
									<Alert variant="danger" style={{ width: "26rem" }}>
										{/* <Alert.Heading> */}
											{this.state.imageMessage}
										{/* </Alert.Heading> */}
									</Alert>
								</Col>
								<Col md={6}></Col>
							</Row>;
							
		} else {
			alertImageDisplay = "";
		}

		const loadProperties = this.state.propertiesLoaded;
		let propertiesDisplay;
		
		if (loadProperties) {
			propertiesDisplay = <div className="properties_container"> 
								<div>
									<div className="properties_container_header">
										<div className="property_name">{this.state.properties.name}</div>
										<div className="properties_header_line_2">
											<div className="properties_count">{this.state.propertiesCount} Properties</div>
											{ this.state.showAddProperty ? 	
												<div className="property_add_button">
													<FontAwesomeIcon onClick={this.handleAddShow} className="add_property_plus icon" icon={ faPlusCircle }/>
												</div> 
											: null }
											
										</div>
									</div>
									<Modal show={this.state.showPropertyAdd} size='lg' onHide={this.handleAddClose}>

										<Modal.Header closeButton>
											<Modal.Title>Add a New Property</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<Form 
												
												className='formCard PropertyAddForm'
												id="property_form"
												onSubmit={this.addProperty}
												>
												<div className="cardTitle">General Home Information</div>
												<Form.Group as={Row}  controlId="formHorizontalPropertyDescription">
													<Col md={4}>
														<Form.Label className="formCardTitle">Name: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															// placeholder="name" 
															name='propertyDescription' 
															onChange={this._handleInput} 
															value={this.state.propertyDescription} 
															/>
													</Col>
												</Form.Group>
												{/* <Form.Group as={Row} controlId="formHorizontalPhone">
													<Col md={4}>
														<Form.Label className="formCardTitle">Phone: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='propertyPhone' 
															onChange={this._handleInput} 
															value={this.state.propertyPhone} 
															/>
													</Col>
												</Form.Group> */}
												<Form.Group as={Row} controlId="formHorizontalAddress1">
													<Col md={4}>
														<Form.Label className="formCardTitle">Address 1: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='propertyAddress1' 
															onChange={this._handleInput} 
															// id="autocomplete"
															ref={this.autocompleteInput}
															// placeholder="Name" 
															onFocus={this.initGooglePlaces}
															value={this.state.propertyAddress1} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalAddress2">
													<Col md={4}>
														<Form.Label className="formCardTitle">Address 2: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='propertyAddress2' 
															onChange={this._handleInput} 
															value={this.state.propertyAddress2} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalCity">
													<Col md={4}>
														<Form.Label className="formCardTitle">City: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='propertyCity' 
															onChange={this._handleInput} 
															value={this.state.propertyCity} 
															/>
													</Col>
												</Form.Group>
												
												<Form.Group as={Row} controlId="formHorizontalState">
													<Col md={4}>
														<Form.Label className="formCardTitle">State: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															as="select" 
															name='propertyState' 
															onChange={this._handleInput} 
															value={this.state.propertyState}
															// defaultValue={this.state.propertyState}
															>
															<option value="">Choose...</option>
															<option value="AL">Alabama</option>
															<option value="AK">Alaska</option>
															<option value="AZ">Arizona</option>
															<option value="AR">Arkansas</option>
															<option value="CA">California</option>
															<option value="CO">Colorado</option>
															<option value="CT">Connecticut</option>
															<option value="DE">Delaware</option>
															<option value="DC">District Of Columbia</option>
															<option value="FL">Florida</option>
															<option value="GA">Georgia</option>
															<option value="HI">Hawaii</option>
															<option value="ID">Idaho</option>
															<option value="IL">Illinois</option>
															<option value="IN">Indiana</option>
															<option value="IA">Iowa</option>
															<option value="KS">Kansas</option>
															<option value="KY">Kentucky</option>
															<option value="LA">Louisiana</option>
															<option value="ME">Maine</option>
															<option value="MD">Maryland</option>
															<option value="MA">Massachusetts</option>
															<option value="MI">Michigan</option>
															<option value="MN">Minnesota</option>
															<option value="MS">Mississippi</option>
															<option value="MO">Missouri</option>
															<option value="MT">Montana</option>
															<option value="NE">Nebraska</option>
															<option value="NV">Nevada</option>
															<option value="NH">New Hampshire</option>
															<option value="NJ">New Jersey</option>
															<option value="NM">New Mexico</option>
															<option value="NY">New York</option>
															<option value="NC">North Carolina</option>
															<option value="ND">North Dakota</option>
															<option value="OH">Ohio</option>
															<option value="OK">Oklahoma</option>
															<option value="OR">Oregon</option>
															<option value="PA">Pennsylvania</option>
															<option value="RI">Rhode Island</option>
															<option value="SC">South Carolina</option>
															<option value="SD">South Dakota</option>
															<option value="TN">Tennessee</option>
															<option value="TX">Texas</option>
															<option value="UT">Utah</option>
															<option value="VT">Vermont</option>
															<option value="VA">Virginia</option>
															<option value="WA">Washington</option>
															<option value="WV">West Virginia</option>
															<option value="WI">Wisconsin</option>
															<option value="WY">Wyoming</option>
														</Form.Control>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalZipcode">
													<Col md={4}>
														<Form.Label className="formCardTitle">Zip Code: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="number" 
															name='propertyZipCode' 
															onChange={this._handleInput} 
															value={this.state.propertyZipCode} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalYearBuilt">
													<Col md={4}>
														<Form.Label className="formCardTitle">Year Built: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="number" 
															name='propertyYearBuilt' 
															onChange={this._handleInput} 
															value={this.state.propertyYearBuilt} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalFootage">
													<Col md={4}>
														<Form.Label className="formCardTitle">Footage: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="number" 
															name='propertyFootage' 
															onChange={this._handleInput} 
															value={this.state.propertyFootage} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalRoomCnt">
													<Col md={4}>
														<Form.Label className="formCardTitle">Room Count: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="number" 
															name='propertyRoomCt' 
															onChange={this._handleInput} 
															value={this.state.propertyRoomCt} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalBathCnt">
													<Col md={4}>
														<Form.Label className="formCardTitle">Bath Count: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='propertyBathCt' 
															onChange={this._handleInput} 
															value={this.state.propertyBathCt} 
															
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalPurchaseDate">
													<Col md={4}>
														<Form.Label className="formCardTitle">Purchase Date: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="date" 
															name='propertyPurchaseDate' 
															onChange={this._handleInput} 
															value={ this.state.propertyPurchaseDate ? moment(this.state.propertyPurchaseDate).format('YYYY-MM-DD') : null } 
															// value={dateFormat(this.state.propertyPurchaseDate, 'yyyy-mm-dd')} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalHOA">
													<Col md={4}>
														<Form.Label className="formCardTitle">HOA Fees</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															// className="form_display_data"
															name='propertyHOA' 
															onChange={this._handleInput} 
															value={this.state.propertyHOA} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalRoof">
													<Col md={4}>
														<Form.Label className="formCardTitle">Roof Updated</Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="date" 
															// className="form_display_data"
															name='propertyRoof' 
															onChange={this._handleInput} 
															// value={dateFormat(this.state.propertyRoof, 'yyyy-mm-dd')} 
															value={ this.state.propertyRoof ? moment(this.state.propertyRoof).format('YYYY-MM-DD') : null } 
															// value={this.state.propertyRoof} 
															/>
													</Col>
												</Form.Group>
											
												<Form.Group as={Row} controlId="formHorizontalPropetyImage">
													<Col md={4}>
														<Form.Label className="formCardTitle">Property Image: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="file" 
															className='propertyEditImage'
															name='propertyImage' 
															onChange={(e) => this.setSelectedImage(e)}
															/>
													</Col>
												</Form.Group>
												{alertImageDisplay}
												<Form.Group as={Row}>
													<Col sm={{ span: 10 }}>
														<Button
															type="submit"
														>
														Submit
														</Button>
													</Col>
												</Form.Group>
											</Form>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" className="white-btn"  onClick={this.handleAddClose}>
												Close
											</Button>
										</Modal.Footer>
									</Modal>
									<ListGroup defaultActiveKey="#link1">
										{this.state.properties.map(property => 
										
											<div className="property_container" key={property.id} >
												<ListGroup.Item action onClick={() => this.showDetail(property.id)}>
													<div className="property_list_top_line">
														<div className="property_list_property">{property.name}</div>
														{/* <div className="property_list_category">{property.contractorCateorgy}</div> */}
													</div>
													<div className="property_list_location">{property.city}, {property.state}</div>
												
												</ListGroup.Item>
											</div>
										)}
									</ListGroup>
								</div>
							</div>
		} else {
			propertiesDisplay = <div></div>
		}
		
		const loadPropertyDetail = this.state.propertyDetailLoaded;
		let propertyDetailDisplay;
		
		if (loadPropertyDetail) {
			propertyDetailDisplay = <div className="property_detail_container">
									<Modal show={this.state.showPropertyEdit} size='lg' onHide={this.handleEditClose}>
									<Modal.Header closeButton>
										<Modal.Title>Edit Property</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Form 
											className="formCard PropertyEditForm" 
											onSubmit={this.updateProperty}
											>
											<div className="cardTitle">Property Information</div>
											<Form.Group as={Row} controlId="formHorizontalPropertyDefault">
												<Col md={4}>
													<Form.Label className="formCardTitle">Default Property: </Form.Label>
												</Col>
												<Col md={2}>
													<Form.Check 
														type="checkbox" 
														// placeholder="name" 
														name='propertyDefault' 
														onChange={this._handleCheckbox} 
														value={this.state.propertyDefault} 
														checked={this.state.propertyDefault}
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalPropertyDescription">
												<Col md={4}>
													<Form.Label className="formCardTitle">Name: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														// placeholder="name" 
														name='propertyDescription' 
														onChange={this._handleInput} 
														value={this.state.propertyDescription} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalPropId">
												<Form.Control 
													type="hidden" 
													name='propertyId' 
													value={this.state.propertyId} 
													onChange={this._handleInput} 
													required/>
												{/* <Col md={4}>
													<Form.Label className="formCardTitle">Phone: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														name='propertyPhone' 
														onChange={this._handleInput} 
														value={this.state.propertyPhone} 
														/>
												</Col> */}
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalAddress1">
												<Col md={4}>
													<Form.Label className="formCardTitle">Address 1: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														name='propertyAddress1' 
														onChange={this._handleInput} 
														// id="autocomplete"
														ref={this.autocompleteInput}
														// placeholder="Name" 
														onFocus={this.initGooglePlaces}
														value={this.state.propertyAddress1} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalAddress2">
												<Col md={4}>
													<Form.Label className="formCardTitle">Address 2: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														name='propertyAddress2' 
														onChange={this._handleInput} 
														value={this.state.propertyAddress2} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalCity">
												<Col md={4}>
													<Form.Label className="formCardTitle">City: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														name='propertyCity' 
														onChange={this._handleInput} 
														value={this.state.propertyCity} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalState">
												<Col md={4}>
													<Form.Label className="formCardTitle">State: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														as="select" 
														name='propertyState' 
														onChange={this._handleInput} 
														value={this.state.propertyState}
														// defaultValue={this.state.propertyState}
														>
														<option value="">Choose...</option>
														<option value="AL">Alabama</option>
														<option value="AK">Alaska</option>
														<option value="AZ">Arizona</option>
														<option value="AR">Arkansas</option>
														<option value="CA">California</option>
														<option value="CO">Colorado</option>
														<option value="CT">Connecticut</option>
														<option value="DE">Delaware</option>
														<option value="DC">District Of Columbia</option>
														<option value="FL">Florida</option>
														<option value="GA">Georgia</option>
														<option value="HI">Hawaii</option>
														<option value="ID">Idaho</option>
														<option value="IL">Illinois</option>
														<option value="IN">Indiana</option>
														<option value="IA">Iowa</option>
														<option value="KS">Kansas</option>
														<option value="KY">Kentucky</option>
														<option value="LA">Louisiana</option>
														<option value="ME">Maine</option>
														<option value="MD">Maryland</option>
														<option value="MA">Massachusetts</option>
														<option value="MI">Michigan</option>
														<option value="MN">Minnesota</option>
														<option value="MS">Mississippi</option>
														<option value="MO">Missouri</option>
														<option value="MT">Montana</option>
														<option value="NE">Nebraska</option>
														<option value="NV">Nevada</option>
														<option value="NH">New Hampshire</option>
														<option value="NJ">New Jersey</option>
														<option value="NM">New Mexico</option>
														<option value="NY">New York</option>
														<option value="NC">North Carolina</option>
														<option value="ND">North Dakota</option>
														<option value="OH">Ohio</option>
														<option value="OK">Oklahoma</option>
														<option value="OR">Oregon</option>
														<option value="PA">Pennsylvania</option>
														<option value="RI">Rhode Island</option>
														<option value="SC">South Carolina</option>
														<option value="SD">South Dakota</option>
														<option value="TN">Tennessee</option>
														<option value="TX">Texas</option>
														<option value="UT">Utah</option>
														<option value="VT">Vermont</option>
														<option value="VA">Virginia</option>
														<option value="WA">Washington</option>
														<option value="WV">West Virginia</option>
														<option value="WI">Wisconsin</option>
														<option value="WY">Wyoming</option>
													</Form.Control>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalZipcode">
												<Col md={4}>
													<Form.Label className="formCardTitle">Zip Code: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="number" 
														name='propertyZipCode' 
														onChange={this._handleInput} 
														value={this.state.propertyZipCode} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalYearBuilt">
												<Col md={4}>
													<Form.Label className="formCardTitle">Year Built: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="number" 
														name='propertyYearBuilt' 
														onChange={this._handleInput} 
														value={this.state.propertyYearBuilt} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalFootage">
												<Col md={4}>
													<Form.Label className="formCardTitle">Footage: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="number" 
														name='propertyFootage' 
														onChange={this._handleInput} 
														value={this.state.propertyFootage} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalRoomCnt">
												<Col md={4}>
													<Form.Label className="formCardTitle">Room Count: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="number" 
														name='propertyRoomCt' 
														onChange={this._handleInput} 
														value={this.state.propertyRoomCt} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalBathCnt">
												<Col md={4}>
													<Form.Label className="formCardTitle">Bath Count: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														name='propertyBathCt' 
														onChange={this._handleInput} 
														value={this.state.propertyBathCt} 
														
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalPurchaseDate">
												<Col md={4}>
													<Form.Label className="formCardTitle">Purchase Date: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="date" 
														name='propertyPurchaseDate' 
														onChange={this._handleInput} 
														// value={dateFormat(this.state.propertyPurchaseDate, 'yyyy-mm-dd')} 
														value={ this.state.propertyPurchaseDate ? moment(this.state.propertyPurchaseDate).format('YYYY-MM-DD') : null } 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalHOA">
												<Col md={4}>
													<Form.Label className="formCardTitle">HOA Fees: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="text" 
														name='propertyHOA' 
														onChange={this._handleInput} 
														value={this.state.propertyHOA} 
														/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="formHorizontalRoof">
												<Col md={4}>
													<Form.Label className="formCardTitle">Roof Updated</Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="date" 
														// className="form_display_data"
														name='propertyRoof' 
														onChange={this._handleInput} 
														// value={dateFormat(this.state.propertyRoof, 'yyyy-mm-dd')} 
														value={ this.state.propertyRoof ? moment(this.state.propertyRoof).format('YYYY-MM-DD') : null } 
														/>
												</Col>
											</Form.Group>
										
											<Form.Group as={Row} controlId="formHorizontalPropetyImage">
												<Col md={4}>
													<Form.Label className="formCardTitle">Property Image: </Form.Label>
												</Col>
												<Col md={8}>
													<Form.Control 
														type="file" 
														className='propertyEditImage'
														name='propertyImage' 
														onChange={(e) => this.setSelectedImage(e)}
														/>
												</Col>
											</Form.Group>
											{alertImageDisplay}
											<Form.Group as={Row}>
												<Col sm={{ span: 10 }}>
													<Button type="submit">Save</Button>
												</Col>
											</Form.Group>
										</Form>
									</Modal.Body>
									<Modal.Footer>
										<Button variant="secondary" className="white-btn"  onClick={this.handleEditClose}>
											Close
										</Button>
									</Modal.Footer>
								</Modal>
									<div className="property_detail_header">
										<div className="property_detail_header_category">
											<Button className="property_edit_btn" onClick={this.handleEditShow}>Edit</Button>
											<div className="fea">
												{imageDisplay}
											</div>
										</div>
									
										{/* <div>
											<Form.Group controlId="formActiveCheckbox">
												<Form.Check type="checkbox" label="Default Property" onClick={this.handleActiveProperty}/>
											</Form.Group>
										</div> */}
									</div> 
									<div className="property_detail_body">
										<div className="property_detail_contractor">{this.state.propertyDescription}</div>
										<div className="property_detail_location">{this.state.propertyCity}, {this.state.propertyState}</div>
										<Form 
											className="propertyDetailForm form_no_modal" 
											>
											<Form.Group as={Row} controlId="formHorizontalUser">
											<Form.Control 
												type="hidden" 
												name='userId' 
												value={this.state.userId} 
												// onChange={this._handleInput} 
												// required
												/>
											<Form.Control 
												type="hidden" 
												name='propertyId' 
												value={this.state.propertyId} 
												// onChange={this._handleInput} 
												// required
												/>
											</Form.Group>
											<div className="property_detail_container">
												<Form.Group as={Row} controlId="formHorizontalPropertyDefault">
													<Col md={2}>
														<Form.Label className="formCardTitle">Default Property: </Form.Label>
													</Col>
													<Col md={2}>
														<Form.Check 
															type="checkbox" 
															// placeholder="name" 
															name='propertyDefault' 
															checked={this.state.propertyDefault}
															disabled
															value={this.state.propertyDefault} 
															/>
															{/* <Form.Check type="checkbox"  /> */}
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalPropertyDescription">
													<Col md={2}>
														<Form.Label className="formCardTitle">Name: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="text" 
															// placeholder="name" 
															name='propertyDescription' 
															readOnly
															value={this.state.propertyDescription} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalPropId">
													<Form.Control 
														type="hidden" 
														name='propertyId' 
														value={this.state.propertyId} 
														readOnly
														required/>
													{/* <Col md={4}>
														<Form.Label className="formCardTitle">Phone: </Form.Label>
													</Col>
													<Col md={8}>
														<Form.Control 
															type="text" 
															name='propertyPhone' 
															readOnly
															value={this.state.propertyPhone} 
															/>
													</Col> */}
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalAddress1">
													<Col md={2}>
														<Form.Label className="formCardTitle">Address 1: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="text" 
															name='propertyAddress1' 
															readOnly
															value={this.state.propertyAddress1} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalAddress2">
													<Col md={2}>
														<Form.Label className="formCardTitle">Address 2: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="text" 
															name='propertyAddress2' 
															readOnly
															value={this.state.propertyAddress2} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalCity">
													<Col md={2}>
														<Form.Label className="formCardTitle">City: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="text" 
															name='propertyCity' 
															readOnly
															value={this.state.propertyCity} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalState">
													<Col md={2}>
														<Form.Label className="formCardTitle">State: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="text" 
															name='propertyState' 
															readOnly
															value={this.state.propertyState} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalZipcode">
													<Col md={2}>
														<Form.Label className="formCardTitle">Zip Code: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="number" 
															name='propertyZipCode' 
															readOnly
															value={this.state.propertyZipCode} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalYearBuilt">
													<Col md={2}>
														<Form.Label className="formCardTitle">Year Built: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="number" 
															name='propertyYearBuilt' 
															readOnly
															value={this.state.propertyYearBuilt} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalFootage">
													<Col md={2}>
														<Form.Label className="formCardTitle">Footage: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="number" 
															name='propertyFootage' 
															readOnly
															value={this.state.propertyFootage} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalRoomCnt">
													<Col md={2}>
														<Form.Label className="formCardTitle">Rooms: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="text" 
															name='propertyRoomCt' 
															readOnly
															value={this.state.propertyRoomCt} 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalBathCnt">
													<Col md={2}>
														<Form.Label className="formCardTitle">Baths: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="text" 
															name='propertyBathCt' 
															readOnly
															value={this.state.propertyBathCt} 
															
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalPurchaseDate">
													<Col md={2}>
														<Form.Label className="formCardTitle">Purchase Date: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="date" 
															name='propertyPurchaseDate' 
															readOnly
															value={dateFormat(this.state.propertyPurchaseDate, 'yyyy-mm-dd')} 
															// value={ this.state.propertyPurchaseDate ? moment(this.state.propertyPurchaseDate).format('YYYY-MM-DD') : null } 
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalHOA">
													<Col md={2}>
														<Form.Label className="formCardTitle">HOA Fees: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="text" 
															name='propertyHOA' 
															readOnly
															value={this.state.propertyHOA} 
															
															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formHorizontalRoofUpdated">
													<Col md={2}>
														<Form.Label className="formCardTitle">Roof Updated: </Form.Label>
													</Col>
													<Col md={6}>
														<Form.Control 
															type="date" 
															name='propertyRoof' 
															readOnly
															value={dateFormat(this.state.propertyRoof, 'yyyy-mm-dd')} 

															/>
													</Col>
												</Form.Group>
												<Form.Group as={Row}>
													<Col sm={{ span: 10 }}>
														<Button type="button" onClick={this.deleteProperty}>Delete</Button>
													</Col>
												</Form.Group>
											</div>
										</Form>
									</div>
								</div>
		} else {
			propertyDetailDisplay = <div></div>
		}
		
		return (
			<div className="parent-container Fade">
				<Container>
					<Row>
						<Col md={4}>
							{propertiesDisplay}
						</Col>
						<Col md={8}>
							{loaderDisplay}
							{propertyDetailDisplay}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default Properties;