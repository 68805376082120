
import React, { Component } from 'react';
import './App.css';
import Promo from './components/Promo.js'
import { connect } from 'react-redux';

class App extends Component{
  constructor(props){
		super(props);
		this.state = {
			// isAuthenticated: false, 
		};
	}

	render(){
		return(
			<div className="">
				<Promo history={this.props.history}/>
			</div>
		)
	}
}

export default connect()(App);